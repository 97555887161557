/**
 * QUESTION STYLES
 */
.question {
    font-family: $font-family-CondOTBold;
    font-size: 1em;
    @extend .p;
    @extend strong;
}

.question {
    font-weight: initial;
}
