.my-infocenter {

    h2, .h2 {
        margin-top: 40px;
        margin-bottom: 30px;

        @include for-tablet-up {
            margin-top: 80px;
            margin-bottom: 50px;
        }

        @include for-desktop-up {
            margin-top: 80px;
            margin-bottom: 60px;
        }
    }

    &.container {
        margin-left: 30px;
        margin-right: 30px;

        @include for-tablet-up {
            margin-left: 30px;
            margin-right: 30px;
        }
    }

    .block-wrapper {
        &:not(:last-of-type){
            margin-bottom: 20px;
        }

        @include for-tablet-up {
            &:not(:last-of-type){
                margin-bottom: 30px;
            }
        }

        @include for-desktop-up {
            &:not(:last-of-type){
                margin-bottom: 0;
            }
        }
    }

    .block {
        display: flex;
        flex-direction: column;
        height: 100%;
        border: solid 3px #f9f9f9;

        h4, .h4 {
            color: $color-brand;
        }

        h5, .h5 {
            font-size: 12px;
            font-family: $font-family-CondOTBold;
            letter-spacing: 2px;
            line-height: 14px;
            text-transform: uppercase;
        }

        .top {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            padding: 35px 30px;
            background-color: #f9f9f9;

            i {
                font-size: 65px;
                line-height: 1;
            }

            .icon-wrapper {
                position: relative;
                color: $color-brand;
            }

            .counter {
                position: absolute;
                top: 14px;
                right: -7px;
                width: 17px;
                height: 17px;
                padding-top: 6px;

                background-color: $color-brand;
                color: $color-white;
                border-radius: 50%;

                font-family: $font-family-CondOTBold;
                font-size: 11px;
                line-height: 8px;
                text-align: center;
            }
        }

        .bottom {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1 1 auto;

            padding: 35px 30px 30px;
        }

        .preview-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            margin-bottom: 10px;
        }

        .arrow-link {
            display: inline-block;
            margin-top: 25px;

            i {
                font-size: 9px;
                padding-left: 3px;
            }
        }

        ul {
            padding-left: 0;
            list-style: none;
        }

        .preview-text, li {
            font-size: 14px;
            line-height: 24px;
        }
    }

}
