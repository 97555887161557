.seminar-form {
    .container--semifluid {
        margin-top: 40px;

        @include for-tablet-up {
            margin-top: 70px;
        }
    }

    h3, .h3 {
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 10px;
    }

    .table-view {
        line-height: 28px;

        .row:not(:first-of-type) {
            .label {
                margin-top: 20px;
            }
        }
    }

    hr {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .shy {
        color: $color-lightgrey3;
        font-size: 14px;
        line-height: 18px;
    }

    .label {
        font-size: 13px;
        font-family: $font-family-CondOTBold;
        line-height: 18px;
        color: $color-lightgrey3;

        @include for-tablet-up {
            color: $color-grey4;
            font-size: 16px;
            line-height: 28px;
        }
    }

    label:not(.form-check-label):not(.custom-radio) {
        /*margin-top: 20px;*/
    }

    .btn-wrapper {
        display: flex;
        flex-direction: row-reverse;
    }

    .btn-secondary {
        background-color: $color-lightgrey6;
    }

    textarea {
        width: 100%;
        height: 200px;
        resize: none;
        border: 1px solid #cccccc;
    }

    input[type='checkbox'] {
        margin-right: 0;
    }

    .form-check-input {
        margin-top: 0.125rem;
    }

    .form-check-label span {
        display: inline-block;
        margin-left: 5px;
    }
}
