.pageIntro {
    padding: 0;

    h1, .h1,
    strong,
    p, .p {
        html.csshyphens & {
            @include hyphenate;
        }
    }

    hr {
        margin-top: 0px;
    }

    h1,
    .h1 {
        @include media-breakpoint-down(sm) {
            font-size: 36px;
            line-height: 42px;
        }
    }

    svg {
        max-width: 100%;
    }

    &.color-white {
        .pageIntro__picture--headline {
            color: $color-white;

            p,
            .p {
                color: $color-white;
            }
        }
    }

    &.color-black {
        .pageIntro__picture--headline {
            color: $color-black;

            p,
            .p {
                color: $color-black;
            }
        }
    }
}

/* Text */
.pageIntro__headline {

    @include media-breakpoint-down(sm) {

        padding: $pageIntro__headline--padding;
    }

    @include media-breakpoint-up(md) {

        padding: $pageIntro__headline--padding2;
    }

    span {
        font-size: 12px;
        letter-spacing: 2px;
        text-transform: uppercase;
        line-height: 14px;
        font-family: $font-family-CondOTBold;
    }

    p,
    .p {
        font-family: $font-family-base-bold;
        color: rgb(24, 22, 24);
        display: block;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
    }
}

/* images*/

.pageIntro .pageIntro__img {
    position: relative;
    min-height: $pageIntro__img-height;
    margin: 0 0 20px 0;
    display: flex;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-color: $color-brand;

    @include media-breakpoint-down(sm) {
        min-height: 220px;
    }


    @media all and(min-width: $tablet-size) {
        .copyright-wrapper {
            position: absolute;
            left: 0;
            bottom: 25px;
            width: 100%;
        }

        .copyright {
            color: inherit;
            background-color: transparent;
            padding: 0 50px;
            max-width: 1400px;
            width: 100%;
            margin: 0 auto;
            text-align: right;

            &.color-black {
                color: $color-black;
            }

            &.color-white {
                color: $color-white;
            }
        }
    }
}

.pageIntro .copyright {
    background-color: $color-lightgrey2;

    margin: -20px 0 20px 0;
    padding: 8px 15px;

    color: $color-lightgrey3;
    font-size: 11px;
    font-weight: 500;
    line-height: 10px;
    text-align: left;
}

.pageIntro__pictureOverlay {
    background-color: $color-lightgrey4-2;
    width: 100%;

    @include media-breakpoint-up(md) {
        min-height: $pageIntro__img-height;
    }
}

/* pageIntro__author */
.pageIntro__author {
    display: flex;
    justify-content: center;

    padding: 8px 0 0;

    @media all and (min-width: $mobile-size) {
        justify-content: flex-start;
    }

    @media all and (min-width: $tablet-size) {
        justify-content: flex-start;
    }

    .col-sm-12.col-md-6 > &,
    .col-12.col-md-6 > & {
        @media all and (min-width: $mobile-size) {
            justify-content: center;
        }

        @media all and (min-width: $tablet-size) {
            justify-content: flex-start;
        }
    }

    p {
        color: $color-lightgrey3;
        font-size: 14px;
        font-family: $font-family-base-bold;
        letter-spacing: 1px;

        span {
            color: #333333;
            font-size: 14px;
            padding: 0 0 0 15px;
            letter-spacing: 0;
        }
    }

    .icon--download {
        background-image: url('../svg/sm/download-mz.svg');
        background-repeat: no-repeat;
        margin-right: 5px;
    }
}

/* pageIntro comments / likes */

.pageIntro__with_comments_likes {

    .pageIntro__author {

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                color: $color-lightgrey3;
                font-size: 14px;
                font-family: $font-family-base-bold;
                letter-spacing: 1px;
                display: inline;
                padding-right: 15px;

            }

            li.author {
                color: #333333;

                a:after {
                    content: url('../svg/icons/link-arrow-red.svg');
                    display: inline-block;
                    font-size: 0.75em;
                    width: 1em;
                    height: auto;
                    margin-left: 0.5em;
                    vertical-align: middle;
                }
            }
        }
    }

    .pageIntro__comments_likes {
        padding: 8px 0 0;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                color: $color-lightgrey3;
                font-size: 14px;
                font-family: $font-family-base-bold;
                letter-spacing: 1px;
                display: inline;
                padding-right: 10px;
            }
        }
    }
}

.pageIntro__social {
    display: flex;
    justify-content: center;

    margin: 20px 0;
    padding-left: 0;

    @media all and (min-width: $mobile-size) {
        justify-content: flex-end;
        margin: 6px 0;
    }

    @media all and (min-width: $tablet-size) {
        justify-content: flex-end;
        margin: 0 0 20px;
    }

    .col-sm-12.col-md-6 > &,
    .col-12.col-md-6 > & {
        @media all and (min-width: $mobile-size) {
            justify-content: center;
        }

        @media all and (min-width: $tablet-size) {
            justify-content: flex-end;
        }
    }

    > li {
        padding: 0 5px;
        margin: 0;
        font-size: 0;

        > a {
            display: inline-block;
            width: 40px;
            height: 40px;
            background-position: 50% 50%;
            background-size: 50%;
            background-repeat: no-repeat;
            border-radius: 50%;
            font-size: 1rem;

            &.icon--twitter,
            &.icon--google,
            &.icon--whatsapp,
            &.icon--mail,
            &.icon--print,
            &.icon--download,
            &.icon--fb {
                background-color: $color-brand;
                transition: all 0.3s ease;
                &:hover {
                    transform: scale(1.2);
                }
            }

            &.icon--fb {
                background-image: url('../svg/sm/fb.svg');
            }

            &.icon--twitter {
                background-image: url('../svg/sm/twitter.svg');
            }

            &.icon--google {
                background-image: url('../svg/sm/google.svg');
            }

            &.icon--whatsapp {
                background-image: url('../svg/sm/whatsapp.svg');
            }

            &.icon--mail {
                background-image: url('../svg/sm/mail.svg');
            }

            &.icon--print {
                background-image: url('../svg/sm/print.svg');
            }

            &.icon--download {
                background-image: url('../svg/sm/download.svg');
            }

            &.icon--rss {
                background-image: url('../svg/sm/rssfeed.svg');
                background-color: $color-brand;
                transition: all 0.3s ease;
                &:hover {
                    transform: scale(1.2);
                }
            }

        }
    }
}

.pageIntro__picture--headline {

    .pageIntro__headline {
        padding: 80px 0;
        width: 100%;

        @include media-breakpoint-down(sm) {
            padding: 60px 0;
        }
    }

    .container--semifluid {
        display: flex;
        align-items: center;
        height: 100%;
    }
}

.pageIntro__intro--mobile {
    margin-bottom: 20px;
}

.mz-intro__logo {
    max-width: 100%;
}
