.event {
    display: flex;

    .calendar-wrapper {
        font-size: 30px;
        margin-right: 10px;
    }

    .kicker {
        color: $color-light-orange;
        font-family: $font-family-CondOTBold;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 14px;
        text-transform: uppercase;

        &:after {
            color: $color-grey1;
        }
    }

    .date {
        color: $color-grey1;
    }

    .headline {
        color: $color-white;
        font-family: $font-family-CondOTBold;
        font-size: 18px;
        line-height: 24px;
    }
}
