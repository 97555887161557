.hierarchy-select {
    background: $color-white url(../svg/icons/dropdown-arrow.svg) no-repeat right 0.75rem center;
    background-size: 14px 14px;

    > .btn {
        text-align: left;
        @extend .custom-select;

        &:hover {
            border: $custom-select-border-width solid $custom-select-border-color;
        }

        &:focus {
            border-color: $custom-select-focus-border-color;
            outline: none;
            @include box-shadow($custom-select-focus-box-shadow);
        }

        &::after {
            content: none;
            display: none;
        }
    }

    &.dropdown {
        .dropdown-menu {
            padding: 0;

            a {
                padding: 5px 10px;

                &[data-level='2'] {
                    padding-left: 12px;

                    i:after,
                    i:before {
                        font-size: 0.5em;
                    }
                }
            }
        }
    }

    .dropdown-item {
        @include hover-focus {
            color: $dropdown-link-hover-color;
            text-decoration: none;
            background-color: $dropdown-link-hover-bg;
        }
    }
}
