@media print {
    .newsletterAnmeldung,
    .footer--page,
    .html5-video-player,
    .pageIntro__social,
    .breadcrumb-auth,
    .hamburger {
        display: none;
    }

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5 {
        page-break-after: avoid;
    }

    p, span {
        page-break-inside: avoid;

        @supports (orphans: 5) {
            orphans: 5;
            widows: 5;
            page-break-inside: initial;
        }
    }
}
