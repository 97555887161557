.dropdown {
    color: red;
    &--newMember {
        width: 80%;
        margin: 0 auto;
        border: 1px solid #d3d3d3;

        button {
            line-height: $line-height-h4;
            font-family: $font-family-CondOTBold;
            font-size: $font-size-h4;
        }

        .dropdown-toggle {
            width: 100%;
            color: $color-brand;
        }
        .dropdown-menu {
            width: 100%;
        }
        .dropdown-item {

            line-height: $line-height-h4;
            font-family: $font-family-CondOTBold;
            font-size: $font-size-h4;

            &[disabled] {
                color: $color-grey2;
                cursor: not-allowed;
            }
            &--active {
                color: $color-brand;
            }
        }
    }
}
