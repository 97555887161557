@font-face {
  font-family: "igm-icons-font";
  src: url('../../igm/fonts/icons/igm-icons-font.eot?version=1');
  src: url('../../igm/fonts/icons/igm-icons-font.eot?#iefix&version=1') format('eot'),
  url('../../igm/fonts/icons/igm-icons-font.woff2?version=1') format('woff2'),
  url('../../igm/fonts/icons/igm-icons-font.woff?version=1') format('woff'),
  url('../../igm/fonts/icons/igm-icons-font.ttf?version=1') format('truetype'),
  url('../../igm/fonts/icons/igm-icons-font.svg#igm-icons-font?version=1') format('svg');
}

@mixin icon-igm-styles {
  font-family: "igm-icons-font";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon-igm {
  @include icon-igm-styles;
}

@function icon-igm-char($filename) {
  $char: "";

  @if $filename == album {
  $char: "\E001";
}
  @if $filename == arrow {
  $char: "\E002";
}
  @if $filename == audio {
  $char: "\E003";
}
  @if $filename == branch {
  $char: "\E004";
}
  @if $filename == calendar-simple {
  $char: "\E005";
}
  @if $filename == calendar {
  $char: "\E006";
}
  @if $filename == check {
  $char: "\E007";
}
  @if $filename == checkmarkcircle {
  $char: "\E008";
}
  @if $filename == close {
  $char: "\E009";
}
  @if $filename == crop {
  $char: "\E00A";
}
  @if $filename == cropping {
  $char: "\E00B";
}
  @if $filename == document {
  $char: "\E00C";
}
  @if $filename == download {
  $char: "\E00D";
}
  @if $filename == dropdown-arrow-right {
  $char: "\E00E";
}
  @if $filename == dropdown-arrow-up {
  $char: "\E00F";
}
  @if $filename == dropdown-arrow {
  $char: "\E010";
}
  @if $filename == edit {
  $char: "\E011";
}
  @if $filename == eye {
  $char: "\E012";
}
  @if $filename == facebook {
  $char: "\E013";
}
  @if $filename == focus-point {
  $char: "\E014";
}
  @if $filename == fold-down {
  $char: "\E015";
}
  @if $filename == fold-up {
  $char: "\E016";
}
  @if $filename == hilfreich {
  $char: "\E017";
}
  @if $filename == image {
  $char: "\E018";
}
  @if $filename == info {
  $char: "\E019";
}
  @if $filename == instagram {
  $char: "\E01A";
}
  @if $filename == kontrast {
  $char: "\E01B";
}
  @if $filename == link-arrow {
  $char: "\E01C";
}
  @if $filename == lock {
  $char: "\E01D";
}
  @if $filename == lock_locked {
  $char: "\E01E";
}
  @if $filename == lock_unlocked {
  $char: "\E01F";
}
  @if $filename == mail-light {
  $char: "\E020";
}
  @if $filename == mail {
  $char: "\E021";
}
  @if $filename == minus {
  $char: "\E022";
}
  @if $filename == navi-arrow {
  $char: "\E023";
}
  @if $filename == next {
  $char: "\E024";
}
  @if $filename == office {
  $char: "\E025";
}
  @if $filename == pin {
  $char: "\E026";
}
  @if $filename == play {
  $char: "\E027";
}
  @if $filename == plus {
  $char: "\E028";
}
  @if $filename == prev {
  $char: "\E029";
}
  @if $filename == preview {
  $char: "\E02A";
}
  @if $filename == print {
  $char: "\E02B";
}
  @if $filename == refresh {
  $char: "\E02C";
}
  @if $filename == rss {
  $char: "\E02D";
}
  @if $filename == save {
  $char: "\E02E";
}
  @if $filename == search {
  $char: "\E02F";
}
  @if $filename == twitter {
  $char: "\E030";
}
  @if $filename == video-b {
  $char: "\E031";
}
  @if $filename == warning {
  $char: "\E032";
}
  @if $filename == write {
  $char: "\E033";
}
  @if $filename == youtube {
  $char: "\E034";
}

@return $char;
}

@mixin icon-igm($filename, $insert: before, $extend: true) {
&:#{$insert} {
  @if $extend {
    @extend %icon-igm;
  } @else {
    @include icon-igm-styles;
  }
  content: icon-igm-char($filename);
}
}

.icon-igm-album {
  @include icon-igm(album);
}
.icon-igm-arrow {
  @include icon-igm(arrow);
}
.icon-igm-audio {
  @include icon-igm(audio);
}
.icon-igm-branch {
  @include icon-igm(branch);
}
.icon-igm-calendar-simple {
  @include icon-igm(calendar-simple);
}
.icon-igm-calendar {
  @include icon-igm(calendar);
}
.icon-igm-check {
  @include icon-igm(check);
}
.icon-igm-checkmarkcircle {
  @include icon-igm(checkmarkcircle);
}
.icon-igm-close {
  @include icon-igm(close);
}
.icon-igm-crop {
  @include icon-igm(crop);
}
.icon-igm-cropping {
  @include icon-igm(cropping);
}
.icon-igm-document {
  @include icon-igm(document);
}
.icon-igm-download {
  @include icon-igm(download);
}
.icon-igm-dropdown-arrow-right {
  @include icon-igm(dropdown-arrow-right);
}
.icon-igm-dropdown-arrow-up {
  @include icon-igm(dropdown-arrow-up);
}
.icon-igm-dropdown-arrow {
  @include icon-igm(dropdown-arrow);
}
.icon-igm-edit {
  @include icon-igm(edit);
}
.icon-igm-eye {
  @include icon-igm(eye);
}
.icon-igm-facebook {
  @include icon-igm(facebook);
}
.icon-igm-focus-point {
  @include icon-igm(focus-point);
}
.icon-igm-fold-down {
  @include icon-igm(fold-down);
}
.icon-igm-fold-up {
  @include icon-igm(fold-up);
}
.icon-igm-hilfreich {
  @include icon-igm(hilfreich);
}
.icon-igm-image {
  @include icon-igm(image);
}
.icon-igm-info {
  @include icon-igm(info);
}
.icon-igm-instagram {
  @include icon-igm(instagram);
}
.icon-igm-kontrast {
  @include icon-igm(kontrast);
}
.icon-igm-link-arrow {
  @include icon-igm(link-arrow);
}
.icon-igm-lock {
  @include icon-igm(lock);
}
.icon-igm-lock_locked {
  @include icon-igm(lock_locked);
}
.icon-igm-lock_unlocked {
  @include icon-igm(lock_unlocked);
}
.icon-igm-mail-light {
  @include icon-igm(mail-light);
}
.icon-igm-mail {
  @include icon-igm(mail);
}
.icon-igm-minus {
  @include icon-igm(minus);
}
.icon-igm-navi-arrow {
  @include icon-igm(navi-arrow);
}
.icon-igm-next {
  @include icon-igm(next);
}
.icon-igm-office {
  @include icon-igm(office);
}
.icon-igm-pin {
  @include icon-igm(pin);
}
.icon-igm-play {
  @include icon-igm(play);
}
.icon-igm-plus {
  @include icon-igm(plus);
}
.icon-igm-prev {
  @include icon-igm(prev);
}
.icon-igm-preview {
  @include icon-igm(preview);
}
.icon-igm-print {
  @include icon-igm(print);
}
.icon-igm-refresh {
  @include icon-igm(refresh);
}
.icon-igm-rss {
  @include icon-igm(rss);
}
.icon-igm-save {
  @include icon-igm(save);
}
.icon-igm-search {
  @include icon-igm(search);
}
.icon-igm-twitter {
  @include icon-igm(twitter);
}
.icon-igm-video-b {
  @include icon-igm(video-b);
}
.icon-igm-warning {
  @include icon-igm(warning);
}
.icon-igm-write {
  @include icon-igm(write);
}
.icon-igm-youtube {
  @include icon-igm(youtube);
}
