.dropbox {

    padding: 26px 40px;

    border: 1px solid $color-table-border;
    border-bottom: none;
    margin-top: -1px;

    &:last-of-type {
        border-bottom: 1px solid $color-table-border;
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .title {
        cursor: pointer;
        margin-bottom: 0;

        font-family: $font-family-CondOTBold;
        font-size: 18px;
    }

    i {
        font-size: 0.75rem;
        cursor: pointer;
    }

    .content {
        display: none;

        color: $color-black;
        font-size: 16px;
        line-height: 24px;
    }

    &.open {
        .content {
            display: block;

            border-top: 1px solid $color-table-border;
            padding-top: 40px;
            margin-top: 34px;
        }
    }
}
