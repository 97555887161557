.teaserRow {
    &.page-section--colored {
        &-red,
        &-black {
            .teaser {
                .headline,
                .text,
                .kicker {
                    color: $color-white;
                }
            }
        }

        &-red {
            .kicker .date,
            .kicker:after {
                color: $color-black;
            }

            .arrow-link {
                color: $color-white;
            }

            .teaser .headline:hover {
                color: $color-black;
            }
        }

        &-black {
            .teaser .headline:hover {
                color: $color-brand;
            }
        }
    }

    .teaser.single {
        margin: 0 auto;
        max-width: 50%;
    }

    .teaser .text {
        margin-bottom: 0;
    }
}
