.teaserCombinationD {
    &__profil {
        text-align: center;
        margin-bottom: 50px;

        @include media-breakpoint-down(md) {
            margin-top: 80px;
            margin-bottom: 80px;
        }

        @include media-breakpoint-down(xs) {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        img {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            margin: 0 auto;
            margin-bottom: 20px;
            display: block;
        }
    }
}

.teaserCombinationD {

    margin-top: 50px;

    .teaserGrid-list__header {
        margin-top: 0;
    }


    .pageIntro,
    .teaserGrid-list,
    .teaser-list {
        .teaserCombinationD__profil {
            strong {
                span {
                    line-height: 16px;
                    text-transform: capitalize;
                    margin: 0 0 20px 0;
                    display: block;
                }
            }
        }
    }
}

.teaserCombinationA__item,
.teaserCombinationD__item {
    .teaserGrid-list__Among--above {
        .teaserGrid-list__Among--content {
            &:last-child {
                margin: 0 0 20px 0;
            }
        }
    }
}

.teaserCombinationD__profil {
    .teaserCombinationA__item,
    .teaserCombinationD__item {
        .teaserGrid-list__Among--above {
            .teaserGrid-list__Among--content {
                &:last-child {
                    margin: 0 0 30px 0;
                }
            }
        }
    }
}

.teaserGrid-list {
    .teaserCombinationD__profil {
        strong {
            span {
                &:before {
                    content: "";
                    margin: 0 3px 0 0;
                }
            }
        }
    }
}

.teaserGrid-list__Among--above {
    @include media-breakpoint-down(md) {
        column-count: 2;
        column-gap: 30px;
    }

    @include media-breakpoint-down(xs) {
        column-count: 1;
        column-gap: 15px;
    }
}

.column-count--one {
    @include media-breakpoint-down(md) {
        column-count: 1 !important;
    }

    @include media-breakpoint-down(xs) {
        column-count: 1 !important;
    }
}

.teaserGrid-list {
    &.teaserGrid-list__Among {
        .teaserCombinationD__item--above {
            @include media-breakpoint-down(md) {
                .teaserGrid-list__item {
                    margin-bottom: 0;

                }
            }
        }
    }
}

.teaserCombinationD__Among--item {
    h3, .h3 {
        font-size: 24px;
        line-height: 24px;
    }
}

.teaserCombinationD__profil {
    a {
        color: $color-grey4;
    }
}

