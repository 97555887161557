.contactStacked {
    .teaserGrid-list strong span:before {
        display: none;
    }

    .teaserGrid-list strong span {
        font-family: $font-family-base;
        font-size: 14px;
        line-height: 16px;
        text-transform: none;
        letter-spacing: normal;
        margin: 0 0 20px 0;
        display: block;
    }

    p {
        margin: 0;
    }

    .pageIntro__social {

        > li {

            padding: 0;

            > a {

                width: 40px;
                height: 40px;

                font-size: 1rem;

                &.icon--fb {
                    background-image: url('../svg/sm/facebook_b.svg');
                    background-color: $white;
                    transition: inherit;
                    &:hover {
                        transform: none;
                    }
                }

                &.icon--twitter {
                    background-image: url('../svg/sm/twitter_b.svg');
                    background-color: $white;
                    transition: inherit;
                    &:hover {
                        transform: none;
                    }
                }

                &.icon--xing {
                    background-image: url('../svg/sm/xing_b.svg');
                    background-color: $white;
                    transition: inherit;
                    &:hover {
                        transform: none;
                    }
                }

            }
        }
    }

    figure {
        margin: 0 auto 25px;
        width: 80%;
        max-width: 220px;
    }

    .author-image-wrapper {
        padding-top: 100%;
        max-width: 100%;
        position: relative;
    }

    .author-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .quote {
        color: #000000;
        font-size: 36px;
        letter-spacing: -1px;
        line-height: 42px;
        text-align: center;

        margin-top: 50px;
    }
}



