.ctaTeaser {

    h2, .h2,
    p {
        html.csshyphens & {
            @include hyphenate;
        }
    }

    .page-section--colored-black,
    .page-section--colored-red,
    .page-section--colored-grey {
        color: $color-black;
    }

    &__wrapper {
        @include picture;
        padding: 60px 0;
        margin: 0 0 80px;
    }

    &__left,
    &__right {
        @include media-breakpoint-up(md){
            text-align: left;
        }
    }

    &__inner {

        background-color: $white;
        padding: 40px 30px;

        @include media-breakpoint-down(sm) {
            flex: none;
            margin: 0 auto;
            width: calc(100% - 60px);
        }

    }

    &__inner--img {
        @include pictureGenerell;
        max-width: 200px;
        min-height: 1px;
        margin: $margin-center;

        &.b-loaded {
            width: auto;
        }
    }

    &__button {
        margin: $ctaTeaser__button--margin;
        line-height: 18px;
        height: auto;
        display: inline-block;
        padding: 9px 20px;
        white-space: normal;
    }

    &__double {

        .ctaTeaser__wrapper {
            align-items: center;
            flex-grow: 1;

            @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
                .row {
                    width: 105%; // IE bugfix
                }
            }
        }
    }

    .form-control {
        margin: 0.875rem 0 0;
    }

    &__colored-box {
        background-size: 100% auto;
        background-position-y: 0;
        background-repeat: no-repeat;

        .ctaTeaser__inner {
            margin-top: 26vw;

            @include media-breakpoint-up(md){
                padding: 40px 72px;
            }
        }

        &--grey {
            background-color: $color-lightgrey;
        }

        &--white {
            background-color: white;
        }

        &--black {
            background-color: $color-black;
        }

        &--red {
            background-color: $color-brand;
        }
    }

}
