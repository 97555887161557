/* mobil */

.header {
    &--page {
        &__main {
            @include media-breakpoint-down(md) {

                padding-left: 0;
                padding-right: 30px;

                .dropdown-toggle-main-nav {
                    &:after {
                        display: inline-block;
                        background: $white url('../svg/icons/navi-arrow.svg') no-repeat right 0 center;
                        width: 20px;
                        height: 20px;
                        border: none;
                        padding: 28px 0 0 0;
                        margin: 0 10px 0 0;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                a {
                    &:focus,
                    &:hover {
                        color: $color-brand;
                        text-decoration: none;
                    }
                }

                .mobil-wrapper-0 {
                    transform: translate(0%, 0);
                }

                .mobil-wrapper-1 {
                    transform: translate(-100vw, 0);
                    & + .navigation--mobil--meta {
                        display: none;
                    }
                }

                .mobil-wrapper-2 {
                    transform: translate(-200vw, 0);
                }

                .dropdown-menu {
                    &__headline {
                        display: none;
                    }
                }

                .dropdown-menu {
                    &--main {
                        border: none;
                        width: 100vw;
                        top: 0;
                        left: 100vw;
                    }
                }

                .dropdown-submenu {
                    &__content {
                        background-color: $white;
                    }
                }

                .dropdown-menu__content {
                    li {
                        &:hover,
                        &:focus,
                        &.active {
                            background: $white;
                        }
                    }
                }

                .navigation {
                    &--mobile {
                        width: 100vw;
                    }
                }

                .navigation {
                    &--mobile {
                        &--main {
                            > li {
                                padding: 0; // 0 0 0 33.3px;
                                width: 100vw;
                            }
                        }
                    }
                }

                .navigation {
                    &--mobile {
                        &--main {
                            > li {
                                &:first-child {
                                    padding: 0;
                                }
                            }
                        }
                    }
                }

                .navigation {
                    &--mobile {
                        &--main {
                            border: none;
                            width: 300%;
                            transition: transform 0.3s ease-in-out;
                        }
                    }
                }

                .dropdown-menu {
                    &__backBtn {
                        padding: 0 0 20px 0;
                        border-top: 1px $color-grey3 solid;

                        a {
                            color: $color-grey2;
                            font-size: 16px;
                            line-height: 20px;
                            padding: 0px 15px 15px 20px;
                        }
                    }
                }

                .mobil-wrapper-2 {
                    .dropdown-menu {
                        &__backBtn {
                            border-top: 2px $color-grey3 solid;
                        }
                    }
                }

                .dropdown-toggle-back {
                    &:before {
                        content: '';
                        background: #FFFFFF url('../svg/icons/navi-arrow-back.svg') no-repeat right 0 center;
                        transform: rotate(180deg) translate(0.5rem, -1rem);
                        display: inline-block;
                        width: 20px;
                        height: 40px;
                    }
                }

                .dropdown-menu {
                    &__content {
                        li {
                            &:hover,
                            &:focus,
                            &.active {
                                background: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*!
 * responsive-nav.js 1.0.39 by @viljamis
 */
.navigation--mobile {
    margin: 0 -#{$grid-gutter-width-base/2} 0 $grid-gutter-width-base/2;
    width: 100vw;

    clip: rect(0 0 0 0);
    max-height: 0;
    position: absolute;
    display: block;
    overflow: hidden;
    zoom: 1;

    @include media-breakpoint-down(md) {
        margin-right: -#{$grid-gutter-width-base}/2;
    }

    ul {
        margin: 0;
        padding: 0;
        width: 100vw;
        // display: block;
        list-style: none;

        &:nth-child(3) {
            border-top: none;
        }
    }

    li {
        width: 100%;
        display: block;

        &.nav-item {
            float: left;
            width: 50%;

            .active {
                display: none;
            }
        }
    }

    &.opened {
        max-height: 9999px;
        min-height: 600px;
    }

    &,
    &--meta {
        margin-left: $grid-gutter-width-base/2;
    }

    &--meta {
        margin-right: $grid-gutter-width-base/2;
    }

    &--main {
        .navigation--mobile & {
            padding-bottom: 30px;
        }

        border-bottom: 2px solid $color-grey3;

        > li {
            > a {
                font-size: 1.4rem;
                line-height: 28px;
                color: $color-darkgrey;
                font-weight: 300;
                text-transform: uppercase;
                letter-spacing: 1px;
                padding: 14px 30px 14px $grid-gutter-width-base/2;
                display: block;
                position: relative;
            }
        }
    }

    &--meta {
        @extend .navbar-nav--meta;
        border-bottom: 2px solid $color-grey3;
        border-top: 2px solid $color-grey3;

        > li {
            > a {
                font-size: 15px;
                line-height: 22px;
                letter-spacing: 1px;
                font-weight: 300;
                color: $color-grey1;
                padding: 10px $grid-gutter-width-base/2;
                text-transform: uppercase;
            }
        }

        .navigation--mobile & {
            padding-bottom: 20px;
            padding-top: 20px;
            display: inline-block;
        }
    }

    &--cta {
        .navigation--mobile & {
            padding: 30px 0 160px 15px;

            @include media-breakpoint-down(md) {
                @include media-breakpoint-up(md) {
                    display: none;
                }
            }
        }

        .btn {
            width: 100%;
            display: block;
            float: left;
        }
    }

    &--main {
        border: none;
        width: 300%;
        transition: transform 0.3s ease-in-out;
        position: relative;
        z-index: 2;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: $search-mobile-line-height + $search-mobile-padding-y * 2;
            z-index: 1;
            border-top: $search-mobile-border-top solid $color-grey3;
            border-bottom: $search-mobile-border-bottom solid $color-grey3;
            background-color: $color-lightgrey;
        }

        > li {
            padding: 0;
            width: 100vw;

            &:first-child {
                padding: 0;
            }
        }
    }
}

.navigation--mobile__toggle,
#navigation--mobile__toggle {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    user-select: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1050;
    display: block;
    line-height: 1;
    transform: translate(0, 6px);
    margin-right: 0;
}

@include media-breakpoint-up(lg) {
    .navigation--mobile {
        position: relative;

        &.closed {
            max-height: 0;
        }
    }

    .navigation--mobile__toggle,
    #navigation--mobile__toggle {
        display: none;
    }
}

/* meta navigation */

.mobil-wrapper-1 + .navigation--mobile--meta {
    display: none;
}

.mobil-wrapper-1 + .navigation--mobile--meta + .navigation--mobile--meta {
    display: none;
}

.mobil-wrapper-1 + .navigation--mobile--meta + .navigation--mobile--meta + .navigation--mobile--cta {
    display: none;
}

.mobil-wrapper-2 + .navigation--mobile--meta {
    display: none;
}

.mobil-wrapper-2 + .navigation--mobile--meta + .navigation--mobile--meta {
    display: none;
}

.mobil-wrapper-2 + .navigation--mobile--meta + .navigation--mobile--meta + .navigation--mobile--cta {
    display: none;
}

.row.hidden-lg-up {
    background: white;
}
