.add-address-form {
    .icon-igm-refresh {
        color: $color-brand;
        cursor: pointer;
        position: absolute;
        top: 7px;
        right: 10px;

        float: right;
        width: 16px;
        height: 16px;
        line-height: 1;

        -animation: spin .7s infinite linear;
        -ms-animation: spin .7s infinite linear;
        -webkit-animation: spinw .7s infinite linear;
        -moz-animation: spinm .7s infinite linear;
    }

    .input-wrapper {
        position: relative;
    }

    .typeahead-address input::-ms-clear {
        display: none;
    }

    .typeahead-address .custom-popup-wrapper > .dropdown-menu {
        box-shadow: 0 6px 12px 0 $color-lightgrey5;
        border-radius: 0.25rem;
        padding: 0;

        a {
            padding: 5px 15px;
        }
    }

    @keyframes spin {
        from {
            transform: scale(1) rotate(0deg);
        }
        to {
            transform: scale(1) rotate(360deg);
        }
    }

    @-webkit-keyframes spinw {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
        }
    }

    @-moz-keyframes spinm {
        from {
            -moz-transform: rotate(0deg);
        }
        to {
            -moz-transform: rotate(360deg);
        }
    }
}
