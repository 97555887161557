.teaser-grid {

    .grid-1-coloumns,
    .grid-2-coloumns,
    .grid-3-coloumns,
    .grid-4-coloumns {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .teaser {
            flex: 0 0 100%;
            max-width: 100%;
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    .teaser {
        margin-bottom: 1.5rem;
    }

    .teaser .image {
        max-width: 100%;
    }

    @include for-tablet-up {
        .teaser {
            margin-bottom: 1rem;
        }

        .grid-1-coloumns .teaser {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .grid-2-coloumns .teaser {
            flex: 0 0 50%;
            max-width: 50%;
        }

        .grid-3-coloumns .teaser {
            flex: 0 0 50%;
            max-width: 50%;
        }

        .grid-4-coloumns .teaser {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    @include for-desktop-up {
        .grid-1-coloumns .teaser {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .grid-2-coloumns .teaser {
            flex: 0 0 50%;
            max-width: 50%;
        }

        .grid-3-coloumns .teaser {
            flex: 0 0 33.33333333%;
            max-width: 33.33333333%;
        }

        .grid-4-coloumns .teaser {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }
}

