/* breadcrumb */

.breadcrumb {

    margin: 0;

    &-nav {
        padding: 0;
        height: 31px;
        background-color: $color-lightgrey;
        border-radius: 0;
        /*margin-top: 0.1rem;*/

        /* max-width: 1400px;
         padding: 0 50px;
         margin: auto;*/

        @include media-breakpoint-up(lg) {
            display: block !important;
        }

        li {
            font-size: 0.75rem;
            line-height: 1.9375rem;

            a {
                color: $black;
                padding-right: 0.5rem;

                &:hover {
                    text-decoration: none;
                    color: $color-brand;
                }
            }

            &.active {
                a,
                a:hover,
                a:focus {
                    color: $color-brand;
                    pointer-events: none;
                    cursor: default;
                }
            }

            &.breadcrumb-item {
                + .breadcrumb-item {
                    &:before {
                        content: ">";
                    }
                }
            }
        }
    }

    &-auth {
        @extend .breadcrumb-nav;
    }

    &-nav,
    &-auth {
        @include media-breakpoint-up(lg) {
            flex-direction: row;
            width: auto;
            display: inline-block;
            margin-bottom: 0;
        }
    }

    &-nav {
        @include media-breakpoint-up(lg) {
            align-self: flex-start;
        }
    }

    &-auth {
        float: right;

        @include media-breakpoint-up(lg) {
            align-self: flex-end;
            margin: 0 -30px 0 0;
        }

        > li:last-child {
            a {
                padding-right: 0;
            }
        }
        @include media-breakpoint-down(md) {
            float: right;
            background: none;
        }
    }

    .icon {
        transform: translate(0, 1px);

    }

    &-steps {
        @extend .breadcrumb-nav;
        background-color: transparent !important;
        padding-top: 1rem;
        li {
            &.breadcrumb-item {

                line-height: $line-height-h4;
                font-family: $font-family-CondOTBold;
                font-size: $font-size-h4;
                color: $color-grey2;

                &:before {

                    color: $color-grey2;

                    padding-right: 3rem !important;
                    padding-left: 3rem !important;

                    @include media-breakpoint-down(lg) {
                        padding-right: 1.5rem !important;
                        padding-left: 1.5rem !important;
                    }

                    @include media-breakpoint-down(md) {
                        padding-right: 0.5rem !important;
                        padding-left: 0.5rem !important;
                    }
                }

                a {
                    line-height: $line-height-h4;
                    font-family: $font-family-CondOTBold;
                    font-size: $font-size-h4;
                    color: $color-grey1;

                    &:before {

                        color: $color-grey2;

                        padding-right: 3rem !important;
                        padding-left: 3rem !important;

                    }

                    &[disabled] {
                        color: $color-grey2;
                        cursor: not-allowed;
                    }

                }

                &--active {

                    a {
                        color: $color-brand;
                    }
                }
            }
        }

    }

}

.breadcrumb-overlay {
    max-width: 100%;
    background-color: #F8F8F9;
    border-top: 1px #EBEBEF solid;
    /*margin-top: 0.1rem;*/

    > .container {
        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

.breadcrumb-item +
.breadcrumb-item::before {
    padding-left: 0;
}

.breadcrumb-dropdown + li:before,
.breadcrumb-ellipsis + li:before {
    display: none;
}

.breadcrumb-menu {
    display: none;
}

.breadcrumb-item {
    float: none;
}
