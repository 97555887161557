.teaserCombinationE {
    .teaserGrid-list__slide {
        .teaserGrid-list__item {
            &:hover {
                &.teaser-list__audio {
                    strong {
                        &:before {
                            background: transparent url(../svg/icons/audio-b.svg) no-repeat right 0 center;
                        }
                    }
                }

                &.teaser-list__video {
                    strong {
                        &:before {
                            background: transparent url(../svg/icons/video-b.svg) no-repeat right 0 center;
                        }
                    }
                }

                &.teaser-list__gallery {
                    strong {
                        &:before {
                            background: transparent url(../svg/icons/album-b.svg) no-repeat right 0 center;
                        }
                    }
                }
            }

            // Kategorie über Bild
            .cat {
                position: absolute;
                top: 0;
                left: 15px;
                z-index: 3;
                background: $color-brand;
                color: $color-white;
                padding: 5px 20px;
                text-transform: uppercase;

                strong {
                    font-weight: normal;
                }
            }

        }
    }

}

/* -----------------------
    Abstaende der Bloecke
 ------------------------*/

.teaserGrid-list {
    &.teaserCombinationE {
        .teaserCombinationE__item {
            figure {
                margin: 0 0 1.125rem 0;
            }
        }
    }
}

.teaserGrid-list {
    &.teaserCombinationE {
        .teaserCombinationE__item {
            .teaserGrid-list__Among--content {
                figure {
                    margin: 0 0 1.125rem 0;
                }
            }
        }
    }
}

.teaserCombinationE__item {

    .teaserGrid-list__Among--content {
        margin: 0 0 20px 0;
    }
}

.teaserCombinationE__item.teaserCombinationE__item--above {
    .teaserCombinationE__item {
        .cat {
            height: 140px;
            width: 135px;
            font-size: 18px;
            text-align: center;
            display: table;

            strong {
                color: $color-white;
                display: table-cell;
                vertical-align: bottom;
            }

            @include media-breakpoint-down(xs) {
                height: auto;
                width: auto;
            }
        }
    }
}

.teaserCombinationE__item.teaserCombinationE__item--above {
    .teaserCombinationE__item.teaserGrid-list__Among--item {
        .cat {
            height: auto;
            width: auto;
            font-size: 12px;
            text-align: center;
            display: table;
        }
    }
}

.teaserGrid-list__Among .teaserGrid-list__slide .teaserGrid-list__item:hover .teaserGrid-list__Among--content .cat {
    color: $color-white;
}

.teaserGrid-list .teaserCombinationE__item strong {
    color: $color-brand;
}

.teaserCombinationE__item {
    .teaserGrid-list__Among--below {
        .teaserGrid-list__Among--content {
            margin: 0 0 20px 0;

            &:last-child {
                margin: 0 0 0 0;

                figure {
                    margin: 0 0 0 0;

                }

                h3, .h3 {
                    margin: 0;
                }
            }
        }
    }
}

.teaserCombinationE__item {
    .teaserGrid-list__Among--below {
        .teaserGrid-list__Among--content {
            h3, .h3 {
                margin: 0;
            }
        }
    }
}

.teaserCombinationE__item {

    .teaserGrid-list__Among--above {

        .teaserGrid-list__Among--content {

            &:last-child {
                margin: 0 0 0 0;
            }
        }
    }
}

@include media-breakpoint-down(md) {

    .teaserCombinationE__item {

        .teaserGrid-list__Among--above {

            .teaserGrid-list__Among--content {

                &:nth-child(3) {
                    margin: 0 0 0 0;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {

    .teaserCombinationE__item {

        .teaserGrid-list__Among--above {

            .teaserGrid-list__Among--content {

                &:nth-child(3) {
                    margin: 0 0 20px 0;
                }
            }
        }
    }
}

.teaserCombinationE__item {
    .col-sm-3 {
        padding-right: 0;
    }
}

.teaserCombinationE__item--above {
    @include media-breakpoint-up(lg) {
        margin-bottom: 40px;
    }
}

.teaserCombinationE__item--below {
    margin-bottom: 6px;
}

.teaserGrid-list {
    &.teaserGrid-list__Among {
        .teaserCombinationE__item--above {
            .teaserGrid-list__item {
                margin-bottom: 0;
            }

            @include media-breakpoint-down(md) {

                .teaserGrid-list__item {
                    margin-bottom: 40px;
                }
            }

            p {
                margin: 0;
            }
        }
    }
}

.teaserGrid-list__Among--above {
    @include media-breakpoint-down(md) {
        column-count: 2;
        column-gap: 30px;
    }
    @include media-breakpoint-down(xs) {
        column-count: 1;
        column-gap: 15px;
    }
}
