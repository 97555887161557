.payout-calculator {
    .box-input {
        h3, .h3 {
            margin-bottom: 40px;
        }
    }

    .result {
        display: none;
    }

    .calculated-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .th {
        padding: 15px;
        background-color: $color-lightgrey6;
        font-family: $font-family-CondOTBold;
        font-size: 13px;
        line-height: 12px;
        margin-bottom: 5px;
    }

    .tr {
        margin-bottom: 30px;
    }

    .caption {
        margin-bottom: 20px;
    }

    .table {
        p {
            margin-bottom: 0;
        }

        hr {
            margin-left: -15px;
            width: calc(100% + 30px);
            margin-bottom: 30px;
        }

        .text-right {
            text-align: right;
        }

        @include for-tablet-up {
            .calc {
                text-align: right;
            }
        }
    }

    .container.bordered {
        padding-left: 0;
        padding-right: 0;
    }

    @include for-tablet-up {
        .container.bordered {
            border: solid 1px $color-table-border;
            border-radius: 3px;
            padding-top: 40px;
            padding-right: 40px;
            padding-bottom: 40px;
            padding-left: 40px;
        }
    }
}
