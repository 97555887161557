.bg {
    &-brand {
        background-color: $color-brand;
    }

    &-white {
        background-color: $color-white;
    }
}

.c {
    &-brand {
        color: $color-brand;
    }

    &-white {
        color: $color-white;
    }
}

.f {
    &-brand {
        fill: $color-brand;
    }

    &-white {
        fill: $color-white;
    }
}

.p {
    &-abs {
        position: absolute;
    }

    &-rel {
        position: relative;
    }
}

// Margins
@for $i from -20 to 20 {
    .m-#{$i*5} {
        margin: #{$i*5}px !important;
    }
    .mt-#{$i*5} {
        margin-top: #{$i*5}px !important;
    }
    .mb-#{$i*5} {
        margin-bottom: #{$i*5}px !important;
    }
    .ml-#{$i*5} {
        margin-left: #{$i*5}px !important;
    }
    .mr-#{$i*5} {
        margin-right: #{$i*5}px !important;
    }
    .mh-#{$i*5} {
        margin-left: #{$i*5}px !important;
        margin-right: #{$i*5}px !important;
    }
    .mv-#{$i*5} {
        margin-top: #{$i*5}px !important;
        margin-bottom: #{$i*5}px !important;
    }
}

// Paddings
@for $i from 0 to 20 {
    .p-#{$i*5} {
        padding: #{$i*5}px !important;
    }
    .pt-#{$i*5} {
        padding-top: #{$i*5}px !important;
    }
    .pb-#{$i*5} {
        padding-bottom: #{$i*5}px !important;
    }
    .pl-#{$i*5} {
        padding-left: #{$i*5}px !important;
    }
    .pr-#{$i*5} {
        padding-right: #{$i*5}px !important;
    }
    .ph-#{$i*5} {
        padding-left: #{$i*5}px !important;
        padding-right: #{$i*5}px !important;
    }
    .pv-#{$i*5} {
        padding-top: #{$i*5}px !important;
        padding-bottom: #{$i*5}px !important;
    }
}

// Display
.db {
    display: block !important;
}

.di {
    display: inline !important;
}

.dib {
    display: inline-block !important;
}

// Positioning
.pos-r {
    position: relative;
}

.pos-a {
    position: absolute;
}

.pos-s {
    position: static;
}

.pos-f {
    position: fixed;
}

// Vertical alignment
.va-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.va-item {
    display: block;
}

.va-item--top {
    align-self: flex-start;
}

.va-item--bottom {
    align-self: flex-end;
}

.left {
    float: left;
    margin: 0.5em 1em 1em 0;
}

.right {
    float: right;
    margin: 0.5em 0 1em 1em;
}

.clear {
    clear: both;
}

.justify-content-center {
    justify-content: center;
}

.dn-xs {
    @media screen and(max-width: $tablet-size - 1px) {
        display: none;
    }
}

.dn-md {
    @media screen and(min-width: $tablet-size) and (max-width: $tablet-landscape-size - 1px) {
        display: none;
    }
}

.dn-lg {
    @media screen and(min-width: $tablet-landscape-size) and (max-width: $desktop-size - 1px) {
        display: none;
    }
}

.dn-xl {
    @media screen and(min-width: $desktop-size){
        display: none;
    }
}

.h {
    &-vt {
        &25 {
            height: 25vh;
        }
        &50 {
            height: 50vh;
        }
        &75 {
            height: 75vh;
        }
        &100 {
            height: 100vh;
        }
    }
}

/*.link-arrow:after {
  content: url('../../igm/svg/icons/link-arrow-red.svg');
  display: inline-block;
  font-size: .75em;
  width: 1em;
  height: auto;
  margin-left: .5em;
  vertical-align: middle;
}*/

.icon-igm-link-arrow {
    &.small {
        font-size: 66.6666%;
    }
}

.icon-input {
    position: relative;

    &::before {
        position: absolute;
        right: 1em;
        top: .45em;
        color: $color-lightgrey3;
        cursor: pointer;
    }
}


.mobile-inline,
.mobile-inline-block,
.mobile-block,
.tablet-inline,
.tablet-inline-block,
.tablet-block,
.phone-inline,
.phone-inline-block,
.phone-block {
    display: none !important;
}

.mobile-inline {
    html.mobile & {
        display: inline !important;
    }
}

.mobile-inline-block {
    html.mobile & {
        display: inline-block !important;
    }
}

.mobile-block {
    html.mobile & {
        display: block !important;
    }
}


.phone-inline {
    html.phone & {
        display: inline !important;
    }
}

.phone-inline-block {
    html.phone & {
        display: inline-block !important;
    }
}

.phone-block {
    html.phone & {
        display: block !important;
    }
}


.tablet-inline {
    html.tablet & {
        display: inline !important;
    }
}

.tablet-inline-block {
    html.tablet & {
        display: inline-block !important;
    }
}

.tablet-block {
    html.tablet & {
        display: block !important;
    }
}



// Letter Spacings
@for $i from 0 to 5 {
    .ls-#{$i} {
        letter-spacing: #{$i}px !important;
    }
}




// Pseudo elements
.no-after {
    &:after {
        display: none !important;
        content: none;
    }
}

.no-before {
    &:before {
        display: none !important;
        content: none;
    }
}

.no-shadow {
    box-shadow: none !important;
}

.ratio-16to9 {
    position: relative;
    display: table;
    width: 100%;

    &:before {
        content: '';
        display: block;
        width: 100%;
        padding-top: (9/16) * 100%
    }

    > .ratio-16to9-inner {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
    }
}

/**
 * Font sizes
 */
@for $i from 10 to 17 {
    .fs-#{$i} {
        font-size: #{$i}px !important;
    }
}
