.teaser-combination-h {

    .main-teaser, .events {
        margin-bottom: 30px;
    }

    .main-teaser, .events {
        margin-bottom: 30px;
    }

    .teaser .headline {
        font-size: 18px;
        line-height: 24px;
    }

    @include for-tablet-up {
        .main-teaser {
            margin-bottom: 40px;
        }

        .teaser .headline {
            font-size: 24px;
            line-height: 30px;
        }
    }

    @include for-tablet-landscape-up {
        .main-teaser {
            margin-bottom: 50px;
        }
    }
}
