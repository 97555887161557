.form-group,
.input-group,
.custom-controls-stacked {
    &.has-error {
        label {
            color: $color-brand;

            &.error {
                font-weight: normal;
            }
        }

        #{$all-text-inputs},
        select {
            border-color: $color-brand;

            @include img-plchld {
                color: #333333;
            }
        }

        p {
            color: $color-brand;
        }
    }
}

.custom-validation {
    &.has-error {
        color: $color-brand;
    }
}

