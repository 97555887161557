.bildTextModul {

    h2, .h2,
    h4, .h4,
    p {
        html.csshyphens & {
            @include hyphenate;
        }
    }
    
    h2, .h2 {
        margin-top: -8px;
    }

    a {
        &:focus,
        &:hover {
            text-decoration: none;
        }
    }

    p {
        margin: 0;
    }

    ul, ol, dl {
        margin: 30px 0;
    }

    .bildTextModul--colored {

        &-green {

            li {

                &:before {
                    content: url('../svg/icons/checkmarkcircle-g.svg');
                }
            }
        }

        &-red {

            li {

                &:before {
                    content: url('../svg/icons/checkmarkcircle-r.svg');
                }
            }
        }

        &-black {

            li {

                &:before {
                    content: url('../svg/icons/checkmarkcircle-b.svg');
                }
            }
        }
    }

    li,
    .li {
        margin-bottom: 9px;

        font-family: $font-family-base;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 200;
        list-style: none;

        &:before {
            content: url('../svg/icons/checkmarkcircle-b.svg');
            position: absolute;
            width: 1em;
            height: auto;
            display: inline-block;
            margin: 4px 1.5em 0 -2.5em;

            font-size: 0.75em;
            vertical-align: middle;
        }
    }

    h3.li {
        display: inline-block;

        &:before {
            content: none;
            display: none;
        }
    }

    .contentBlock__linkList {
        margin-bottom: 0;
    }

    @include media-breakpoint-down(sm) {

        .teaserGrid-list__item {
            p {

                display: block;
            }
        }

        .bildTextModul--beneath {
            margin: 0 0 20px 0;
        }

    }

}

.bildTextModul--noCheck {
    ul {
        padding-left: 0;
    }

    li:before {
        content: '';
        display: none;
    }
}
