.accessibility {
  filter: grayscale(100%);
}

.accessibility_button {
  width: 100px;
  text-align: center;
  border-left: 1px solid #cccccc;
}

.icon-igm-kontrast:before {
  margin-right: 5px;
  font-size: 8px;
}

@media screen and (max-width: 1000px) {
  .accessibility_button {
    border-left: 0 solid #cccccc;
  }
  .icon-igm-kontrast:before {
    font-size: 11px;
  }
}

