.igmetall-map-cont {

    #igmetall-map-canvas {
        position: relative;
        height: 100vw;
        max-height: 644px;
    }

    #igmetall-search-error {
        display: none;
        margin: 0;
        width: auto;

        color: $color-light-red;
        padding: 7px 15px;
        font-size: 14px;
    }

    .search-input-wrapper {
        position: relative;

        #igmetall-search-btn {
            position: absolute;
            right: 5px;
            top: 7px;

            background-color: transparent;
            border: none;
            cursor: pointer;
        }

        .form-control {
            padding-top: 11px;
        }
    }

    .igm-zoom-control {
        display: none;
    }

    #map-overlay {
        position: absolute;
        top: 10%;
        left: 25%;
        width: 50%;
        background-color: $color-white;
        z-index: 10;

        padding: 20px 30px;

        font-size: 16px;
        line-height: 28px;

        border-radius: 3px;
        box-shadow: 0 6px 12px 0 $color-lightgrey5;

        @include media-breakpoint-down(sm) {
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 15px;
            width: auto;
            height: auto;
            line-height: 1;
            font-size: 85%;
        }

        h1, .h1 {
            color: $color-black;
            font-size: 24px;
            font-family: $font-family-CondOTBold;
            line-height: 30px;
        }

        .close {
            position: absolute;
            top: 26px;
            right: 26px;
            z-index: 11;

            font-size: 14px;
            opacity: 1;
        }

        p {
            margin-bottom: 0;
        }

        .hidden {
            margin-bottom: 0;
            margin-top: -20px;
            color: $color-white;
        }

        table.opening-times {
            width: 100%;

            span {
                display: block;

                @include for-phone-up {
                    display: inline-block;
                    min-width: 95px;
                }
            }

            td {
                vertical-align: top;
            }
        }

        .time-toggle {
            cursor: pointer;
            margin-top: 25px;

            &.open .icon-igm-dropdown-arrow::before {
                display: inline-block;
                transform: rotate(180deg);
            }
        }

        a i {
            font-size: 9px;
        }

        .icon-igm-dropdown-arrow {
            font-size: 8px;
            margin-left: 5px;
        }

        .opening-times {
            display: none;

            &.open {
                display: table;
            }
        }
    }

    #igmetall-map-btns {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10px;
    }

    .igmetall-map-btn {
        padding: 16px 28px;
        border: 1px solid $color-table-border;
        border-radius: 4px;
        cursor: pointer;

        margin-bottom: 10px;
        width: 100%;

        @include for-tablet-up {
            width: auto;
            margin-right: 10px;
            margin-bottom: 0;
            flex-grow: 1;
        }

        &:last-child {
            margin-right: 0;
            margin-bottom: 0;
        }

        &.active {
            background-color: $color-lightgrey6;
        }
    }

    .icon {
        height: 22px;
        margin-right: 13px;

        &.small {
            height: 15px;
        }
    }

    .control-wrapper {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        flex-direction: row;

        background-color: $color-white;
        box-shadow: 0 6px 12px 0 $color-lightgrey5;
    }

    .igm-map-btn {

        padding: 10px 0 10px 10px;
        color: $color-lightgrey3;

        font-size: 13px;
        font-family: $font-family-CondOTBold;
        line-height: 12px;

        cursor: pointer;

        min-width: 50px;

        .text {
            padding-right: 10px;

            &.active {
                color: $color-black;
            }

            &:not(.noBorder) {
                border-right: solid 2px $color-table-border;
            }
        }
    }

    .shadow {
        display: none;
    }

    .map-marker-label {
        position: absolute;
        display: none;
    }
}
