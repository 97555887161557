.image-gallery {
    $image-gallery__nav-container-height: 90px;

    & {
        position: relative;
    }

    .preview {
        background-color: #F8F8F9;
        border: 1px solid #CCCCCC;
        padding: 10px 10px 0 10px;
        max-width: 100%;
        margin-bottom: 40px;
        height: calc(100% - #{$grid-gutter-width-base});

        .copyright {
            font-size: 11px;
        }

    }

    .preview__image {
        cursor: pointer;
        padding-top: 75%;
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .preview__caption {
        span {}

        i.icon-igm-download {
            font-size: 12px;
            transform: translate(0, -1px);
            display: inline-block;
        }
    }

    .preview__caption-text {
        font-size: 12px;
        color: #333;
    }

    &__detail-view {
        position: relative;
        margin-top: -20px;

        .owl-carousel {
            .owl-item .slide__image {
                width: 1400px;
                max-width: 100%;
            }

            .owl-stage {
                display: flex;
                align-items: stretch;
            }

            .owl-item {
                text-align: center;
            }
        }
    }

    .image-gallery__nav-container {
        .owl-nav {
            button {
                &.owl-prev,
                &.owl-next {
                    &,
                    & i:before {
                        color: $color-brand;
                    }
                }
            }
        }
    }

    .slide {
        display: inline-block;
        margin: 0 auto;
        width: 100%;
        background-color: transparent;

        @media screen and(min-width: $tablet-size) {
            min-height: auto;
        }

        > .row {
            overflow-y: visible;
        }
    }

    &__close {
        position: absolute;
        top: -10px;
        right: -10px;
        cursor: pointer;
        padding: 10px;
        z-index: 9001;
        display: block;

        @media screen and(min-width: $tablet-size) {
            top: 25px;
            right: 2vw;
        }

        &,
        &:hover,
        &:focus {
            color: $color-black;
        }
    }

    .slide__image-wrapper {
        margin: 40px 0 0 0;
        overflow: hidden;

        .image {
            padding: 56.25% 0 0 0;
            background-position: 50% 50%;
            background-size: contain;
            background-repeat: no-repeat;
            margin: 30px 30px 5px 30px;
        }

        &.no-copyright .image {
            margin: 30px;
        }
    }

    .slide__image {
        height: calc(100vh - 200px);
        margin: 0 auto;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        background-size: contain;
    }

    .slide__image-wrapper,
    .slide__text-wrapper {
        background-color: $color-lightgrey;
    }

    .slide__text-wrapper {
        width: 100%;
        min-height: 60px;
        margin-top: -1px;

        .no-copyright {
            .slide__info-bar {
                padding: 10px 30px;
            }
        }
    }

    .slide__info-bar,
    .slide__text {
        padding: 0 30px;
        border-bottom: 1px solid $color-grey3;
    }

    .slide__info-bar {
        display: flex;
        justify-content: space-between;
        text-align: left;
    }

    .slide__text {
        margin: 0 auto;
        text-align: left;
        min-height: 60px;
        padding: 0 30px 40px 30px;
        position: relative;

        @include clearfix;

        h2 {
            margin-top: 1.75rem;
        }

        p {
            color: #333333;
            margin-top: 30px;
        }

        h2 + p {
            margin-top: 0;
        }

        *:last-child {
            margin-bottom: 0;
        }
    }

    .slide__copyright,
    .slide__date {
        color: rgba(0, 0, 0, 0.5);
        font-size: 0.6875rem;
        line-height: 1.5rem;
    }

    .slide__copyright {
        text-align: center;
        display: block;
    }

    .slide__download-wrapper {
        float: right;
        margin: 1.25rem 0 2rem 2rem;
    }

    .preview__caption {
        .slide__download-wrapper {
            margin: 0 0 2rem 2rem;

            .label {
                display: none;
            }
        }
    }

    .slide__download {
        position: relative;
        display: block;
        color: red;
        cursor: pointer;
        white-space: nowrap;
        text-decoration: none;
        font-size: 14px;

        .icon-igm-dropdown-arrow {
            display: inline-block;
            font-size: 6px;
            transform: translateY(-1px);
        }

        &.open {
            background-color: $color-white;

            .icon-igm-dropdown-arrow {
                transform: translateY(-3px) rotate(180deg);
            }

            .slide__download-window {
                display: block;
            }
        }
    }

    .slide__download-window {
        position: absolute;
        top: 100%;
        right: -8px;
        background-color: $color-white;
        display: none;
        padding: 18px 20px;
        margin-top: -24px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
        z-index: $zindex-dropdown;

        .slide__download__close-trigger {
            position: absolute;
            top: 3px;
            right: 10px;
            display: inline-block;
        }

        .paranthesis {
            color: $color-grey1;
        }
    }

    &__nav-container {
        height: $image-gallery__nav-container-height;
        position: relative;
        overflow: hidden;

        .owl-nav {
            position: absolute;
            top: 20px;
            left: 0;
            width: 100%;
            height: 47px;
            z-index: 1045;

            button {
                -webkit-appearance: none;
                cursor: pointer;

                &.owl-prev,
                &.owl-next {
                    position: absolute;
                    background-color: $color-lightgrey5;
                    top: 0;
                    padding: 11px !important;
                    color: $color-white;
                    border: none;

                    @media screen and(min-width: $tablet-size) {
                        background: transparent;
                    }
                }

                &.owl-prev {
                    left: 3vw;
                }

                &.owl-next {
                    right: 3vw;
                }

                &.owl-prev,
                &.owl-next {
                    &,
                    & i:before {
                        color: #000;
                    }
                }
            }
        }

        .owl-dots {
            position: absolute;
            height: 10px;
            top: 65px;
            left: 50%;
            transform: translate(-50%, -34px);
            z-index: 1050;

            .owl-dot {
                width: 10px;
                height: 10px;
                border-radius: 5px;
                margin: 0 7px;
                border: 1px solid $color-brand;
                background-color: $color-white;
                outline: none;
                -webkit-appearance: none;
                padding: 0;

                &.active {
                    border-color: $color-brand;
                    background-color: $color-brand;
                }
            }
        }
    }

    .image-gallery__footer {
        position: relative;
        height: auto;
        margin-bottom: -20px;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left:0;
            width: 100%;
            height: 2px;
            background-color: $color-grey3;
        }

        p {
            margin: 20px auto 0 auto;

            i {
                font-size: 12px;
                display: inline-block;
                transform: translateY(-1px);
            }
        }
    }

    i,
    span {
        &.icon-igm-download,
        &.icon-igm-album {
            position: relative;
            top: 0.125em;
        }

        &.icon-igm-download {
            font-size: 12px
        }
    }


    .owl-stage-outer::after {
        width: 1px;
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: white;

        @include media-breakpoint-up(md) {
            content: none;
        }
    }

    &.page-section--colored-black {
        .slide__image-wrapper,
        .slide__text-wrapper {
            background-color: $color-lightgrey;
        }

        .image-gallery__header .header .headline { color: $color-white; border-bottom-color: $color-white; }
        .image-gallery__header .header { border-bottom-color: rgba(235, 235, 239, 0.5); }
        .image-gallery__header p { color: $color-white; }

        .slide__text {
            h2, .h2 {
                color: $color-black;
            }
        }

        .image-gallery__close {color: $color-white;}

        .image-gallery__nav-container {
            .owl-dots {
                .owl-dot {
                    border-color: $color-white;
                    background-color: $color-black;

                    &.active {
                        border-color: $color-white;
                        background-color: $color-white;
                    }
                }
            }

            .owl-nav {
                button {
                    &.owl-prev,
                    &.owl-next {
                        &,
                        & i:before {
                            color: $color-white;
                        }
                    }
                }
            }
        }

        .image-gallery__footer {
            &:before {
                background-color: $color-grey3;
            }
            p, a {
                color: $color-white;
            }
        }


        .owl-stage-outer::after {
            background-color: black;
        }
    }

    &.page-section--colored-red {
        .slide__image-wrapper,
        .slide__text-wrapper {
            background-color: $color-lightgrey;
        }

        .image-gallery__header .header .headline { color: $color-white; border-bottom-color: $color-white; }
        .image-gallery__header .header { border-bottom-color: rgba(235, 235, 239, 0.5); }
        .image-gallery__header p { color: $color-white; }

        .slide__text {
            h2, .h2 {
                color: $color-black;
            }
        }

        .image-gallery__close {color: $color-white;}

        .image-gallery__nav-container {
            .owl-dots {
                .owl-dot {
                    border-color: $color-white;
                    background-color: $color-brand;

                    &.active {
                        border-color: $color-white;
                        background-color: $color-white;
                    }
                }
            }

            .owl-nav {
                button {
                    &.owl-prev,
                    &.owl-next {
                        &,
                        & i:before {
                            color: $color-white;
                        }
                    }
                }
            }
        }

        .image-gallery__footer {
            &:before {
                background-color: $color-grey3;
            }
            p, a {
                color: $color-white;
            }
        }


        .owl-stage-outer::after {
            width: 1px;
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: $color-brand;
        }
    }

    &.page-section--colored-grey {
        .slide__image-wrapper,
        .slide__text-wrapper {
            background-color: #fff; // $color-lightgrey;
        }

        .owl-stage-outer::after {
            background-color: white;
        }
    }

    .owl-stage-outer {
        transition: none !important; // height 1ms ease-in-out;
    }
}
