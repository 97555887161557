.teaser-combination-d {
    .contact-wrapper {
        text-align: center;
    }

    .arrow-link {
        font-size: 14px;
    }

    .teaser-block {
        margin-top: 60px;

        .teaser .headline {
            font-size: 18px;
            font-family: $font-family-CondOTBold;
            line-height: 24px;
            margin-bottom: 20px;
        }
    }

    .teaser-column {
        .teaser {
            margin-bottom: 30px;
        }
    }

    .teaser-block {
        .teaser {
            margin-bottom: 30px;
        }
    }

    .contact-wrapper {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .footer {
        margin-top: 40px;
    }

    @include for-tablet-up {
        .contact-wrapper {
            margin-top: 80px;
            margin-bottom: 80px;
        }

        .teaser-column {
            .teaser .headline {
                margin-bottom: 20px;
            }
        }
    }

    @include for-tablet-landscape-up {
        .contact-wrapper {
            margin-top: 40px;
            margin-bottom: 0;
        }
    }
}
