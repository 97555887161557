// GRID SETTINGS
$max-width                      : 1400px !default;
$grid-columns                   : 12 !default;
$gutter                         : 30px !default;
$default-feature                : min-width;



// Grid breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1000px,
    xl: 1200px
) !default;

$mobile-size: 576px;
$tablet-size: 768px;
$tablet-landscape-size: 1000px;
$desktop-size: 1200px;


// Slider breakpoint
$grid__breakpoint--xxl          : 1680px;




// Grid containers
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1400px
) !default;




// COLORS
$color-brand                    : #E3020E;
$color-accent                   : #518DA3;
$color-accent-IGM               : #ED7F08;
$color-brand-youth              : rgba(255,220,7,1);

$color-black                    : #181618;
$color-darkgrey                 : #2C292C;
$color-grey1                    : #847E85;
$color-grey2                    : #BFBFC8;
$color-grey3                    : #EBEBEF;
$color-grey4                    : #333333;
$color-table-border             : #D8D8D8;
$color-lightgrey                : #F8F8F9;
$color-lightgrey2               : #EFEFEF;
$color-lightgrey3               : rgba(0, 0, 0, 0.5);
$color-lightgrey4               : rgba(0, 0, 0, 0.3);
$color-lightgrey4-2             : rgba(0, 0, 0, 0.2);
$color-lightgrey5               : rgba(0, 0, 0, 0.1);
$color-lightgrey6               : #F5F5F5;

$color-font-default             : $color-grey4;

$color-white                    : #FFFFFF;


$color-pink                     : #FF008A;
$color-success                  : #43B36C;
$color-info                     : #003EFF;
$color-information              : #F58F23;
$color-error                    : #EA4848;
$color-error-box                : #ffe1e1;
$color-menu                     : rgba(0, 0, 0, 0.5);

$color-light-green              :#0AB51F;
$color-light-orange             :#F29200;
$color-light-red                : #FF000E;
$color-light-blue               : rgba(0, 62, 255, 0.1);

$black                          : $color-black;
$white                          : $color-white;

$hr-border-color                : $color-lightgrey2 !default;
$hr-border-width                : 2px !default;

$breadcrumb-active-color        : $color-brand !default;


// TYPO
$font-family-base               : "MetaWeb Normal", sans-serif;
$font-family-base-bold          : "MetaWeb Bold", sans-serif;
$font-family-Cond               : "MetaWeb CondNormal", sans-serif;
$font-family-CondOTBold         : "MetaWeb CondXBold", sans-serif;

$font-size-base                 : 1.125rem !default; // = 18px
$font-size-base-small           : 14/16*1rem;        // = 14px
$font-size-subline              : 12/16*1rem;        // = 12px

$line-height-base-small         : 24/16*1rem;        // = 24px;


$font-size-h1                   : 70/16*1rem;  // = 70px;
$font-size-h2                   : 36/16*1rem;  // = 36px;
$font-size-h3                   : 24/16*1rem;  // = 24px;
$font-size-h4                   : 18/16*1rem;  // = 18px;
$font-size-h5                   : 18/16*1rem;  // = 18px;

$line-height-h1                 : 76/16*1rem;  // = 76px;
$line-height-h2                 : 42/16*1rem;  // = 42px;
$line-height-h3                 : 30/16*1rem;  // = 38px;
$line-height-h4                 : 24/16*1rem;  // = 24px;
$line-height-h5                 : 24/16*1rem;  // = 24px;


$headline-margin                : 16/16*0.5rem; // = 8px
$font-size-base--margin         : 16/16*1rem;  // = 16px;


$link-color                     : $color-brand !default;
$link-decoration                : none !default;
$link-hover-decoration          : underline !default;


// TOPNAV
$topnav-height                  : 32px;
$topnav-bg                      : #F3F3F5;

$navbar-padding-x               : 0;
$navbar-padding-y               : 0;
$navbar-link-gap                : 30px;



// HEADER
$header-height                  : 95px;
$header-border-width            : 2px;
$mainnav-level0-font-size       : 16px;
$mainnav-level0-fline-height    : 19px;




// FOOTER
$footer-bg                      : $topnav-bg;
$footer-border-width            : 2px;
$footer-border-color            : $color-grey3;



// FORMS
$input-border-focus             : #cccccc !default;
$input-color-focus              : #333333 !default;


$input-border-disabled          : #eeeeee !default;
$input-color-disabled           : #333333 !default;
$input-bg-disabled              : $color-white;

$input-border-color             : #cccccc !default;
$input-color                    : #333333 !default;


// BUTTONS
$btn-primary-color              : $color-white;
$btn-primary-bg                 : $color-brand;
$btn-primary-border             : $color-brand;

$btn-primary-hover-bg           : #FF000E;

$btn-padding-y                  : 10px;
$btn-padding-x                  : 20px;
$font-size-base                 : 1rem;
$btn-border-radius              : 0;


// MENU
// ==================================================
$menu-distance-y                : 10px;
$menu-height-extra              : 2px;
$menu-height-extra2             : 1rem;
$menu-headline-padding          : 5px;
$menu-dropdown-toggle-arrow     : 20px;
$menu-width-hr                  : 90%;







// HAMBURGER MENU
// Settings
// ==================================================
$hamburger-padding-x                        : 15px !default;
$hamburger-padding-y                        : 15px !default;
$hamburger-layer-width                      : 29px !default;
$hamburger-layer-height                     : 3px !default;
$hamburger-layer-spacing                    : 6px !default;
$hamburger-layer-color                      : #000 !default;
$hamburger-layer-border-radius              : 4px !default;
$hamburger-hover-opacity                    : 0.7 !default;
$hamburger-hover-transition-duration        : 0.15s !default;
$hamburger-hover-transition-timing-function : linear !default;

$hamburger-hover-use-filter                 : false !default;
$hamburger-hover-filter                     : opacity(50%) !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
    3dx
) !default;


// BOX-SHADOWS

$box-shadow1                     : inset 0 1px 1px 0 $color-lightgrey;
$box-shadow2                     : inset 0 0 0 3px $white;


// BORDER

$border1                         : 1px solid $color-grey2;
$border2                         : 2px solid transparent;
$border3                         : 2px solid $color-brand;
/*
$border4                         : 1px solid $color-grey3;
*/
$border4                         : 1px solid $black;
$border5                         : 1px solid $color-lightgrey2;






// CHECKBOX

$checkbox                        : 17px;

// MARGIN
$margin-center                   : 0 auto;


// Z-INDEXES
$zindex-dropdown                  : 10;



// SEARCH FORM
$search-desktop-padding-x         : 65px;
$search-desktop-padding-y         : 22px;
$search-desktop-color             : #333333;
$search-desktop-font-size         : 15px;
$search-desktop-line-height       : 22px;
$search-desktop-border-top        : 1px;
$search-desktop-border-bottom     : 1px;


$search-mobile-padding-x            : 65px;
$search-mobile-padding-y            : 20px;
$search-mobile-color                : #333333;
$search-mobile-font-size            : 16px;
$search-mobile-line-height          : 23px;
$search-mobile-border-top           : 1px;
$search-mobile-border-bottom        : 1px;




// Slider
$slider__radios-width               : 10px;
$carousel-control-next-width        : 44px;
$slider__caption-height             : 280px;
$slider__caption-width-md           : 640px;
$slider__caption-width-sm           : 340px;
$slider__caption-top                : 60px;



// Intro
$pageIntro__img-height               : 460px;
$pageIntro__headline--paddingBottom  : calc(40px - #{$font-size-base--margin});;
$pageIntro__headline--padding        : 40px 0 $pageIntro__headline--paddingBottom;
$pageIntro__headline--paddingBottom2 : calc(60px - #{$font-size-base--margin});;
$pageIntro__headline--padding2       : 80px 0 $pageIntro__headline--paddingBottom2;



// Login
$dialogStage__pageLogin-height      : 44px;


// ContentBlock
$contentBlock__table--padding       : 1rem 1.25rem;
$contentBlock__douglasAdams         : 42px;


$link-hover-color                   : $btn-primary-hover-bg !default;

// ctaTeaser

$ctaTeaser__button--marginOriginal  : 30/16*1rem;  // = 30px;
$ctaTeaser__button--margin          : calc(#{$ctaTeaser__button--marginOriginal} - #{$font-size-base--margin}) 0 0 0;


// SocialMediaLinks

$socialMedia__links--paddingBottom          : calc(40px - #{$headline-margin});
$socialMedia__links--headlinePadding        : 0 0 $socialMedia__links--paddingBottom;
$socialMedia__links--padding                : 0 0 60px 0;



// Modals

$modal-sm                                   : 480px;
$modal-md                                   : 920px;
$modal-lg                                   : 1400px;



$dropdown-link-bg                           : $color-white;
$dropdown-link-hover-bg                     : $color-lightgrey6;
$dropdown-link-active-bg                    : $color-lightgrey6;
$dropdown-link-color                        : $color-darkgrey;
$dropdown-link-active-color                 : $color-black;
$dropdown-link-hover-color                  : $color-black;
