.newsletterAnmeldung {
    background: $color-brand;

    &--inner {
        margin: 0 auto;
        padding: 30px 0;
        flex-wrap: wrap;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
    }

    .newsletterAnmeldung--abo,
    .newsletterAnmeldung--input {
        margin-bottom: 20px;
        margin-right: 0;

        @include for-tablet-up {
            margin-bottom: 0;
            margin-right: 10px;
        }
    }

    .newsletterAnmeldung--abo {
        p {
            color: $white;
            font-family: $font-family-CondOTBold;
            font-size: 24px;
            line-height: 28px;
            margin: 0;
        }
    }

    .newsletterAnmeldung--input {
        .form-group {
            margin: 0;
        }
        input {
            width: 250px;
        }
    }

    .newsletterAnmeldung--btn {
        button {
            border: 1px solid white !important;
        }
    }

    .form-control {
        border: 1px solid #ffffff;
    }

}

.page-section.newsletterAnmeldung {
    margin-top: 40px;
    margin-bottom: 0;

    @include for-tablet-up {
        margin-top: 80px;
        margin-bottom: 0;
    }
}
