.b-lazy {
    -webkit-transition: opacity 500ms ease-in-out;
    -moz-transition: opacity 500ms ease-in-out;
    -o-transition: opacity 500ms ease-in-out;
    transition: opacity 500ms ease-in-out;
    max-width: 100%;
    opacity: 0;

    &.b-loaded,
    &.b-error {
        opacity: 1;
    }
}

img.b-loaded {
    width: 100%;
    height: auto;
}
