.btn-primary {
    border-color: $color-brand;
    position: relative;
    padding-right: 30px !important;


    &:not(:disabled):focus, &:not(:disabled).focus,
    &:not(:disabled):hover, &:not(:disabled).hover {
        background-color: $btn-primary-hover-bg;
        border-color: $btn-primary-hover-bg;
        color: $color-white;
    }

    &:after {
        content: url('../svg/icons/link-arrow-white.svg');
        display: inline-block;
        font-size: 0.75em;
        width: 1em;
        height: auto;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
    }
}

.btn-secondary {
    background-color: $color-lightgrey6;
}

.btn-thirdly {
    border-color: $color-lightgrey2;
    background: $color-lightgrey2;

    &:not(:disabled):focus, &:not(:disabled).focus,
    &:not(:disabled):hover, &:not(:disabled).hover {
        background-color: $color-brand;
        border-color: $color-brand;
        color: $white;
    }
}

.btn {
    @include button-size(9px, 20px, 15px/16px*1rem, 0);
    font-weight: 200;
    height: calc(1.333em + 18px);
    transition: margin 0s ease;
    line-height: 18px;
    cursor: pointer;

    &,
    &:focus, &.focus,
    &:hover, &.hover,
    &:active, &.active {
        outline: none;
        border: none;
    }

    width: auto;
}

.btn-load {
    background-color: rgba(0, 0, 0, 0.06);
    padding: 13px 18px;
    height: auto;

    &:hover {
        background-color: $color-grey3;
    }

    &:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
}


.btn--switch,
.btn-switch,
.btn-button-radio {
    font-size: 14px;
    line-height: 18px;
    font-family: $font-family-base;
    font-weight: normal;
    padding: 9px 25px;
    background-color: $color-grey3;
    color: $black;

    &.active {
        color: $white;
        background-color: $color-brand;
    }

    &:focus,
    &:active,
    &.active {
        outline: none;
        box-shadow: none;
    }
}
