@font-face {
    font-family: "MetaWeb Normal";
    src: url("../fonts/3ba7755e-73d4-410d-9fa5-cc6acf4179ee.eot?#iefix&verion=1");
    src: url("../fonts/3ba7755e-73d4-410d-9fa5-cc6acf4179ee.eot?#iefix&verion=1") format("eot"),
    url("../fonts/afadf37a-d5b5-4152-837d-d72cf7210947.woff?version=1") format("woff");
}

@font-face {
    font-family: "MetaWeb Bold";
    src: url("../fonts/d622b21c-08d5-4be7-ba1c-63f6b03b27dc.eot?#iefix&verion=1");
    src: url("../fonts/d622b21c-08d5-4be7-ba1c-63f6b03b27dc.eot?#iefix&verion=1") format("eot"),
    url("../fonts/ddf9e5e1-6ea7-4b48-8343-bd8ccde5fa88.woff?version=1") format("woff");
}

@font-face {
    font-family: "MetaWeb CondXBold";
    src: url("../fonts/725d0446-9664-45ce-8848-06ed7c12330d.eot?#iefix&verion=1");
    src: url("../fonts/725d0446-9664-45ce-8848-06ed7c12330d.eot?#iefix&verion=1") format("eot"),
    url("../fonts/00795c69-065d-4bb9-9f5e-4f165333bb14.woff?version=1") format("woff");
}

body {
    font-size: $font-size-base;
    font-family: $font-family-base;
}

h1, .h1 {
    line-height: $line-height-h1;
    font-family: $font-family-CondOTBold;
    font-size: $font-size-h1;
}

h2, .h2 {
    line-height: $line-height-h2;
    font-family: $font-family-CondOTBold;
    font-size: $font-size-h2;
}

h3, .h3 {
    line-height: $line-height-h3;
    font-family: $font-family-CondOTBold;
    font-size: $font-size-h3;
}

h4, .h4 {
    line-height: $line-height-h4;
    font-family: $font-family-CondOTBold;
    font-size: $font-size-h4;
}

h5, .h5 {
    line-height: $line-height-h4;
    font-family: $font-family-CondOTBold;
    font-size: $font-size-h4;
}

h6, .h6 {
    line-height: $line-height-h4;
    font-family: $font-family-CondOTBold;
    font-size: $font-size-h4;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    display: block;
}

strong {
    font-family: $font-family-base-bold, sans-serif;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
    display: block;
}

.subheadline {
    font-size: 12px;
    font-family: $font-family-CondOTBold;
    line-height: 14px;
    letter-spacing: 2px;
    color: $color-black;
    text-transform: uppercase;
}

button, input, optgroup, select, textarea {
    font-family: $font-family-base;
}

p, .p {
    line-height: 28/16*1rem;;
    color: $color-black;
}

.base-small {
    color: $color-grey4;
    font-size: $font-size-base-small;
    line-height: $line-height-base-small;
}

.color-black-hover-black {
    color: $color-black;

    &:hover {
        color: $color-black;
    }
}

.color-black-hover-red {
    color: $color-black;

    &:hover {
        color: $color-brand;
    }
}

.text-decoration-none {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.kicker {
    font-family: $font-family-CondOTBold;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 14px;
    text-transform: uppercase;
    margin-bottom: 0;

    &:after {
        content: attr(data-date);
        display: inline-block;
    }

    h2 &, .h2 &,
    h3 &, .h3 & {
        color: #181618;
    }
}

.arrow-link i {
    font-size: 10px;
    padding-left: 5px;
}

.download-link:before {
    content: "\E00B";
    margin-right: 0;
    font-family: "igm-icons-font";
    transform: translateY(4px);
    display: inline-block;
}
