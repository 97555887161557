/* alert */

.alert {
    padding: 0.75rem 1.25rem 1.75rem;
    width: 35rem;
    margin: $margin-center;
}

.alert-danger {
    background-color: $color-error-box;
    border-color: $color-error;
    color: $color-error;

    .alert-danger-icon {
        background: $color-error-box url(../svg/icons/warning.svg) no-repeat right 0 center;
        width: 1.9rem;
        padding: 4rem 0 0;
        margin: $margin-center;
    }

    p {
        text-align: center;
    }

    .mb-0 {
        font-size: 0.8rem;
    }
}
