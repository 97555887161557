.modal {
    &.modal-custom-size-lg {
        .modal-dialog {
            max-width: $max-width;
        }
    }

    &.modal-custom-size-md {
        .modal-dialog {
            max-width: 1024px;
        }
    }

    &.modal-custom-size-sm {
        .modal-dialog {
            max-width: 768px;
        }
    }


    &.modal-custom-size-lg,
    &.modal-custom-size-md,
    &.modal-custom-size-sm {
        .modal-header {
            border-bottom: none;

            .close {
                width: 2.5rem;
                height: 2.5rem;
                border: 1px solid #ccc;
                display: inline-block;
                border-radius: 1.25rem;

                span {
                    font-size: 2.5rem;
                    line-height: 0.875rem;
                }
            }
        }
        .modal-body {
            > div:last-child > section,
            > section:last-child {
                padding-bottom: 20px;
                margin-bottom: 0;
            }
        }
        .modal-footer {
            border-top: none;
        }
    }
}
