.page-section {
    @include media-breakpoint-up(xs) {
        margin-bottom: 40px;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 80px;
    }

    @include media-breakpoint-down(xs) {
        overflow: hidden;
    }

    .component {
        padding: 0;
    }

    &[class*=' page-section--colored-'] {
        @include media-breakpoint-up(xs) {
            padding: 40px 0;
        }

        @include media-breakpoint-up(lg) {
            padding: 80px 0;
        }

        & + &[class*=' page-section--colored-'],
        & + .socialMedia {
            @include media-breakpoint-up(xs) {
                margin-top: -40px;
            }

            @include media-breakpoint-up(lg) {
                margin-top: -80px;
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &--colored {
        &-red {
            background-color: $color-brand;
            color: $color-white;
        }

        &-grey {
            background-color: $color-lightgrey;
        }

        &-black {
            background-color: $color-black;
            color: $color-white;
        }

        &-white {
            background-color: white;
        }
    }
}
