@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

@mixin img-plchld {
    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    }

    @include optional-at-root(':-moz-placeholder') {
        @content;
    }

    @include optional-at-root('::-moz-placeholder') {
        @content;
    }

    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    }
}

@mixin igm-placeholder {
    $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
    @each $placeholder in $placeholders {
        &:#{$placeholder}-placeholder {
            @content;
        }
    }
}

// checkboxen reset
@mixin igm-input-remove {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-radius: 0;
    font-size: 1em;
    width: 100%

}

@mixin img-input-ie8 {
    width: auto;
    float: left;
    margin-right: .75em;
    background: transparent;
    border: none;
}

@mixin hyphenate {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

@mixin picture {
    background-color: $color-brand;
    background-position: center;
    background-size: cover;
}

@mixin pictureGenerell {
    background-position: center;
    background-size: cover;
}

@mixin for-phone-up {
    @media (min-width: $mobile-size) {
        @content;
    }
}

@mixin for-tablet-up {
    @media (min-width: $tablet-size) {
        @content;
    }
}

@mixin for-tablet-landscape-up {
    @media (min-width: $tablet-landscape-size) {
        @content;
    }
}

@mixin for-desktop-up {
    @media (min-width: $desktop-size) {
        @content;
    }
}
