/* radio and checkboxen */

input {
    @include igm-input-remove;
}

input[type='checkbox'] {
    @include img-input-ie8;
    line-height: 0;
}

.form-check-input:checked::before,
.form-check-input:not(:checked)::before {
    display: inline-block;
    width: $checkbox;
    height: $checkbox;
    font-size: 11px;
    padding: 7px 1px;
}

.form-check-input:not(:checked)::before {
    color: white;
}

.custom-control-indicator,
.form-check-input:checked::before,
.form-check-input:not(:checked)::before {
    border: $border1;
    background-color: $white;
    box-shadow: $box-shadow1;
}

.form-check-input:not(:checked),
.form-check-input:checked {
    @extend .icon-igm-check;
}

.custom-radio .custom-control-input:checked ~ .custom-control-indicator,
.form-check-input:checked::before {
    box-shadow: $box-shadow2;
}

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
    background-image: none;
}

.custom-control-input:active ~ .custom-control-indicator {
    background-color: transparent;
}

.custom-control-input:checked ~ .custom-control-indicator {
    background-color: #000;
}

.custom-control-input {
    opacity: 0;
}

.form-check-label {
    display: flex;
    align-items: center;
    cursor: default;

    .custom-control-indicator {
        display: none;
    }
}

.custom-select {
    background: #FFFFFF url(../svg/icons/dropdown-arrow.svg) no-repeat right 0.75rem center;
    background-size: 14px 14px;
}

.thanks {
    width: 40rem;
    text-align: center;
    margin: 0 auto;
}
