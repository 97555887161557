.simple-search {

    .pageIntro__img {
        overflow: visible;
        background-color: $color-brand;
    }

    .seminar-search-wrapper {
        min-height: $pageIntro__img-height;
        display: flex;
        flex-direction: column;
        justify-content: center;

        text-align: center;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
            min-height: $pageIntro__img-height;
        }
    }

    h1, .h1,
    h2,
    p, .p {
        color: $color-white;
    }

    p, .p {
        font-family: $font-family-base-bold;
        display: block;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
    }


    .input-wrapper {
        position: relative;
        margin-top: 40px;

        padding: 10px 20px;
        background-color: $color-white;

        border: 1px solid $color-grey3;
        border-radius: 3px;
        box-sizing: border-box;

        display: flex;
        flex-direction: row;
        align-items: center;

        &.open {
            border-radius: 3px 3px 0 0;
            border-bottom: none;
        }

        i {
            margin-left: 20px;
            color: $color-black;
        }

        input {
            font-size: 16px;
            line-height: 28px;

            &:focus {
                border: none;
                outline: 0;
            }
        }

        .dropdown {
            display: none;
            position: absolute;
            top: 100%;
            left: -1px;
            width: calc(100% + 2px);
            z-index: 100;

            padding: 0;
            background-color: $color-white;
            border: 1px solid $color-grey3;
            border-radius: 0 0 3px 3px;
            box-shadow: inset 0 2px 0 0 $color-grey3, 0 6px 12px 0 rgba(0, 0, 0, 0.17);
            box-sizing: border-box;

            text-align: left;

            a {
                display: block;
                margin-top: 0;

                color: $color-black;

                &:hover {
                    color: $color-black;
                    text-decoration: none;
                }
            }

            .strong {
                display: inline;
                font-family: $font-family-base-bold;
            }

            ul {
                list-style: none;
                padding-left: 0;
                margin-bottom: 0;
            }

            li {
                border-top: solid 2px $color-grey3;
                padding: 8px 20px;

                &.highlight,
                &:hover {
                    background-color: $color-lightgrey2;
                }

                &:last-of-type {
                    border-radius: 0 0 3px 3px;
                }
            }

            &.open {
                display: block;
            }
        }
    }

    a {
        margin-top: 20px;
        margin-bottom: 20px;

        color: $color-white;

        @media all and(min-width: $tablet-size) {
            margin-bottom: 0;
        }

        i {
            font-size: 10px;
        }
    }

    .small {
        font-size: 0.8em;
    }

    &.color--black,
    &.color--red {
        color: white;
    }

    &.color--red {
        .pageIntro__img {
            background-color: $color-brand;
        }
    }

    &.color--black {
        .pageIntro__img {
            background-color: $color-black;
        }
    }

    &.color--grey {
        .pageIntro__img {
            background-color: $color-lightgrey;
        }

        a {
            color: $color-brand;
        }
    }

    &.text-color--white {
        color: $color-white;

        a {
            color: $color-white;
        }
    }

    &.text-color--black {
        color: $color-black;

        h1, .h1,
        h2,
        p, .p,
        a {
            color: $color-black;
        }
    }

    .pageIntro__img {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    &.search-teaser .seminar-search-wrapper,
    &.search-teaser .pageIntro__img {
        min-height: 0;
    }

    &.search-teaser .pageIntro__img {
        background-size: 100%;
        background-position: center;
    }

    .copyright {
        background-color: #EFEFEF;
        color: $color-black;
        margin: -20px 0 20px 0;
        padding: 8px 15px;
        font-size: 11px;
        font-weight: 500;
        line-height: 10px;
        text-align: left;
    }

    @media all and(min-width: $tablet-size) {
        &.search-teaser .copyright-wrapper {
            position: absolute;
            bottom: 25px;
            width: 100%;
        }

        &.search-teaser .copyright {
            color: inherit;
            background-color: transparent;
            padding: 0 50px;
            max-width: 1400px;
            width: 100%;
            margin: 0 auto;
            text-align: right;

            &.color-black {
                color: $color-black;
            }
        }
    }

    a {
        margin-top: 15px;
    }

    .input-wrapper {
        margin-top: 20px;
    }
}

