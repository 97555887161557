.glossary {
    &__container {
        margin-bottom: 40/16*1rem;
    }

    &__big-letter {
        font-family: $font-family-CondOTBold;
        font-size: 60/16*1rem;
        line-height: 42/16*1rem;
        color: $color-brand;
        padding-bottom: 30/16*1rem;
    }

    &__topic-list {
        &__wrapper {

            @include media-breakpoint-down(md) {
                padding-bottom: 40/16*1rem;
                margin-bottom: 40/16*1rem;
                border-bottom: 2px solid $color-lightgrey2;
            }

        }

        padding-left: 0;
        list-style: none;

        li {
            a {
                font-family: $font-family-CondOTBold;
                font-size: 18/16*1rem;
                line-height: 24/16*1rem;
                color: $color-black;

                &:hover,
                &.active {
                    color: $color-brand;
                }
            }
        }
    }

    &__text {
        font-size: 16/16*1rem;
        line-height: 28/16*1rem;

        .db {
            font-family: $font-family-CondOTBold;
            font-size: 12/16*1rem;
            line-height: 14/16*1rem;
            letter-spacing: 2/16*1rem;
            text-transform: uppercase;
            color: $color-lightgrey3;
        }

        h3, .h3 {
            margin-bottom: 30/16*1rem;
        }

        h4, .h4 {
            margin-bottom: 16/16*1rem;
        }

        ul {
            margin-top: 14/16*1rem;
            margin-bottom: 42/16*1rem;
        }
    }

    &__alphabet {
        display: block;
        width: 100%;
        font-family: $font-family-CondOTBold;
        font-size: 18/16*1rem;
        line-height: 24/16*1rem;
        padding-top: 25/16*1rem;
        padding-bottom: 25/16*1rem;
        margin-bottom: 40/16*1rem;
        border-top: 2px solid $color-lightgrey2;
        border-bottom: 2px solid $color-lightgrey2;

        @include media-breakpoint-up(lg) {
            margin-bottom: 80/16*1rem;
        }

        &__letters {
            width: 100%;
            //overflow: visible;

            @include media-breakpoint-up(lg) {
                display: table;
                table-layout: fixed;
                width: 100%;
            }

            &__wrapper {
                display: inline-block;
                width: calc(100% - 52 / 16 * 1rem);
                overflow: hidden;

                @include media-breakpoint-up(lg) {
                    display: table;
                    table-layout: fixed;
                    width: calc(100% + 3%);
                    margin: 0 -1.5%;
                }
            }
        }

        &__letter {
            display: table-cell;
            color: $color-black;
            padding: 0 16/16*1rem;

            @include media-breakpoint-up(lg) {
                text-align: center;
                padding: 0;
            }

            &.disabled {
                color: $color-grey3;
                cursor: default;

                &:hover {
                    text-decoration: none;
                }
            }

            &.active {
                color: $color-brand;
            }
        }
    }

    &__pagging {
        display: inline-block;
        width: 22/16*1rem;
        height: 24/16*1rem;
        background: $white url('../svg/icons/navi-arrow.svg') no-repeat right 0 center;
        cursor: pointer;

        &:hover {
            background: transparent url('../svg/icons/navi-arrow-hover.svg') no-repeat right 0 center;
        }

        &.disabled {
            background: transparent url('../svg/icons/navi-arrow-back.svg') no-repeat right 0 center;
            cursor: default;
        }

        @include media-breakpoint-up(lg) {
            display: none;
            visibility: hidden;
        }

        &[data-pagging="left"] {
            transform: rotate(180deg);
        }
    }
}

.pageIntro {
    + .glossary {
        @include media-breakpoint-up(xs) {
            margin-top: -40px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: -80px;
        }
    }
}
