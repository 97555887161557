.teaser-combination-e {

    .teaser {
        margin-bottom: 20px;
    }

    @include for-tablet-up {
        .teaser {
            margin-bottom: 0;
        }

        .teaser-large {
            margin-bottom: 40px;
        }
    }

    @include for-tablet-landscape-up {
        .teaser {
            margin-bottom: 20px;
        }

        .teaser-large {
            margin-bottom: 0;
        }
    }
}
