.dot {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;

    margin-right: 4px;

    &.green {
        background-color: $color-light-green;
    }

    &.orange {
        background-color: $color-light-orange;
    }

    &.red {
        background-color: $color-brand;
    }
}
