.component--newsticker {

    @mixin inner {
        content: '';
        width: 2px;
        height: 2rem;
        margin: 1rem auto;
        display: block;
        background-color: $color-brand;
    }

    @mixin redTimeLine($before: true, $after: true) {
        @if $before == true {
            &:before {
               @include inner;
            }
        }

        @if $after == true {
            &:after {
                @include inner;
            }
        }
    }

    .newsticker-block,
    .newsticker-entry {
        display: none;
    }

    &.js-initialized {
        .newsticker-block,
        .newsticker-entry {
            display: block;
        }
    }

    .text-block {
        font-size: 14px;
        line-height: 24px;
    }

    .newsticker-block {
        h2 {
            color: $color-brand;
        }
    }

    .newsticker-entry--link {
        display: inline-block;
    }

    .newsticker-entry__video,
    .newsticker-entry__video--large {
        figure .newsticker-entry--link {
            position: relative;
            color: $color-black;

            &,
            &:hover,
            &:focus {
                text-decoration: none;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.8);
            }

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 30%;
                height: 30%;
                background-image: url('../svg/icons/nt-icon-play-btn.svg');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                transform: translate(-50%, -50%);
            }
        }
    }

    .newsticker-entry--figure {
        position: relative;

        figcaption {
            position: absolute;
            right: 0;
            bottom: 0;
            font-size: 11px;
            line-height: 10px;
            padding: 3px;
            background-color: rgba(255, 255, 255, 0.8);
        }
    }

    .date {
        color: $color-brand;

        &:after {
            content: ' | ';
            color: $color-black;
        }
    }

    &.newsticker__list {

        .newsticker-entry {}

        .newsticker-block {
            @include redTimeLine(true, true);

            &:first-child {
                &:before {
                    content: none;
                    display: none;
                }
            }


            &:last-child {
                &:after {
                    content: none;
                    display: none;
                }
            }
        }

        .newsticker-entry__image,
        .newsticker-entry__image--large {}

        .newsticker-entry__video,
        .newsticker-entry__video--large,
        .newsticker-entry__gallery,
        .newsticker-entry__gallery--large {
            .kicker-inner {
                position: relative;
                padding-left: 22px;

                &:before {
                    position: absolute;
                    top: 2px;
                    left: 0;
                    width: 17px;
                    height: 12px;
                }
            }
        }

        .newsticker-entry__video,
        .newsticker-entry__video--large {
            .kicker-inner {
                &:before {
                    content: url('../svg/icons/nt-icon-video.svg');
                }
            }

            a:hover,
            a:focus {
                .kicker-inner {
                    &:before {
                        content: url('../svg/icons/nt-icon-video-red.svg');
                    }
                }
            }
        }

        .newsticker-entry__gallery,
        .newsticker-entry__gallery--large {
            .kicker-inner {
                &:before {
                    content: url('../svg/icons/nt-icon-gallery.svg');
                }
            }

            a:hover,
            a:focus {
                .kicker-inner {
                    &:before {
                        content: url('../svg/icons/nt-icon-gallery-red.svg');
                    }
                }
            }
        }

        .newsticker-block {
            h2 {
                margin: 1rem auto;
            }
        }

        .newsticker-entry--content-wrapper {
            border-top: 2px solid #DADADA;
            border-bottom: 2px solid #DADADA;
            padding: 15px 0;
            position: relative;
        }

        .newsticker-entry + .newsticker-entry .newsticker-entry--content-wrapper {
            border-top: none;
        }

        .text-block {
            display: none;
        }

        figure {
            display: none;

            //.newsticker-block {
            //    &:not(:first-child) {
            //        @include redTimeLine(false, true);
            //    }
            //}
        }

        header {
            h2.h3 {}

            a {
                color: $color-black;
                text-decoration: none;

                &:hover,
                &:focus {
                    &,
                    .kicker {
                        color: $color-brand;
                    }
                }
            }
        }

        @include media-breakpoint-up(md) {
            .date {
                position: absolute;
                left: 0;
                top: 50%;
                width: 20%;
                height: auto;
                transform: translate(0, -50%);
                color: $color-brand;
                font-size: 18px;
                line-height: 24px;

                &:after {
                    content: none;
                    display: none;
                }
            }

            header {
                padding-left: calc(20% + #{$grid-gutter-width-base});

                h2.h3 {
                    margin-bottom: 0;
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }

    }

    &.newsticker__detail {
        .newsticker-entry,
        .newsticker-block {
            @include redTimeLine(false, true);

            &:last-child {
                &:after {
                    display: none;
                    content: none;
                }
            }
        }

        .newsticker-entry {
            header {
                a {
                    color: $color-black;
                    text-decoration: none;

                    &:hover,
                    &:focus {
                        color: $color-brand;
                    }
                }
            }

            @include media-breakpoint-up(md) {
                header {
                    a {
                        &,
                        &:hover,
                        &:focus {
                            color: $color-black;
                            // text-decoration: none;
                        }
                    }
                }
            }
        }

        .newsticker-entry--inner {}

        .newsticker-entry__video,
        .newsticker-entry__image,
        .newsticker-entry__gallery {
            @include media-breakpoint-down(sm) {
                .figure-0 {
                    display: none;
                }

                .figure-1 {
                    display: block;
                }
            }

            @include media-breakpoint-up(md) {

                .newsticker-entry--content-wrapper {
                    display: flex;
                    flex-direction: row;
                }

                .newsticker-entry--inner-wrapper {
                    flex-grow: 3;
                    flex-basis: 75%;
                    display: flex;
                    flex-direction: column;
                    padding-left: $grid-gutter-width-base;
                }

                .newsticker-entry--figure {
                    flex-grow: 1;
                    flex-basis: 25%;
                    // display: inline-block;
                    margin: 0;
                    height: 56.25%;
                }

                .figure-0 {
                    display: block;
                }

                .figure-1 {
                    display: none
                }

                .text-block {}
            }
        }

        .newsticker-entry__image--large,
        .newsticker-entry__video--large,
        .newsticker-entry__gallery--large {
            .figure-0 {
                display: none;
            }

            .figure-1 {
                display: block;
            }
        }

        .newsticker-entry__video {}

        .newsticker-entry__video--large {}

        .newsticker-entry__image--large.newsticker-entry__text-image-image {

            .newsticker-entry--inner-wrapper {
                position: relative;
            }

            header {

                @include media-breakpoint-up(md) {
                    background-color: $color-white;
                    position: absolute;
                    top: 1.25rem;
                    left: 1.25rem;
                    width: 45%;
                    height: auto;
                    padding: 1.25rem;
                    z-index: 10;

                    .kicker {
                        margin-bottom: 8px;
                    }
                }

                @include media-breakpoint-up(lg) {
                    h2 {
                        font-size: 36px;
                        line-height: 42px;
                    }
                }
            }
        }

    }

    .newsticker--text-toggle {
        font-size: 14px;
        line-height: 24px;
        text-decoration: none;

        .icon-igm-dropdown-arrow {
            font-size: 8px;
            display: inline-block;

            &:before {
                transform-origin: 15px 0;
                transition: all 1ms linear;
                transform: rotate(0deg) translate(0, 0);
            }
        }

        &:before {
            content: attr(data-label-collapsed);
            margin-right: 5px;
            color: $color-brand;
        }

        &[aria-expanded="true"] {
            & /*&:before*/ {
                content: attr(data-label-open);
            }

            .icon-igm-dropdown-arrow {
                & /*&:before*/ {
                    transform: rotate(-180deg) translate(0, 1px);
                }
            }
        }
    }

    .newsticker--button-container {
        text-align: center;

        .newsticker--button {
            display: inline-block;
            margin: 1.5rem auto;
            -webkit-appearance: none;
            background-color: #EBEBEF;
            padding: 13px 15px;
            font-size: 15px;
            line-height: 18px;
            border: 0 none;
            cursor: pointer;

            &:focus,
            &:active {
                outline: 0 none;
            }
        }
    }

}


.form--newsticker {

    padding: 20px 0 20px 0;

    @include media-breakpoint-up(md) {
        padding: 40px 0;
    }

    input[type="text"] {
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: 12px 9px;
        padding-left: 40px;
        background-image: url('../svg/icons/nt-icon-search.svg');

        &.datepicker {
            background-image: url('../svg/icons/nt-icon-date.svg');
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 1rem;
        }
    }

    a.btn[data-toggle="collapse"] {
        display: flex;
        position: relative;
        text-align: center;
        justify-content: space-between;

        .icon-igm-dropdown-arrow {
            display: inline-block;
            text-align: right;
            transition: all 1ms linear;
            transform: rotate(0deg) translate(0,0);
            transform-origin: 8px 9px;
            font-size: 8px;
        }

        &[aria-expanded="true"] {
            .icon-igm-dropdown-arrow {
                transform: rotate(-180deg) translate(0,0);
            }
        }
    }
}
