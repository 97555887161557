.teaserCombinationB-above {
    margin-bottom: 40px;

    .teaserCombinationB-above__item {
        margin-bottom: 0px;
    }

    p {
        margin-bottom: 0px;
    }
}

.teaserCombinationB-above,
.teaserCombinationB-below {
    .teaserGrid-list__item {
        .cat {
            display: none;
        }
    }
}

.MZ__teaserCombination {
    &.teaserCombinationB-above,
    &.teaserCombinationB-below {
        .teaserGrid-list__item {
            .cat {
                position: absolute;
                top: 0;
                left: 15px;
                z-index: 3;
                background: $color-brand;
                color: $white;
                padding: 5px 20px;
                text-transform: uppercase;
                display: block;
                max-width: 413px;
            }
        }

    }

    .teaserCombinationB__item {
        &.teaserCombinationB__item--above {
            .teaserCombinationB__item {
                .cat {
                    height: 140px;
                    width: 135px;
                    font-size: 18px;
                    text-align: center;
                    display: table;
                }
            }
        }
    }

    .teaserCombinationB__item {
        &.teaserCombinationB__item--above {
            .teaserCombinationB__item {
                .cat strong {
                    color: $color-brand;
                    display: table-cell;
                    vertical-align: bottom;
                }
            }
        }
    }

    .teaserGrid-list {
        strong {
            color: $color-brand;
        }
    }

}
