.sliderElement {

    .header {
        margin-bottom: 40px;
        border-bottom: solid 2px $color-grey3;

        .headline {
            display: inline-block;
            padding: 0 0 20px;
            border-bottom: solid 2px $color-brand;
            margin-bottom: -2px;

            color: $color-black;
            font-family: $font-family-CondOTBold;
            font-size: 12px;
            letter-spacing: 1px;
            line-height: 14px;
            text-transform: uppercase;

            img {
                margin-right: 2px;
            }
        }
    }

    .contact {
        margin-bottom: 0;
    }

    .footer {
        margin-top: 40px;
        border-top: 2px solid $color-lightgrey2;
        text-align: center;
        padding: 20px 0 0;
    }

    &.page-section--colored {
        &-red,
        &-black {
            color: $color-white;

            .header {
                border-bottom: 2px solid rgba(255, 255, 255, 0.25);

                .headline {
                    border-bottom: 2px solid $color-white;
                    color: $color-white;
                }
            }

            .owl-dots {
                .owl-dot {
                    span {
                        background: transparent;
                        border: solid 1px $color-white;
                    }

                    &.active,
                    &:hover {
                        span {
                            background: $color-white;
                        }
                    }
                }
            }

            .owl-nav {
                button.owl-prev,
                button.owl-next {
                    color: $color-white;

                }
            }

            .footer {
                border-top: 2px solid rgba(255, 255, 255, 0.25);
            }
        }
    }
}
