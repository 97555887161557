.teaser-combination-g {
    &.page-section--colored-black {
        .teaserCombinationG-news-teaser__number {
            color: white;
        }

        .teaserCombinationG__tab-nav {
            border-color: rgba(255,255,255,.5);
        }


        .teaserCombinationG__tab {

            &--active, &:hover, &:focus {
                border-color: white;
                color: white;
            }
        }

        .footer {
            border-color: rgba(255,255,255,.5);
        }

        .teaserCombinationG-news-teaser:hover .teaserCombinationG-news-teaser__text {
            color: $color-light-orange;
        }
    }
}
