.teaser-combination {
    .header {
        margin-bottom: 30px;
        border-bottom: solid 2px $color-grey3;

        .headline {
            display: inline-block;
            padding: 0 0 18px;
            border-bottom: solid 2px $color-brand;
            margin-bottom: -2px;

            color: $color-black;
            font-family: $font-family-CondOTBold;
            font-size: 12px;
            letter-spacing: 1px;
            line-height: 16px;
            text-transform: uppercase;

            img {
                margin-right: 2px;
            }
        }
    }

    .teaser-large {
        .teaser .category {
            height: 100px;
            min-width: 100px;
            padding-top: 78px;
        }

        .headline {
            font-size: 24px;
            line-height: 30px;
        }
    }

    .image {
        max-width: 100%;
    }

    @include for-tablet-up {
        .teaser-large {
            .teaser .category {
                height: 140px;
                min-width: 140px;
                padding-top: 105px;
                font-size: 16px;
                line-height: 14px;
            }

            .headline {
                font-size: 36px;
                line-height: 42px;
            }
        }

    }

    .footer {
        text-align: center;
        margin-top: 30px;
        border-top: solid 2px $color-grey3;
        padding: 20px 0;
    }

    .teaser-column {
        .teaser {
            display: flex;
            margin-bottom: 20px;

            .headline {
                margin-bottom: 0;
                font-size: 18px;
                line-height: 24px;
            }

            .image {
                margin-bottom: 0;
            }

            &:not(.no-image) {
                .image-wrapper {
                    flex: 0 0 25%;
                }

                .text-wrapper {
                    flex: 0 0 75%;
                    padding: 0 0 0 20px;
                }
            }

            .category,
            .text {
                display: none;
            }
        }
    }

    @include for-tablet-up {
        .teaser-column {
            .teaser {
                .category,
                .text {
                    display: block;
                }
            }
        }

    }

    &.page-section--colored-black {

        .headline,
        .kicker {
            color: $color-light-orange;
        }

        .headline {
            border-color: $color-light-orange;
        }

        .event .headline {
            color: $color-white;
        }

        .teaser {
            &:hover {
                .headline {
                    color: $color-light-orange;
                }
            }
        }

        .headline,
        .text {
            color: $color-white;
        }

        .number,
        .contact .mail a {
            color: $color-white;
        }

        .arrow-link,
        .footer .arrow-link {
            color: $color-white;
            &:hover {
                color: $color-light-orange;
            }
        }
    }
}
