.teaser-combination-f {

    .teaser {
        margin-bottom: 30px;
    }

    .footer {
        margin-top: 0;
    }

    @include for-tablet-up {
        .teaser {
            margin-bottom: 40px;
        }
    }
}
