
// Teaser Combination - List Image Text

.teaserCombinationG {
    width: 100% !important; // IE11 BUG
}

// News list (left column)

.teaserCombinationG__news-container {
    padding-right: 30px;
}

.teaserCombinationG__tab-nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 2px solid  #EBEBEF;
}

.teaserCombinationG__tab {
    opacity: .5;
    padding: 10px 0;
    margin-right: 20px;
    text-transform: uppercase;
    color: inherit;
    font-size: .8rem;
    font-family: $font-family-CondOTBold;
    letter-spacing: 1px;
    margin-bottom: -2px;
    border-bottom: 2px solid transparent;

    &--active, &:hover, &:focus {
        opacity: 1;
        border-bottom: 2px solid $color-error;
        text-decoration: none;
    }
}

.teaserCombinationG__tab-content {
    display: none;

    &--active {
        display: block;
    }

    .footer {
        margin-top: 15px !important;
    }
}

.teaserCombinationG-news-teaser {
    display: flex;
    color: inherit;
    margin-bottom: 15px;

    &:hover, &:focus {
        text-decoration: none;
        outline: none;
    }

    &:first-child .teaserCombinationG-news-teaser__content {
        border-top: none;
    }
}

.teaserCombinationG-news-teaser__number {
    min-width: 40px;
    width: 40px;
    padding-top: 15px - 5px;
    flex-shrink: 1;
    flex-grow: 0;
    color: $black;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1;
    font-family: $font-family-CondOTBold;
    text-decoration: none !important;
}

.teaserCombinationG-news-teaser__content {
    flex-grow: 1;
    border-top: 1px solid rgba(0,0,0,.1);
    padding-top: 15px;
}

.teaserCombinationG-news-teaser__headline {
    font-size: 12px;
}

.teaserCombinationG-news-teaser__text {
    margin: 0;
    font-size: 18px;
    line-height: 24px;

}


.teaserCombinationG__tab-content {
    counter-reset: news-teaser-counter;

    .teaserCombinationG-news-teaser {
        counter-increment: news-teaser-counter;

        .teaserCombinationG-news-teaser__number:before {
            content: counter(news-teaser-counter);
        }
    }
}
