.footer {
    &--page {
        background-color: $footer-bg;

        @include media-breakpoint-up(xs) {
            padding: 40px 0;
        }

        @include media-breakpoint-up(lg) {
            padding: 80px 0;
        }
    }
}
