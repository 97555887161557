.teaser {
    display: block;

    &:hover {
        text-decoration: none;
    }

    .image-wrapper {
        position: relative;

        .category {
            position: absolute;
            top: 0;
            left: 0;
            text-transform: uppercase;
            text-align: center;
            padding: 12px 20px;

            background-color: $color-brand;
            color: $color-white;

            font-family: $font-family-CondOTBold;
            font-size: 12px;
            letter-spacing: 2px;
            line-height: 10px;
        }

        .image {
            margin-bottom: 15px;
        }
    }

    .kicker {
        text-transform: uppercase;
        font-family: $font-family-CondOTBold;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 14px;
        margin-bottom: 5px;

        .icon {
            display: inline-block;
            height: 10px;
        }

        &:after,
        .date {
            color: $color-grey1;
        }
    }

    .headline {
        line-height: 1.875rem;
        font-family: "MetaWeb CondXBold", sans-serif;
        font-size: 1.5rem;
        margin-bottom: .8rem;
        color: $color-black;

        &:hover {
            color: $color-brand;
        }
    }

    .text {
        color: $color-grey4;
        font-size: .875rem;
        line-height: 1.5rem;
    }
}

.metall-zeitung {
    .kicker {
        color: $color-brand;
    }
}
