.contact {
  text-align: center;
  margin-bottom: 50px;

  figure {
    margin: 0 auto 20px;
    width: 80%;
    max-width: 120px;
  }

  .image-wrapper {
    padding-top: 100%;
    max-width: 100%;
    position: relative;
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 20px;
    display: block;
  }


  h2, .h2,
  .number,
  .mail {
    margin-bottom: 0;
  }
    h2, .h2 {
    font-family: $font-family-CondOTBold;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
  }

  .subline {
    color: $color-grey1;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    text-align: center;
  }

  .mail a {
    color: $color-black;
  }
}
