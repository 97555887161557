.teaser-combination-a {

    .teaser-column {
        margin: 40px 0 0 -15px;
        width: calc(100% + 30px);

        .teaser {
            @extend .col-md-6, .col-lg-12;
            display: flex;
            margin-bottom: 20px;

            .headline {
                margin-bottom: 0;
                font-size: 18px;
                line-height: 24px;
            }

            .image-wrapper {
                flex: 0 0 25%;
            }

            .text-wrapper {
                flex: 0 0 75%;
                padding: 0 15px;
            }
        }
    }

    .teaser-grid .teaser {
        @extend .col-md-4;
        margin-top: 40px;
    }

    @include for-tablet-up {
        .teaser-column {
            display: flex;
            flex-wrap: wrap;
            margin: 40px -15px 0;

            .teaser {
                flex: 0 0 auto;
            }
        }
    }

    @include for-tablet-landscape-up {
        .teaser-column {
            display: block;
            width: auto;
            margin: 0 -15px;
        }
    }
}
