.socialMedia {
    @include media-breakpoint-up(xs) {
        margin-bottom: 40px;
        margin-top: -40px;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 80px;
        margin-top: -80px;
    }

    background-color: $color-lightgrey;
    //padding: 60px 0 0 0;

    @include media-breakpoint-up(xs) {
        padding: 40px 0;
    }

    @include media-breakpoint-up(lg) {
        padding: 80px 0;
    }

    &__headline {
        padding: $socialMedia__links--headlinePadding;
    }

    &__links {

        //padding: $socialMedia__links--padding;
        padding: 0;
        border-bottom: none;
        margin: 0;

        > li {
            padding: 0 0 0px;
            font-size: inherit;
            display: inline-block;

            > a {
                margin: 0 50px 0px;

                @include media-breakpoint-down(lg) {
                    margin: 0 20px 0px;
                }

                @include media-breakpoint-down(sm) {
                    margin: 0 10px 0px;
                }

            }
            p {
                display: none;

                @include media-breakpoint-up(xl) {
                    display: block;
                    margin: 0;
                }
            }
        }
    }
}


