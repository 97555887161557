.cke_editable,
.contentBlock {

    img {
        background-position: center;
        background-size: cover;
        max-width: 100%;
    }

    figure {
        margin: 0 0 0.5rem;
        text-align: initial;
        border: initial;
        border-radius: initial;
        background: initial;
        padding: initial;
        display: initial;
    }

    figure figcaption {
        color: $color-lightgrey3;
        font-size: $font-size-base/16*12;
        line-height: 16px;
        text-align: center;
        width: 100%;
        padding: 0;

        @media screen and(min-width: $tablet-size) {
            width: auto;
            padding: 0 30px;
        }
    }

    iframe {
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;
        margin: 0 0 1rem 0;
    }

    li {
        line-height: 28px;
        margin: 0 0 15px 0;
    }

    .image-captioned img {
        margin-bottom: 0.5rem;
    }

    .image-left,
    &__image--floatLeft {
        float: left;

        width: 100%;
        margin: 1rem 0;

        @media screen and(min-width: $tablet-size) {
            margin-right: 30px;
            margin-left: 0;
            width: auto;
        }
    }

    .image-right,
    &__image--floatRight {
        float: right;

        width: 100%;
        margin: 1rem 0;

        @media screen and(min-width: $tablet-size) {
            margin-right: 0;
            margin-left: 30px;
            width: auto;
        }
    }

    .image-center,
    .image-center .image-captioned img {
        margin: 1rem 0 0.5rem;
        display: block;
        width: 100%;
        text-align: center;

        @media screen and(min-width: $tablet-size) {
            margin-right: auto;
            margin-left: auto;
            width: auto;
        }
    }
}

.contentBlock__quote {
    font-family: $font-family-Cond;
    font-size: $font-size-base/18*36;
    line-height: $contentBlock__douglasAdams;
    text-align: center;
    padding: 20px 0 0 0;
    margin: 0 0 1rem;
}

.contentBlock__marginale {
    color: $color-lightgrey3;
    font-size: $font-size-base/16*12;
    line-height: 16px;
    text-align: center;
}

.contentBlock__notice {
    background-color: $color-lightgrey;
    padding: 20px;
    line-height: 24px;
    margin-bottom: 0;
    font-size: $font-size-base/16*14;
}

.contentBlock__center {
    text-align: center;
}

.contentBlock__linkList {
    display: block;
    line-height: 28px;
    padding-top: 2px;

    &:after {
        content: url('../../igm/svg/icons/link-arrow-red.svg');
        display: inline-block;
        font-size: 0.75em;
        width: 1em;
        height: auto;
        margin-left: 0.5em;
        vertical-align: middle;
    }
}

.contentBlock__downloadList {
    a {
        span {
            color: $color-lightgrey3;
        }

        &:before {
            content: url('../../igm/svg/icons/downloadlink-red.svg');
            display: inline-block;
            font-size: 0.75em;
            width: 1em;
            height: auto;
            margin-right: 0.5em;
            vertical-align: middle;
            top: 2px;
        }
    }

    p {
        margin-bottom: 0;
    }
}

a.contentBlock__downloadList {
    display: inline;

    &:before {
        content: url('../../igm/svg/icons/downloadlink-red.svg');
        display: inline-block;
        font-size: 0.75em;
        width: 1em;
        height: auto;
        margin-right: 0.5em;
        vertical-align: middle;
        top: 2px;
    }
}

.contentBlock__video {
    iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.contentBlock__headline--center {
    text-align: center;
}

/*
---- table -----
*/

.contentBlock__table--wrapper {

    margin: 1rem 0 0;
    width: 100%;

    td {
        border-bottom: $border5;
        font-size: $font-size-base/16*14;
    }

    table, th, td {
        padding: $contentBlock__table--padding;
    }

    th {
        background-color: $color-lightgrey2;
        font-family: $font-family-base-bold;
        font-size: $font-size-base/16*14;
    }

    .contentBlock__table--wrapper table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 1rem;
    }

    table thead th {
        vertical-align: bottom;
        border-bottom: 2px solid #eceeef;
    }

    .contentBlock__table--wrapper table th,
    .contentBlock__table--wrapper table td {
        border-top: 1px solid #eceeef;
        vertical-align: top;
        border-top: 1px solid #eceeef;
    }
}

.contentBlock__table--inner {
    overflow: auto;
}

.contentBlock__copy--center {
    text-align: center;
    clear: both;
}

.contentBlock__video {
    margin: 0 0 1rem 0;
}

.contentBlock__iframe {
    margin: 0 0 1rem 0;
}

.contentBlock__button {
    margin: 1rem 0;
    padding: 10px 20px;
    font-size: 0.9375rem;
    border-radius: 0;
    font-weight: 200;
    width: auto;
    color: #FFFFFF;
    background-color: #E3020E;
    display: inline-block;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    transition: all 0.2s ease-in-out;
    font-family: "MetaWeb Normal", sans-serif;
    touch-action: manipulation;
    overflow: visible;
    text-transform: none;

    &,
    &:focus,
    &.focus,
    &:hover,
    &.hover,
    &:active,
    &.active {
        outline: none !important;
        border: none !important;
        cursor: pointer;
    }

    &:after {
        content: url('../../igm/svg/icons/link-arrow-white.svg');
        display: inline-block;
        font-size: 0.75em;
        width: 1em;
        height: auto;
        margin-left: 0.5em;
        vertical-align: middle;
    }

    &:focus,
    &.focus,
    &:hover,
    &.hover {
        background-color: #FF000E;
        color: #FFFFFF;
        text-decoration: none;
    }

}

.contentBlock__marginale--floatLeft {
    display: none;

    color: $color-lightgrey3;
    font-size: $font-size-base/16*12;
    line-height: 16px;
    text-align: center;
    width: 100%;
    padding: 0;
    float: left;

    @media screen and(min-width: $tablet-size) {
        padding: 0 30px 0 0;
        width: 306px;
    }
}

.contentBlock__marginale--floatRight {
    display: none;

    color: $color-lightgrey3;
    font-size: $font-size-base/16*12;
    line-height: 16px;
    text-align: center;
    float: right;
    width: 100%;
    padding: 0;

    @media screen and(min-width: $tablet-size) {
        padding: 0 30px 0 0;
        width: 306px;
    }
}

.contentBlock__downloadList--locked,
.contentBlock__downloadList--lockedMember,
.contentBlock__downloadList--lockedUser,
.contentBlock__downloadList--unlocked {
    display: flex;

    a {

        span {
            color: rgba(0, 0, 0, 0.5);
        }

        &:before {
            content: url('../../igm/svg/icons/downloadlink-red.svg');
            display: inline-block;
            font-size: 0.75em;
            width: 1em;
            height: auto;
            margin-right: 0.5em;
            vertical-align: middle;
            top: 3px;
            position: relative;
        }

    }

    &:after {
        display: inline-block;
        font-size: 0.75em;
        width: 1em;
        height: auto;
        margin-left: 0.5em;
        vertical-align: middle;
        top: 2px;
        position: relative;
    }
}

.contentBlock__downloadList--locked,
.contentBlock__downloadList--lockedMember,
.contentBlock__downloadList--lockedUser {

    &:after {
        content: url('../../igm/svg/icons/lock_locked.svg');
    }
}

.contentBlock__downloadList--unlocked {

    &:after {
        content: url('../../igm/svg/icons/lock_unlocked.svg');
    }
}

.contentBlock__wrapper {
    padding: 80px 0 50px 0;
}


/**
 * Inline text block
 */
.block.block--text.block--inline-text {
    margin: 40px 0;
    padding: 30px 40px;
    border: 1px solid $color-table-border;
    color: $color-grey4;
    background-color: $white;
    border-radius: 3px;

    p,
    span,
    ul,
    ol {
        font-size: 14px;
    }

    > *:first-child {
        margin-top: 0;
        padding-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}
