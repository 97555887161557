/* Cookie notice */
@keyframes slideUp {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}

/* Cookie notice */
@keyframes slideDown {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(100%);
    }
}

.cookie-wrapper {
    bottom: 0;
    border-top: 1px solid #888;
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 40;
    animation-duration: 0.8s;
    animation-name: slideUp;

    &.hide {
        animation-name: slideDown;
        animation-fill-mode: forwards;
    }
}

.cookie {
    background-color: #222222;
    background-color: rgba(34, 34, 34, 0.9);
    position: relative;
    padding: 10px 50px 10px 50px;

    .btn-wrapper {
        position: absolute;
        right: 50px;
        top: 50%;
        transform: translateY(-50%);
    }

    .btn {
        background: #c8c8c8;
        border: none;
        border-radius: 5px;
        color: #000;
        cursor: pointer;
        display: inline-block;
        max-width: 120px;
        margin: 0;
        padding: 8px 15px;
        text-align: center;
        transition: background 200ms ease-in-out, color 200ms ease-in-out, box-shadow 200ms ease-in-out;

        &:hover {
            background: #d3d3d3;
        }

        + .btn {
            margin-left: 25px;
        }
    }

    p {
        color: #888;
        padding-right: 350px;

        a {
            color: #c8c8c8;
            text-decoration: none;
            transition: 200ms color;
            background: none;

            &:hover {
                color: #888;
            }
        }
    }
}

@media (max-width:961px) {
    //.rwdActive body  .cookie {
    //    padding: 10px 25px;
    //}

    .cookie .btn-wrapper {
        bottom: 0;
        position: relative;
        margin-top: 15px;
        right: 0;
        top: 0;
        transform: translateY(0);
        width: 100%;
    }

    .cookie p {
        padding: 0 0 0 0;
    }

    .cookie .btn-wrapper:after {
        clear: both;
        content: '';
        display: block;
    }
}

@media (max-width: 380px) {
    .cookie {
        padding: 10px 25px;

        .btn-wrapper {
            position: relative;
            text-align: center;
            right: 0;
            width: 100%;
        }

        .btn {
            display: block;
            margin: 0 auto;
            width: 100%;

            + .btn {
                margin: 15px auto 0 auto;
            }
        }
    }
}

@media print {
    .cookie-wrapper {
        display: none;
    }
}
