.icon-input {
    .icon-wrapper {
        position: absolute;
        right: 1em;
        top: .45em;

        cursor: pointer;
        color: rgba(0, 0, 0, 0.5);
    }

    .clear-date {
        font-size: 12px;
        padding: 5px;

        &.hidden {
            display: none;
        }
    }
}
