.header {
    &--page {

        @include media-breakpoint-up(md) {
            border-top: $header-border-width solid $color-brand;
        }

        svg {
            width: 75px;
            height: 75px;
            position: relative;
            z-index: 2;

            @include media-breakpoint-down(md) {
                width: 65px;
                height: 65px;
            }
        }

        &__topnav {
            background-color: $topnav-bg;
        }

        &__main {
            .row:first-child {
                @include media-breakpoint-down(md) {
                    height: 65px;
                }
            }
        }

        .btn-primary {
            height: calc(1.333em + 18px);
            transition: margin 0s ease;
            line-height: 18px;

            @include media-breakpoint-up(md) {
                @include media-breakpoint-down(md) {
                    margin-left: auto;
                    margin-right: 90px;
                }
            }
        }
    }
}

.search--desktop__trigger {
    display: block;
    margin-right: 22px;

    font-size: 20px;
    color: $color-black;

    &:visited,
    &:focus,
    &:hover {
        color: $color-black;
        text-decoration: none;
    }
}

.header {
    &--page {

        svg {

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                display: none;
            }
        }

    }
}

.jugend {
    .header {
        &--page {

            svg {

                &:nth-child(2) {
                    position: absolute;
                    z-index: 100;
                    width: 118px;
                    height: 64px;
                    left: -12px;
                    top: 66px;
                    display: block;

                    @include media-breakpoint-down(md) {
                        width: 80px;
                        height: 44px;
                        left: 90px;
                        top: 15px;
                        position: absolute;
                    }
                }
            }

            ol {
                margin-left: 110px;
            }
        }
    }
}

.meinIGM {
    .header {
        &--page {

            svg {

                &:nth-child(3) {
                    position: absolute;
                    z-index: 1;
                    width: 149px;
                    height: 64px;
                    left: 15px;
                    top: 78px;
                    display: block;

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }

            ol {
                margin-left: 150px;
            }
        }
    }
}

.meinIGM {

    .meinIGMMobileLogo {
        display: none;

        @include media-breakpoint-down(md) {
            width: 233px;
            height: 72px;
            left: 84px;
            top: -7px;
            position: absolute;
            display: block;
            background: transparent url('../images/logo/migm-logo-s.png') no-repeat;
            background-size: 233px 72px;
        }
    }
}
