[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
    display: none !important;
}

.form-hint {
    font-size: 7pt;
    line-height: 9pt;
    margin: -5px auto 5px;
    color: #999;
}

.form-hint.error {
    color: #C00;
    font-family: $font-family-CondOTBold;
    font-size: 8pt;
}

.password-count {
    float: right;
    position: relative;
    bottom: 24px;
    right: 10px;
}

.strength-meter {
    position: relative;
    height: 3px;
    background: #DDD;
    margin: 10px auto 20px;
    border-radius: 3px;
}

.strength-meter:before,
.strength-meter:after {
    content: '';
    height: inherit;
    background: transparent;
    display: block;
    border-color: #FFF;
    border-style: solid;
    border-width: 0 5px 0 5px;
    position: absolute;
    width: 20%;
    z-index: 10;
}

.strength-meter:before {
    left: 20%;
}

.strength-meter:after {
    right: 20%;
}

.strength-meter-fill {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    border-radius: inherit;
    transition: width 0.5s ease-in-out, background-color 0.25s;
}

.strength-meter-fill[data-strength='0'] {
    background-color: $radicalred;
    width: 20%;
}

.strength-meter-fill[data-strength='1'] {
    background-color: $radicalred; // $orange;
    width: 40%;
}

.strength-meter-fill[data-strength='2'] {
    background-color: $radicalred; // $banana;
    width: 60%;
}

.strength-meter-fill[data-strength='3'] {
    background-color: $greenlizard;
    width: 80%;
}

.strength-meter-fill[data-strength='4'] {
    background-color: $junglegreen;
    width: 100%;
}

.password-count {
    &.label-success {
        color: $junglegreen;
    }
}
