.seminar {
    img {
        width: 100%;
        margin-bottom: 15px;

        @include for-tablet-up {
            margin-bottom: 0;
        }
    }
}
