.quiz {
    border: 1px solid #979797;
    border-radius: 3px;
}

.quiz__status {
    padding: 60px;
    text-align: center;
}

.quiz-animate-show-hide.ng-hide {
    opacity: 0;
}

.quiz-animate-show-hide.ng-hide-add,
.quiz-animate-show-hide.ng-hide-remove {
    transition: all linear 0.8s;
}

.quiz__item {

    & + & {
        border-top: 1px solid #979797;
    }
}

.quiz__item--end-result {
    background-color: rgba(60,60,60,0.05);

    .quiz__item-title-text {
        opacity: 0.5;
    }

    &.is-active {
        .quiz__item-title-text {
            opacity: 1;
        }
    }

    &.is-result-1 {
        .quiz__item-header,
        .quiz__item-content {
            background-color: rgba(66,156,55,0.05);
        }

        .quiz__item-content strong {
            color: #5B9945;
        }
    }

    &.is-result-2 {
        .quiz__item-header,
        .quiz__item-content {
            background-color: rgba(162,167,35,0.05);
        }

        .quiz__item-content strong {
            color: #A2A723;
        }
    }

    &.is-result-3 {
        .quiz__item-header,
        .quiz__item-content {
            background-color: rgba(234,181,0,0.05);
        }

        .quiz__item-content strong {
            color: #EAB500;
        }
    }

    &.is-result-4 {
        .quiz__item-header,
        .quiz__item-content {
            background-color: rgba(230,93,14,0.05);
        }

        .quiz__item-content strong {
            color: #E65D0E;
        }
    }
    &.is-result-5 {
        .quiz__item-header,
        .quiz__item-content {
            background-color: rgba(227,5,27,0.05);
        }

        .quiz__item-content strong {
            color: #E3051B;
        }
    }
}

.quiz__item-header {
    padding: 20px 16px;
    cursor: default;

    @include for-phone-up {
        padding: 25px 20px;
    }

    .quiz__item.is-active & + .quiz__item &,
    .is-result-1:not(.is-active) &,
    .is-result-2:not(.is-active) &,
    .is-result-3:not(.is-active) &,
    .is-result-4:not(.is-active) &,
    .is-result-5:not(.is-active) & {
        cursor: pointer;
    }

    .quiz__item--end-result & {
        cursor: default;
    }
}

.quiz__item-title {
    position: relative;
    color: #181618;
    font-size: 0;
    margin-bottom: 0;
    line-height: 1;

    // Result smiley
    &::before {
        // empty state
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 22px;
        height: 22px;
        border: 1px solid #dadada;
        border-radius: 100%;
        background-image: none;
        background-repeat: no-repeat;
        background-size: contain;

        .is-result-1 & {
            border: none;
            background-image: url('../svg/quiz/a.svg');
        }

        .is-result-2 & {
            border: none;
            background-image: url('../svg/quiz/b.svg');
        }

        .is-result-3 & {
            border: none;
            background-image: url('../svg/quiz/c.svg');
        }

        .is-result-4 & {
            border: none;
            background-image: url('../svg/quiz/d.svg');
        }

        .is-result-5 & {
            border: none;
            background-image: url('../svg/quiz/e.svg');
        }
    }

    // Open/Close state
    &::after {
        // closed state
        content: "\e010";
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        font-size: 0.5rem;
        line-height: 22px;
        height: 22px;
        font-family: "igm-icons-font";
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        text-decoration: none;
        text-transform: none;

        .is-active & {
            // open state
            content: "\e00f";
        }

        .quiz__item--end-result & {
            display: none;
        }
    }
}

.quiz__item-title-text {
    display: inline-block;
    vertical-align: middle;
    max-width: calc(100% - 64px);
    font-size: 18px;
    margin-left: 16px;

    @include for-phone-up {
        font-size: 22px;
    }
}

.quiz__item-content {
    padding: 5px 16px 20px 16px;

    @include for-phone-up {
        padding: 5px 20px 50px calc(20px + 16px + 22px);
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.quiz__question-count {
    color: #E3051B;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    display: block;
    margin-bottom: 16px;
}
