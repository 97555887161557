
.teaserGrid-list,
.teaser-list {

    &__header {
        h3, .h3 {
            font-size: 12px;
            font-family: $font-family-CondOTBold;
            line-height: 14px;
            letter-spacing: 2px;
            color: $color-black;
            text-transform: uppercase;
            margin-bottom: 30px;
            border-bottom: 2px solid $color-grey3;

            span {
                padding: 0 0 13px 0;
                display: inline-block;
                position: relative;
                letter-spacing: 1px;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    height: 2px;
                    width: 100%;
                    background-color: $color-brand;
                }

                svg {
                    position: relative;
                    top: 3px;
                }

                object {
                    position: relative;
                    top: 3px;
                }
            }
        }
    }

    h2, h3 {
        color: $black;
    }

    &__item {

        margin-bottom: 33px;

        &:hover {

            h2,
            h3,
            p,
            strong {
                color: $color-brand;
            }
        }
    }

    &--red {

        .teaserGrid-list__item,
        .teaser-list__item {

            &:hover {

                h2,
                h3,
                p,
                strong {
                    color: $black;
                }
            }
        }

    }
}

.pageIntro,
.teaserGrid-list,
.teaser-list {

    strong {
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $color-black;
        font-family: $font-family-CondOTBold;
        display: block;
        margin-bottom: 6px;
        font-family: $font-family-CondOTBold;
    }

    .teaser__img {
        background-position: center;
        background-size: cover;
        background-color: #F8F8F9;
    }
}






