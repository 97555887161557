.office-search {
    h2, .h2 {
        margin-bottom: 20px;
    }

    h5, .h5 {
        margin-bottom: 16px;
    }

    strong {
        margin-bottom: 8px;
    }

    input {
        margin-bottom: 30px;
    }

    a:hover {
        text-decoration: none;
    }

    hr {
        margin: 10px 0 20px;

        @include for-tablet-up {
            margin: 15px 0 30px;
        }
    }

    label {
        margin: 10px 0 0;

        color: $color-lightgrey3;
        font-size: 14px;
        line-height: 18px;

        &:first-child {
            margin-top: 0;
        }
    }

    table {
        width: 100%;
    }

    thead,
    .tr.th {
        background-color: $color-lightgrey;
        font-family: $font-family-CondOTBold;
        padding: 20px;
    }

    .tr {
        padding: 20px 0;
        border-bottom: solid 1px $color-lightgrey;
    }

    td:first-of-type {
        width: 200px;
    }

    .cell-border-bottom td {
        padding: 16px;
        border-bottom: solid 1px $color-lightgrey;
    }

    .container.grey {
        background-color: $color-lightgrey;
        padding: 40px;
        margin-bottom: 40px;
    }

    .container.bordered {
        padding-left: 0;
        padding-right: 0;
    }

    @include for-tablet-up {
        .container.bordered {
            border: solid 1px $color-table-border;
            border-radius: 3px;
            padding-top: 40px;
            padding-right: 40px;
            padding-bottom: 40px;
            padding-left: 40px;
        }
    }

    .invis {
        color: $color-white;
        margin-top: -1em;
    }

    .space-between {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .arrow {
        font-size: 8px;
        padding-left: 7px;
    }

    .opening-times {
        span {
            display: block;

            @include for-phone-up {
                display: inline-block;
                min-width: 95px;
            }
        }

        td {
            vertical-align: top;
        }
    }

    .btn-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .spacer {
            display: none;
            width: 100%;
        }
    }

    @include for-tablet-up {
        .btn-wrapper {
            justify-content: right;

            .spacer {
                display: block;
            }
        }
    }

    &.searchbox {
        text-align: center;

        @include for-tablet-up {
            text-align: left;
        }
    }

    &.city-overview {
        p,
        a {
            margin-bottom: 0;
        }

        .multi-column {
            column-count: 1;
        }

        @include for-tablet-up {
            .multi-column {
                column-count: 3;
            }
        }

        p {
            margin-top: -30px;
            margin-bottom: 30px;
        }

        .keep-together {
            margin-bottom: 0;
            margin-top: 0;
            min-width: 100%;

            &:first-child {
                margin-top: 0;
            }
        }

        .letter {
            margin-bottom: 30px;
        }
    }

    .keep-together {
        display: inline-table;
    }

}
