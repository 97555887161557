/**
Owl Slider Controls
 */

.slide {
    margin: 0;
    background: $color-lightgrey;

    @include media-breakpoint-down(md) {
        padding: 0 0 40px 0;
        margin: 0;
    }

}

.stage .owl-nav {
    position: relative;
    z-index: 10;
    top: calc(-560px / 2 + 25px/2);

    @include media-breakpoint-down(sm) {
        top: calc(-300px / 2 + 25px/2);
    }

    button.owl-next,
    button.owl-prev {
        color: white;
        background-color: black;
        padding: 10px!important;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

.stage .owl-dots {
    position: relative;
    z-index: 10;
    top: -35px;
    margin-top: 0;

    .owl-dot span {
        background: none;
        border: solid $color-base 1px;
    }
}

/**
images
 */

.slide-item--img {
    background-position: center;
    background-size: cover;
    height: 560px;
    background-color: #F8F8F9;

    @include media-breakpoint-down(sm) {
        height: 300px;
    }
}

/**
content Box
 */

.slider__caption {
    position: static;
    display: block;
    padding: 20px;

    background: white;
    color: black;
    text-align: left;

    span,
    h3,
    p {
        overflow-wrap: break-word;
        word-wrap: break-word;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
    }

    span {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-family: $font-family-CondOTBold;

        @include media-breakpoint-down(sm) {
            line-height: 14px;
        }
    }

    h3, .h3 {
        font-size: 70px;
        line-height: 76px;
        font-family: $font-family-CondOTBold;

        @include media-breakpoint-down(sm) {
            font-size: 36px;
            line-height: 42px;
        }
    }

    p {
        font-size: 18px;
        line-height: 24px;
        font-family: $font-family-CondOTBold;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        color: $color-brand;
    }
}

.slider__container--position {
    position: absolute;
    top: 50px;
    width: 100%;

    @include media-breakpoint-down(md) {
        position: relative;
        top: -60px;
        margin-bottom: -60px;
    }
}
