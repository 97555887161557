.commentsLoggedOut {
    overflow: hidden;

    .comments__header {
        background: $color-lightgrey2;
        height: 98px;
        align-items: center;

        h3, a {
            margin: 0;
        }
    }
}

.comments {
    &__wrapper {
        margin: 60px 0 0;

        .delete-link a {
            color: red;
            font-size: 12px;
            cursor: pointer;
        }
    }

    .teaserGrid-list__line {
        margin: 19px 0 19px 0;
    }

    &__textarea {
        margin: 60px 0 60px 0;

        label {
            font-size: 14/16*1rem;
            line-height: 18/16*1rem;

        }

        textarea {
            height: 180px;
        }

    }

    @include media-breakpoint-down(md) {
        .teaserGrid-list__item p {
            display: block;
        }
    }

}
