.navigation--footer--social {
    @extend %fullWidth;
    @extend %navigation;

    padding-bottom: 40px;
    margin: 0 $grid-gutter-width-base/2;

    > li {
        padding: 0 10px;
        margin: 0;
        font-size: 0;

        > a {
            display: inline-block;
            width: 40px;
            height: 40px;
            background-position: 50% 50%;
            background-size: 50%;
            background-repeat: no-repeat;
            border-radius: 50%;
            font-size: 1rem;

            &.icon--fb {
                background-image: url('../svg/sm/fb.svg');
                background-color: #3B5998;
                transition: all 0.3s ease;
                &:hover {
                    transform: scale(1.2);
                }
            }

            &.icon--twitter {
                background-image: url('../svg/sm/twitter.svg');
                background-color: #55ACEE;
                transition: all 0.3s ease;
                &:hover {
                    transform: scale(1.2);
                }
            }

            &.icon--instagram {
                background-image: url('../svg/sm/instagram.svg');
                background-color: #2E5E86;
                transition: all 0.3s ease;
                &:hover {
                    transform: scale(1.2);
                }
            }

            &.icon--google {
                background-image: url('../svg/sm/google.svg');
                background-color: #CC3732;
                transition: all 0.3s ease;
                &:hover {
                    transform: scale(1.2);
                }
            }

            &.icon--flickr {
                background-image: url('../svg/sm/flickr.svg');
                background-color: #0063DB;
                transition: all 0.3s ease;
                &:hover {
                    transform: scale(1.2);
                }
            }

            &.icon--yt {
                background-image: url('../svg/sm/yt.svg');
                background-color: #CD201F;
                transition: all 0.3s ease;
                &:hover {
                    transform: scale(1.2);
                }
            }

            &.icon--whatsapp {
                background-image: url('../svg/sm/whatsapp.svg');
                background-color: #25D366;
                transition: all 0.3s ease;
                &:hover {
                    transform: scale(1.2);
                }
            }

            &.icon--rss {
                background-image: url('../svg/sm/rssfeed.svg');
                background-color: #F26522;
                transition: all 0.3s ease;
                &:hover {
                    transform: scale(1.2);
                }
            }

        }
    }

    border-bottom: $footer-border-width solid $footer-border-color;
}

.navigation--footer--main {
    //  @extend %fullWidth;
    width: auto;
    @extend %navigation;
    margin: 11/16*1rem auto;
    padding-left: $grid-gutter-width-base/2;
    padding-right: $grid-gutter-width-base/2;

    > li {
        padding: 0 20px;
        float: left;

        > a {
            font-size: 14px/16px*1rem;
            line-height: 12/16*1rem;
            font-family: $font-family-CondOTBold;
            letter-spacing: 1px;
            color: $color-grey1;
            white-space: nowrap;

            &:hover {
                color: $color-brand;
            }
        }
    }
}

.navigation--footer--bottom {
    @extend %fullWidth;
    @extend %navigation;
    //margin: 0 0 36/16*1rem 0;
    margin: 0;
    padding-left: $grid-gutter-width-base/2;
    padding-right: $grid-gutter-width-base/2;

    > li {
        padding: 0 20px;

        > a {
            font-size: 11px/16px*1rem;
            line-height: 12/16*1rem;
            font-weight: 200;
            text-transform: uppercase;
            letter-spacing: 1px;
            white-space: nowrap;
            color: $color-grey1;

            &:hover {
                color: $color-brand;
            }
        }
    }
}
