.social-wall__container {
    margin: 0 auto;
    width: 100%;

    .loading {
        text-align: center;
    }

    .ui-loader {
        display: none;
    }
}

.streamfilter-desktop {
    display: block;

    ul li span {
        display: none;
    }
}

.streamfilter-mobile {
    display: none;

    ul li {
        i {
            display: none;
        }

        span {
            text-transform: capitalize;
        }
    }
}

.streamfilter {
    display: block;
    margin: 1rem auto 0 auto;

    ul {
        float: left;
        display: table;
        text-align: center;
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 60px;

        li {
            display: inline-block;
            font-style: italic;
            width: 40px;
            height: 40px;
            text-align: center;
            line-height: $input-height;
            margin: 0 3px 0 3px;
            color: $color-white;
            background-color: $color-grey2;
            border-radius: 30px;
            cursor: not-allowed;
            overflow: hidden;
            background-position: 50% 50%;
            background-size: 50%;
            background-repeat: no-repeat;
            vertical-align: middle;

            &[data-id="all"] {
                width: 65px;
            }

            &.active {
                font-style: normal;
                color: $color-white;
                //background-color: $color-error;
                cursor: pointer;
            }

            &.current {
                color: $color-white;
                background-color: $color-brand;
            }

            &[data-id="facebook"] {
                background-image: url('../svg/sm/fb.svg');
            }

            &[data-id="google-plus"] {
                background-image: url('../svg/sm/google.svg');
            }

            &[data-id="twitter"] {
                background-image: url('../svg/sm/twitter.svg');
            }

            &[data-id="instagram"] {
                background-image: url('../svg/sm/instagram.svg');
            }

            &[data-id="youtube"] {
                background-image: url('../svg/sm/yt.svg');
            }
        }
    }

    .paging {
        float: left;
        width: 15%;
        margin-bottom: 60px;

        .inactive {
            background-color: $color-grey2;
            cursor: not-allowed;
        }

        &:last-child {
            text-align: right;
        }

        li {
            display: inline-block;
            font-style: italic;
            width: 22px;
            height: 22px;
            text-align: center;
            margin: 0 3px 5px 3px;
            color: $color-white;
            background-color: #ff9900;
            cursor: pointer;
        }

        &[data-id="prev"] .paging-left {
            display: inline-block;
            font-style: italic;
            width: 22px;
            height: 22px;
            text-align: center;
            margin: 0 3px 5px 3px;
            cursor: pointer;
            background-color: $color-grey2;
            mask-image: url('../svg/icons/navi-arrow.svg');
            mask-repeat: no-repeat;
            transform: rotate(180deg);
        }

        &[data-id="next"] .paging-right {
            display: inline-block;
            font-style: italic;
            width: 22px;
            height: 22px;
            text-align: center;
            margin: 0 3px 5px 3px;
            cursor: pointer;
            background-color: $color-grey2;
            mask-image: url('../svg/icons/navi-arrow.svg');
            mask-repeat: no-repeat;
        }
    }
}

.streamcontainer {
    position: relative;
    float: left;
    width: 100%;
    //height: 600px;
    margin: 0;
    padding: 0;
    /*overflow-x: auto;*/
    overflow: hidden;
}

.stream {
    width: 100%;
    height: 98%;
    overflow: hidden;

    &:after {
        content: '';
        display: block;
        clear: both;
    }

    .stream-item {
        float: left;
        font-size: 12px;
        // width: 290px;
        width: calc(25% - 20px);
        max-width: 100%;
        margin: 5px;
        line-height: 1.35em;
        background-color: $color-white;
        box-shadow: 0 1px 5px 0 $color-lightgrey5, 0 2px 2px 0 $color-lightgrey6;
        cursor: default;

        &[data-link] {
            cursor: pointer;
        }

        a {
            color: $color-brand;
            text-decoration: none;
        }

        &[data-link] a {
            cursor: pointer;
        }

        img {
            // max-width: 100%;
            width: 100%;
            height: auto;
        }

        .title {
            font-family: $font-family-CondOTBold;
            font-size: 16px;
            line-height: 18px;
            padding-top: 15px;
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 0;
        }

        .text,
        .image {
            padding-left: 15px;
            padding-right: 15px;
        }

        &[data-link] {
            .title,
            .text,
            .image {
                cursor: pointer;
            }
        }

        .image {
            max-height: 420px;
            padding: 0;
            overflow: hidden;
            text-align: center;
        }

        .text {
            max-height: 205px;
            overflow: hidden;
            padding: 0;
            margin: 15px;

            b {
                color: #1d1d1d;
            }

            p {
                margin-top: 0;
            }
        }

        div {
            padding-bottom: 15px;
        }

        .headerbar {
            display: block;
            color: #a2a2a2;
        }

        .headerbar {
            padding: 15px 15px 0 15px;
            text-transform: capitalize;
        }

        &[data-link] .headerbar {
            cursor: pointer;
        }

        .footerbar {
            display: flex;
            justify-content: space-between;
            float: left;
            width: 100%;
            padding: 9px;
            color: #a2a2a2;
            border-top: 2px solid $color-lightgrey2;

            div {
                display: inline-block;
                padding-right: 15px;
                padding-bottom: 0;
            }

            .author {
                width: 100%;
                color: $color-brand;
            }

            .left {
                text-align: left;
                color: $color-lightgrey3;
                padding-top: 3px;
            }

            .right {
                text-align: right;

                div {
                    padding-right: 10px;
                }

                padding-right: 0;

                .social-icon {
                    display: inline-block;
                    font-size: 22px;
                    text-align: center;
                    cursor: pointer;
                    color: $color-grey2;
                }
            }

            div:last-child {
                padding-right: 0;
            }

            a {
                color: #ff9900;
            }
        }
    }

    .btn-load {
        margin-bottom: 5rem;
    }
}

.ui-page {
    min-height: inherit !important;
    outline: 0 !important;

    &:focus {
        min-height: inherit !important;
        outline: 0 !important;
    }
}

.streamloader {
    transition: all 1s ease-in-out;
    width: 1px;
    min-width: 100%;
    *width: 100%;
}

@media only screen and (max-width: 768px) {
    .stream .stream-item {
        width: calc(50% - 10px);
    }
}

@media only screen and (max-width: 767px) {
    .streamfilter {
        ul {
            margin-bottom: 30px;
        }
    }
}
