.teaser-combination-c {
    overflow: hidden;

    overflow: hidden;

    .cta-teaser {
        height: 100%;

        .cta-teaser-inner {
            width: 318px;
        }
    }

    .block:not(:last-of-type) {
        margin-bottom: 40px;
    }

    .block .footer {
        margin-bottom: 0;
    }

    .form-control:not(:last-of-type) {
        margin-bottom: 10px;
    }

    @include for-tablet-up {
        .teaser-column {
            display: flex;
            flex-wrap: wrap;

            .teaser {
                flex: 0 0 50%;
            }
        }

        .block:not(:last-of-type) {
            margin-bottom: 80px;
        }

    }

    @include for-tablet-landscape-up {
        .teaser-column {
            display: block;

            .teaser {
                flex: 0 0 100%;
            }
        }
    }

    .cta-teaser {

        background-position: center;
        background-size: cover;

        .wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding: 60px 0;
        }

        .cta-teaser-inner {
            background-color: $color-white;
            padding: 40px 30px;
            max-width: calc(100% - 30px);
        }
    }
}
