.teaserCombinationC {
    .ctaTeaser__wrapper {
        min-height: 420px;
        margin: 0;
        display: flex;
    }

    .col-lg-4 {
        display: flex;
        flex-grow: 1;
        flex-direction: column;

        &:first-child {
            padding-bottom: 40px;
        }

        &:last-child {
            padding-bottom: 0px;
        }

        @include media-breakpoint-down(md) {
            &:nth-child(2) {
                padding-bottom: 40px;
            }
        }

        @include media-breakpoint-up(lg) {
            &:first-child {
                padding-bottom: 100px;
            }

            &:last-child {
                padding-bottom: 100px;
            }
        }
    }

    .teaserGrid-list__item{
        overflow: hidden;
    }

    @include media-breakpoint-up(lg) {

        .teaserCombinationC__line--wrapper {
            position: absolute;
            bottom: 34px;
            width: 100%;
            padding: 0 15px 0 0px;
        }

        .teaserCombinationC__line--wrapper2 {
            position: absolute;
            bottom: 0;
            left: 50%;
            padding-right: 10px;
            margin: 0 0 0 -25px;
        }
    }

}

.MZ__teaserCombination {
    .teaserGrid-list {
        .teaserCombinationA__item {
            strong {
                color: $color-brand;
            }
        }
    }
}
