.teaserGrid-list__Among--item{
    h3, .h3{
        font-size: 18px;
        line-height: 24px;
    }
}

/* -----------
    hover
------------ */

.teaserGrid-list__Among--item {
    .row {
        .teaserGrid-list__Among--content {
            .kicker {
                color: $black;

                &:after {
                    content: attr(data-date);
                    color: $color-grey1;

                    @include media-breakpoint-down(md) {
                        content: attr(data-date-short);
                    }
                }
            }
        }
    }

    &:hover {
        .row {
            .teaserGrid-list__Among--content {
                h2, .h2,
                h3, .h3,
                p,
                strong {
                    color: $black;

                    .kicker:after {
                        color: $color-grey1;
                    }
                }
            }
        }
    }

    &:hover {
        .row {
            a {
                &:hover {
                    h2, .h2,
                    h3, .h3,
                    p,
                    strong {
                        color: $color-brand;
                    }
                }
            }
        }
    }
}

.MZ__teaserKombinationen {
    .teaserGrid-list__Among--item {
        &:hover {
            .row {
                .teaserGrid-list__Among--content {
                    strong {
                        color: $color-brand;
                    }
                }
            }
        }
    }
}

.teaserGrid-list__Among {

    .teaserGrid-list__slide {

        .teaserGrid-list__item {
            &:hover {
                .teaserGrid-list__Among--content {
                    h4,
                    .h4 {
                        color: rgba(0, 0, 0, 0.5);
                    }
                }
            }
        }
    }
}

.teaserCombinationA {
    .teaserGrid-list__slide {
        .teaserGrid-list__item {
            &:hover {
                &.teaser-list__audio {
                    strong {
                        &:before {
                            background: transparent url(../svg/icons/audio-b.svg) no-repeat right 0 center;
                        }
                    }
                }

                &.teaser-list__video {
                    strong {
                        &:before {
                            background: transparent url(../svg/icons/video-b.svg) no-repeat right 0 center;
                        }
                    }
                }

                &.teaser-list__gallery {
                    strong {
                        &:before {
                            background: transparent url(../svg/icons/album-b.svg) no-repeat right 0 center;
                        }
                    }
                }

            }
        }
    }

}

.teaserGrid-list__Among {
    .teaserGrid-list__slide {
        .teaserGrid-list__item {
            &:hover {
                a {
                    &:hover {
                        h4, .h4,
                        span {
                            color: rgba(227, 2, 14, 0.5);
                        }
                    }
                }

                .teaser-list__audio {
                    a {
                        &:hover {
                            strong {
                                &:before {
                                    background: transparent url(../svg/icons/audio-r.svg) no-repeat right 0 center;
                                }
                            }
                        }
                    }
                }

                .teaser-list__video {
                    a {
                        &:hover {
                            strong {
                                &:before {
                                    background: transparent url(../svg/icons/video-r.svg) no-repeat right 0 center;
                                }
                            }
                        }
                    }
                }

                .teaser-list__gallery {
                    a {
                        &:hover {
                            strong {
                                &:before {
                                    background: transparent url(../svg/icons/album-r.svg) no-repeat right 0 center;
                                }
                            }
                        }
                    }
                }

            }
        }
    }
}

/* -----------------------
    Abstaende der Bloecke
 ------------------------*/

.teaserGrid-list {
    &.teaserCombinationA {
        .teaserCombinationA__item {
            figure {
                margin: 0 0 1.125rem 0;
            }
        }
    }
}

.teaserGrid-list {
    &.teaserCombinationA {
        .teaserCombinationA__item {
            .teaserGrid-list__Among--content {
                figure {
                    margin: 0;
                }
            }
        }
    }
}

.teaserCombinationA__item {
    .teaserGrid-list__Among--content {
        margin: 0 0 40px 0;
    }
}

.teaserCombinationA__item {
    .teaserGrid-list__Among--below {
        .teaserGrid-list__Among--content {
            margin: 0 0 20px 0;

            &:last-child {
                margin: 0 0 0 0;

                figure {
                    margin: 0 0 0 0;

                }

                h3, .h3 {
                    margin: 0;
                }
            }
        }
    }
}

.teaserCombinationA__item {
    .teaserGrid-list__Among--below {
        .teaserGrid-list__Among--content {
            h3, .h3 {
                margin: 0;
            }
        }
    }
}

.teaserCombinationA__item {
    .teaserGrid-list__Among--above {
        .teaserGrid-list__Among--content {
            &:last-child {
                margin: 0 0 0 0;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .teaserCombinationA__item {
        .teaserGrid-list__Among--above {
            .teaserGrid-list__Among--content {
                &:nth-child(3) {
                    margin: 0 0 0 0;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .teaserCombinationA__item {
        .teaserGrid-list__Among--above {
            .teaserGrid-list__Among--content {
                &:nth-child(3) {
                    margin: 0 0 20px 0;
                }
            }
        }
    }
}

.teaserCombinationA__item {
    .col-sm-3 {
        padding-right: 0;
    }
}

.teaserCombinationA__item--above {
    @include media-breakpoint-up(lg) {
        margin-bottom: 40px;
    }
}

.teaserCombinationA__item--below {
    margin-bottom: 6px;
}

.teaserGrid-list {
    &.teaserGrid-list__Among {
        .teaserCombinationA__item--above {
            .teaserGrid-list__item {
                margin-bottom: 0;
            }

            @include media-breakpoint-down(md) {
                .teaserGrid-list__item {
                    margin-bottom: 40px;
                }
            }

            p {
                margin: 0;
            }
        }
    }
}

.teaserGrid-list__Among--above {
    @include media-breakpoint-down(md) {
        column-count: 2;
        column-gap: 30px;
    }
    @include media-breakpoint-down(xs) {
        column-count: 1;
        column-gap: 15px;
    }
}

.teaserCombinationA {
    .teaserGrid-list__slide {
        .teaserGrid-list__item {
            .cat {
                display: none;
            }
        }
    }
}

.MZ__teaserCombination {

    .teaserCombinationA {
        .teaserGrid-list__slide {
            .teaserGrid-list__item {
                .cat {
                    position: absolute;
                    top: 0;
                    left: 15px;
                    z-index: 3;
                    background: $color-brand;
                    color: $color-white;
                    padding: 5px 20px;
                    text-transform: uppercase;
                    display: block;
                    max-width: 413px;
                }
            }
        }
    }

    .teaserCombinationA__item {
        &.teaserCombinationA__item--above {
            .teaserCombinationA__item {
                .cat {
                    height: 140px;
                    width: 135px;
                    font-size: 18px;
                    text-align: center;
                    display: table;
                }
            }
        }
    }

    .teaserCombinationA__item {
        &.teaserCombinationA__item--above {
            .teaserCombinationA__item {
                .cat strong {
                    color: $color-white;
                    display: table-cell;
                    vertical-align: bottom;
                }
            }
        }
    }
    .teaserGrid-list {
        .teaserCombinationA__item {
            strong {
                color: $color-brand;
            }
        }
    }

    .teaserCombinationA__item {
        &.teaserCombinationA__item--above {
            .teaserCombinationA__item {
                &.teaserGrid-list__Among--item {
                    .cat {
                        display: none;
                    }
                }
            }
        }
    }

    .teaserCombinationA {
        .teaserGrid-list__slide {
            .teaserGrid-list__item {
                .cat {
                    position: absolute;
                    top: 0;
                    left: 15px;
                    z-index: 3;
                    background: $color-brand;
                    color: $color-white;
                    padding: 5px 20px;
                    text-transform: uppercase;
                    display: block;
                }
            }
        }
    }

    .teaserCombinationA__item {
        &.teaserCombinationA__item--above {
            .teaserCombinationA__item {
                .cat {
                    height: 140px;
                    width: 135px;
                    font-size: 18px;
                    text-align: center;
                    display: table;

                    strong {
                        color: $color-white;
                        display: table-cell;
                        vertical-align: bottom;
                    }
                }
            }
        }
    }

    .teaserGrid-list {
        .teaserCombinationA__item {
            strong {
                color: $color-brand;
            }
        }
    }

    .teaserCombinationA__item {
        &.teaserCombinationA__item--above {
            .teaserCombinationA__item {
                &.teaserGrid-list__Among--item {
                    .cat {
                        display: none;
                    }
                }
            }
        }
    }

    .teaserGrid-list {
        strong {
            span {
                display: none;
            }
        }
    }
}
