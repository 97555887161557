.lightbox {
	display: none;
	position: fixed;
	z-index: 9999;
	width: 100%;
	height: 100%;
    justify-content: center;
    align-items: center;
	top: 0;
	left: 0;
	background: rgba(0,0,0,0.8);

    img {
    	max-width: 90%;
    	max-height: 80%;
    }

    &:target {
    	outline: none;
    	display: flex;
    }
}
