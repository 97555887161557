.search-wrapper {
    position: absolute;
    height: 68px;
    border-top: 2px solid #ebebef;
    box-shadow: 0 1px 5px -2px rgba(0,0,0,.2);
    background-color: #F8F8F9;
    width: 100%;
    z-index: 105;
    display: none;
}

.search--desktop__form {
    width: 100%;
    padding: 0;
    border: none;
    background-color: transparent;

    &:before {
        content: '';
        position: absolute;
        top: 53px;
        left: 26px;
        width: 1.25rem;
        height: 1.25rem;
        background-image: url(../svg/icons/search.svg);
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        -webkit-transform: translate(0,-30px);
        -ms-transform: translate(0,-30px);
        transform: translate(0,-30px);
        opacity: .5;
        z-index: 1;
    }
}

.search--desktop__input {
    border-radius: 0;
    font-size: $search-desktop-font-size;
    line-height: $search-desktop-line-height;
    margin: 0;
    width: 100%;
    padding: $search-desktop-padding-y $search-desktop-padding-x;
    border: none;

    @include igm-placeholder {
        color: $search-desktop-color;
    }

    &,
    &:focus,
    &:hover,
    &:active {
        color: $search-desktop-color;
        background-color: transparent;
    }
}

.search--desktop__trigger {
    &.dropdown-toggle {
        &:after {
            display: none;
        }
    }
}

/**
 * Handling html element rql and pseudo elements
 */

html.search-desktop-open {
    body::before {
        width: 100vw;
        height: $search-desktop-line-height + $search-desktop-padding-y*2 + $search-desktop-border-top + $search-desktop-border-bottom;
        border-top: $search-desktop-border-top solid $color-grey3;
        box-shadow: 0 1px 5px -2px rgba(0, 0, 0, 0.2);
        background-color: $color-lightgrey;
    }

    body::after {
        opacity: 0;
    }
}

.search--mobile__form {
    margin-bottom: 20px;
    position: relative;
    z-index: 3;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 20px;
        width: 2rem;
        height: 2rem;
        background-image: url('../svg/icons/search.svg');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        transform: translate(0, -1.125rem);
        opacity: 0.5;
    }
}

.search--mobile__input {
    width: 100%;
    color: $search-mobile-color;
    border: none;
    background-color: transparent;
    padding: $search-mobile-padding-y 20px $search-mobile-padding-y $search-mobile-padding-x;
    border-radius: 0;
    font-size: $search-mobile-font-size;
    line-height: $search-mobile-line-height;

    @include img-plchld {
        color: $search-mobile-color;
    }

    &:focus,
    &.focus {
        outline: none;
        border-color: transparent;
        background-color: transparent;
    }
}

.search--desktop__reset,
.search--mobile__reset {
    background-image: url('../svg/icons/close.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 20px;
    height: 28px;
    width: 28px;
    background-color: transparent;
    transform: translate(0, -14px);
    z-index: 15;
    cursor: pointer;

    &,
    &:hover,
    &:focus,
    &:active {
        -webkit-appearance: none;
        border: none;
        outline: none;
    }

    &:not(.show) {
        display: none;
    }
}
