%navigation {
    padding: 0;
    > li {
        display: inline;
    }
}

.navbar {
    &--topnav {
        flex-direction: row;

        > ul {
            &.navbar-nav--meta {
                flex-direction: row;

                > .nav-item {
                    padding: 0 10px;

                    > .nav-link {
                        font-size: 11/16*1rem;
                        line-height: $topnav-height;
                        padding: 0;

                        &.active {
                            position: relative;
                            color: $color-brand;
                        }

                        text-transform: uppercase;
                        white-space: nowrap;
                    }
                }

                &:first-child {
                    transform: translate(-10px, 0);
                }

                &:last-child {
                    transform: translate(10px, 0);
                }
            }
        }
    }
}

.nav {
    &--main {
        display: inline-block;

        > ul {
            @extend .clearfix;
            display: inline-flex;

            padding: 0;
            margin: 0 $menu-distance-y;

            @include media-breakpoint-up(xl) {
                margin: 0 ($menu-distance-y*2);
            }

            > li {
                display: inline-block;
                padding: 0 $menu-distance-y;

                @include media-breakpoint-up(xl) {
                    padding: 0 ($menu-distance-y*1.5);
                }

                &.show{
                    > a{
                        text-decoration: none;
                        color: $color-brand;
                        border-bottom: $border3;
                    }
                }

                > a {
                    font-size: $mainnav-level0-font-size;
                    letter-spacing: -1px;
                    text-transform: uppercase;
                    float: left;
                    display: inline-block;
                    color: $color-darkgrey;
                    white-space: nowrap;
                    margin: $topnav-height 0 $topnav-height 0;
                    border-bottom: $border2;

                    &:hover,
                    &:focus,
                    &.active {
                        text-decoration: none;
                        color: $color-brand;
                    }
                    &:focus,
                    &.active {
                        border-bottom: $border3;
                    }

                    &.active {
                        position: relative;

                        &:after {
                            content: '';
                            position: absolute;
                            bottom: 27 / 16 * 1rem;
                            left: 0;
                            width: 100%;
                            height: $menu-height-extra;
                            background-color: $color-brand;
                            display: none;
                        }
                    }
                }
            }
        }

        .dropdown-toggle-main-nav {
            &:after {
                display: none;
            }
        }
    }
}

.navigation {
    &--mobile {
        &--main {
            a {
                &.dropdown-toggle {
                    + ul {
                        display: none;
                    }

                    &.active {
                        + ul {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 576px) {
    .col-sm-4,
    .col-sm-8,
    .col-sm-9,
    .col-sm-3 {
        float: left;
    }
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropdown-menu {
    &--main {
        background-color: transparent;
        border-radius: 0;
        padding: ($menu-height-extra2*2) 0 0 0;
        min-height: 400px;
        border: none;
        /*
        border-top: $border4;
        */
    }

    li {
        list-style: none;
        line-height: ($menu-height-extra2*2.5);
        padding: 0 0 0 1px;
    }

    a {
        color: $black;
        text-decoration: none;
        display: block;
        position: relative;
        padding: $grid-gutter-width-base/2;
        font-size: $mainnav-level0-font-size;
        line-height: $mainnav-level0-fline-height;


        &.dropdown-submenu__active {
            background-color: $color-lightgrey;
            color: $color-brand;
            &.dropdown-toggle {

                &:after {
                    background: $color-lightgrey url('../svg/icons/navi-arrow-hover.svg') no-repeat right 0 center;
                }
            }
        }
    }

    &__headline {
        padding: $menu-headline-padding ($menu-headline-padding*3) 0;

        h2, .h2 {
            position: absolute;
            left: (3*$menu-headline-padding);

            @include media-breakpoint-up(xl) {
                left: (-5.2*$menu-headline-padding);
            }
        }
    }

    &__content {

        > ul {
            padding: 0;
        }

        li {
            &:hover,
            &:focus{

                > a {
                    color: $color-brand;
                }

                .dropdown-toggle {
                    &:after {
                        background: transparent url('../svg/icons/navi-arrow-hover.svg') no-repeat right 0 center;
                    }
                }
            }

            &.active {
                /*
                background-color: $color-lightgrey;
                */

                > a {
                    /*
                    color: $color-brand;
                    */
                }

                .dropdown-toggle {
                    &:after {
                        /*
                        background: $color-lightgrey url('../svg/icons/navi-arrow-hover.svg') no-repeat right 0 center;
                        */
                    }
                }
            }
        }

        .dropdown-toggle {
            &:after {
                background: $white url('../svg/icons/navi-arrow.svg') no-repeat right 0 center;
                width: $menu-dropdown-toggle-arrow;
                height: $menu-dropdown-toggle-arrow;
                border: none;
                margin: -$menu-distance-y $menu-distance-y 0 0;
                top: 50%;
                position: absolute;
                right: 0;

                @include media-breakpoint-up(xl) {
                    right: ($menu-distance-y*1,5);
                }

            }
        }

        .dropdown-menu {
            border: none;
        }
    }

    &__overview {
        color: $color-grey1;
        padding: (0.5*$menu-height-extra2) 0 0 0;
        margin: 0 0 $menu-height-extra2 0;
        position: relative;

        @include media-breakpoint-up(xl) {
            padding: (0.5*$menu-height-extra2) 0 $menu-height-extra2 0;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: $grid-gutter-width-base/2;
            width: calc(100% - #{70px + $grid-gutter-width-base/2});
            height: 1px;
            //background-color: rgba(0, 0, 0, 0.05);
        }

        ul {
            padding: 0 0 0 9/16*1rem;

            @include media-breakpoint-up(xl) {
                padding: 0;
            }
        }


        > ul {
            > li {
                &:first-child {
                    line-height: 0;
                    padding: 0 35px;
                    @include media-breakpoint-up(xl) {
                        padding: 0 $grid-gutter-width-base/2;
                    }
                    background: none;

                    @include media-breakpoint-up(xl) {
                        padding: 0 35px;
                    }
                }
            }
        }

        li {
            padding: 0;
            color: $color-menu;

            &:first-child {
                font-size: (0.8*$menu-height-extra2);
                margin: 0 0 -$menu-dropdown-toggle-arrow 0;
            }
        }

        hr {
            width: $menu-width-hr;
            margin: (0.5*$menu-height-extra2) 0 0 $menu-height-extra2;
            color: $color-grey3;
        }
    }

    .dropdown-submenu {

        &__content {
            background-color: transparent !important;

            ul {
                padding: 0;
            }
        }

        .dropdown-menu {
            top: 0;
            left: 100%;
            margin-top: -1px;

            &__overview {
                ul {
                    padding: 0; // 0 0 0 9/16*1rem;
                }
            }
        }
    }

    &.dropdown-submenu {
        &__content {
            background-color: transparent !important;

            .dropdown-menu {
                &__overview {
                    padding-top: 0;
                }
            }
        }

    }
}



/* background full width */

@include media-breakpoint-up(lg) {
    body {
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: $header-height + $topnav-height + $header-border-width + 1px;
            background-size: 100%;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            display: block;
            background-color: $white;
            border-radius: 0;
            transition: top 1s ease-in-out;
        }

        &.dialogStage__pageLogin--bodyClass {
            &:before,
            &:after {
                top: $dialogStage__pageLogin-height + $header-height + $topnav-height + $header-border-width + 3px;
            }
        }
    }

    body {
        &:before {
            left: 0;
            width: 100%;
            z-index: 9;

            html.navigation-desktop-level-1 &,
            html.navigation-desktop-level-2 & {
                box-shadow: 0 1px 5px -2px rgba(0, 0, 0, 0.2);
                border-top: 1px solid $color-grey3;
            }
        }
    }

    body {
        &:after {
            right: 0;
            width: 50%;
            z-index: 9;

            html.navigation-desktop-level-1 &,
            html.navigation-desktop-level-2 & {
                border-top: 1px solid $color-grey3;
            }

            html.navigation-desktop-level-1 & {
                background-color: $white;
            }

            html.navigation-desktop-level-2 & {
                background-color: $color-lightgrey;
            }
        }
    }
}




.menu,
.sub-menu {
    margin: 0;
    padding: 0;
}

.menu-item,
.sub-menu {
    border: 0 solid;
}

.menu {
    .menu-item {
        display: block;

        &.has-children {
            position: relative;

            .menu-item {
                position: relative;
            }
        }

        &:not(:last-child) {
            border-width: 0 0 1px;
        }

        a {
            padding: 1em;
            display: flex;
            justify-content: space-between;
            text-decoration: none;
        }
    }

    .sub-menu {
        border-width: 1px 0 0;
        visibility: hidden;
        opacity: 0;
        height: 0;
        width: 0;
        transition: visibility .25s, opacity .25s ease-in-out;

        .menu-item {
            visibility: hidden;
        }
    }

    .menu-item {
        &:hover > .sub-menu,
        &:active > .sub-menu,
        &:focus > .sub-menu {
            opacity: 1;
            height: auto;
            width: auto;
        }

        &:hover > .sub-menu,
        &:active > .sub-menu,
        &:focus > .sub-menu {
            visibility: visible;

            .menu-item {
                visibility: visible;
            }
        }
    }
}

.nav--rtl {
    direction: rtl;
    unicode-bidi: bidi-override;
}

@media only screen and (min-width: 1024px) {
    .nav-sr-only .menu {
        display: flex;
        flex-flow: row wrap;

        > .menu-item {
            border-width: 0 1px 0 0;
        }
        
        .menu-item {
            &:hover > .sub-menu,
            &:active > .sub-menu,
            &:focus > .sub-menu {
                width: 225px;
            }
        }

        .sub-menu {
            position: absolute;
            z-index: 3000;
            top: 100%;
            left: 0;
            border-width: 0;

            .sub-menu {
                top: 0;
                left: 100%;
            }
        }
    }

    .nav--rtl .menu {
        .sub-menu {
            top: 100%;
            left: auto;
            right: 0;

            .sub-menu {
                left: auto;
                right: 100%;
            }
        }
    }

    .nav--cen .menu {
        justify-content: center;
    }
}

.nav-sr-only {
    background-color: $color-brand;

    .menu > .menu-item {
        border-color: rgba(255, 255, 255, 0.1);
        color: $color-lightgrey;

        > a {
            color: $color-lightgrey;

            &:hover,
            &:active,
            &:focus {
                background-color: $color-white;
                color: $color-black;
            }
        }
    }

    .sub-menu {
        background-color: #eee;
        border-color: rgba(0, 0, 0, 0.1);

        .menu-item {
            border-color: rgba(0, 0, 0, 0.1);
            color: #222;

            a {
                color: #222;

                &:hover,
                &:active,
                &:focus {
                    background-color: white;
                    color: black;
                }
            }
        }
    }
}
