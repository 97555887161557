.streikportal {
    .box {
        background-color: $color-lightgrey;
        padding: 40px;
    }

    .shy {
        color: $color-lightgrey3;
        font-size: 14px;
        line-height: 22px;
    }

    .btn-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .btn-secondary {
            background-color: $color-lightgrey6;
            margin-right: 10px;
        }

    }

    .alert {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $color-light-red;
        padding: 30px 40px;
        width: auto;

        .icon-igm-checkmarkcircle,
        .icon-igm-warning {
            margin-right: 15px;
            font-size: 20px;
        }

        .alert-heading {
            font-size: 14px;
            line-height: 18px;
        }

        &.alert-success {
            color: $color-light-green;
            border-color: $color-light-green;
        }

        &.alert-info {
            color: $color-info;
            border-color: $color-info;
            background-color: $color-light-blue;
        }
    }

    .contatct-header {
        font-size: 16px;
        font-family: $font-family-CondOTBold;
        line-height: 24px;

        @include for-tablet-up {
            font-size: 24px;
            font-weight: 500;
            height: 60px;
            line-height: 30px;
        }
    }

    .info {
        font-size: 14px;
    }
}
