.icon {
    &,
    &:before {
        display: inline-block;
    }

    &:before {
        content: '';
        width: 0.8125rem;
        height: 0.8125rem;
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &.icon-login:before {
        background-image: url('../svg/icons/login.svg');
    }

    &.icon-logout:before {
        background-image: url('../svg/icons/logout.svg');
    }

    &.icon-lock_locked:before {
        background-image: url('../svg/icons/lock_locked.svg');
    }

    &.icon-lock_unlocked:before {
        background-image: url('../svg/icons/lock_unlocked.svg');
    }

    &.icon-edit:before {
        background-image: url('../svg/icons/edit.svg');
    }

    &--big {
        &:before {
            width: 1.25rem;
            height: 1.25rem;
        }
    }

    &.icon-album:before {
        background-image: url('../svg/icons/album-b.svg');
    }

    &--large:before {
        width: 32px;
        height: 32px;
    }

}
