.grid-pagination {

    .teaserGrid-list__header {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        @include for-tablet-up {
            justify-content: space-between;
            flex-direction: row;
            border-bottom: 2px solid #EBEBEF;

            h3, .h3 {
                margin-bottom: -2px;
            }
        }
    }

    .select-wrapper {
        display: flex;

        span {
            white-space: nowrap;
            padding-top: 7px;
            margin-right: 10px;
        }

        @include for-tablet-up {
            margin-top: -30px;
        }
    }

    select {
        display: inline;
        font-family: $font-family-base-bold;
        min-width: 200px;
    }
}
