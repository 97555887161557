.regional-filter {

    &.page-section {
        margin-bottom: 0px;
    }

    .filter-area {
        background-color: $color-lightgrey;
        padding: 25px 0;
    }

    .filter-wrapper.closed {
        display: none;
    }

    .filter-toggle {
        display: inline-block;
        margin-bottom: 25px;

        cursor: pointer;

        font-family: $font-family-CondOTBold;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 14px;
    }

    .icon-igm-dropdown-arrow {
        display: inline-block;
        margin-right: 8px;

        font-size: 8px;
        color: $color-brand;
    }

    .icon-igm-dropdown-arrow.closed,
    .closed .icon-igm-dropdown-arrow {
        transform: rotate(-90deg) translateX(2px);
    }

    .group {
        padding: 15px;
        margin-bottom: 10px;

        border: solid 1px $color-grey3;
        background-color: $color-white;

        .header {
            cursor: pointer;
            font-family: $font-family-CondOTBold;
            font-size: 18px;
            line-height: 24px;

            &.inactive {
                color: $color-grey3;
            }
        }

        .body {
            padding: 20px 30px;

            &.closed {
                display: none;
            }

            .checkbox-wrapper {
                column-count: 1;
                margin-bottom: 40px;
                line-height: 1.2;
            }

            @media all and (min-width: $mobile-size) {
                .checkbox-wrapper {
                    column-count: 2;
                }
            }

            .btn-wrapper {
                display: flex;
                justify-content: flex-end;
                flex-wrap: wrap;

                .btn {
                    margin-top: 5px;
                }
            }

            .btn-primary {
                color: $color-white;
                margin-left: 10px;
            }
        }
    }

    .selected-filters,
    .selected-filters div,
    .selected-filters div div {
        display: inline-flex;
    }

    .selected-filters {
        flex-wrap: wrap;
        margin: 5px 0;

        .filter {
            padding: 5px 10px;
            margin: 0 5px 5px 0;

            background-color: $color-grey3;
            color: $color-lightgrey3;
        }
    }

    .selected-filters .filter,
    .btn-reset-all {
        font-size: 12px;
        line-height: 18px;
    }

    .btn-reset-all.not-a-link {
        display: inline-block;
        margin: 15px 5px 0 0;
        border: solid 1px $color-brand;
        padding: 7px 10px;

        color: $color-brand;
        cursor: pointer;

        i {
            font-size: 8px;
            margin-left: 5px;
        }

        &:hover {
            color: $color-brand;
        }
    }

    .result-counter-row {
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 30px;

        span {
            white-space: nowrap;
            padding: 7px 10px;
        }
    }

    .result-counter {
        display: flex;
        color: $color-grey1;
        font-family: $font-family-CondOTBold;
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 14px;
        text-transform: uppercase;
    }

    .select-wrapper {
        display: flex;
    }
}

.regional-archiv {
    .grid-3col {
        margin-top: 0;
        padding-top: 0;
    }

    .teaser-combination.teaser-combination-e {
        margin-bottom: 40px;
    }
}
