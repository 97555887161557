.isic {
    hr {
        margin: 10px 0 30px 0;
    }

    label:not(:first-child) {
        margin-top: 20px;
    }

    label.custom-control {
        margin-top: 0;
    }

    .group {
        margin-bottom: 60px;
    }

    .sidebar {
        margin-bottom: 60px;

        @include for-tablet-up {
            margin-bottom: 0;
        }

    }

    .text-muted {
        color: $color-lightgrey3;
        font-size: 14px;
        line-height: 18px;
    }
}

.isic__example {
    margin-bottom: 35px;
    max-width: 100%;
}
