.detailed-search {
    .pageIntro {
        padding: 0;

        @include for-tablet-up {
            padding: 0 0 40px;
        }
    }

    .page-section {
        margin-bottom: calc(40px - 1rem);

        @include for-tablet-up {
            margin-bottom: calc(45px - 1rem);
        }
    }

    .result-count {
        font-size: 16px;
        line-height: 28px;
        text-align: center;

        margin: 20px 0;

        @include for-tablet-up {
            margin: 40px 0;
        }
    }

    .btn-wrapper {
        display: flex;
        flex-direction: row-reverse;
        align-items: right;
        margin-top: 20px;

        @include for-tablet-up {
            .btn {
                margin-top: 12px;
            }
        }
    }

    .searchPage__pagination {
        margin-bottom: 40px;
        padding: 0 15px;

        @include for-tablet-up {
            margin-bottom: 70px;
            padding-top: 40px;
        }

        p {
            margin-bottom: 0;
        }
    }

    .icon-igm-calendar-simple::before {
        pointer-events: none;
    }

    input[readonly] {
        border-color: $input-border-color;
    }
}
