.seminar-detail {
    .MZ__teaserCombination .teaser-list__item strong {
        color: $color-black;
    }

    .MZ__teaserCombination .teaser-list .teaser-list__item span,
    .MZ__teaserCombination .teaser-list .teaser-list__item:hover span {
        color: $color-lightgrey3;
    }

    .pageIntro__marginale {
        ul {
            margin-bottom: 0;
        }

        li {
            line-height: unset;
        }
    }

    hr {
        margin-bottom: 40px;

        @include for-tablet-up {
            margin-bottom: 80px;
        }
    }

    h3, .h3 {
        margin-bottom: 30px;
    }

    p {
        margin-bottom: 30px;

        &:last-of-type {
            margin-bottom: 40px;
        }
    }

    ul {
        padding-left: 28px;
    }

    li {
        line-height: 28px;
    }

    .details {
        margin-bottom: 50px;

        @include for-tablet-up {
            margin-bottom: 0;
        }

        h5:not(:first-child) {
            margin-top: 20px;
        }

        p {
            margin-bottom: 0;
        }

        ul {
            padding-left: 15px;
        }

        li {
            line-height: 2em;
        }
    }

    .box {
        border: solid 1px $color-lightgrey5;
        padding: 15px 20px;
        font-size: 14px;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    .label {
        margin: 10px 0 0;

        font-size: 13px;
        font-family: $font-family-CondOTBold;
        line-height: 18px;
        color: $color-lightgrey3;

        &:first-child {
            margin-top: 0;
        }
    }



  table {
    width: 100%;

        th {
            padding: 15px 40px;
            font-size: 14px;
        }

        td {
            padding: 20px 40px;
        }

        thead {
            border: 1px solid $color-table-border;
            border-radius: 3px 3px 0 0;
            background-color: $color-lightgrey;
        }

        tr {
            border: 1px solid $color-table-border;

            &:last-of-type {
                border-radius: 0 0 3px 3px;
            }
        }

        i {
            display: inline-block;
            transform: translateY(2px);
        }
    }
}
