/**
 * IGMetall.de styles
 */

@import "core/variables";
@import "core/mixins";


@import "bootstrap/scss/bootstrap.scss";
@import "hamburgers/_sass/hamburgers/hamburgers.scss";
@import "bourbon/app/assets/stylesheets/bourbon";
@import "jquery-asBreadcrumbs/src/scss/asBreadcrumbs.scss";
@import "bootstrap-daterangepicker/daterangepicker.scss";
@import "crayon/dist/crayon.scss";
@import "hierarchy-select/src/hierarchy-select.scss";
@import "owl.carousel/src/scss/owl.carousel.scss";
@import "owl-carousel/theme";
@import "magnific-popup/src/css/main.scss";


@import "_igmIcons";


@import "common/forms";
@import "common/layout";
@import "common/print";
@import "common/type";
@import "common/utilities";


@import "patterns/alert";
@import "patterns/autorenDetailSeite";
@import "patterns/bildTextModul";
@import "patterns/blazy";
@import "patterns/breadcrumb";
@import "patterns/buttons";
@import "patterns/comments";
@import "patterns/committees";
@import "patterns/contentBlock";
@import "patterns/cookie-banner";
@import "patterns/ctaTeaser";
@import "patterns/dropdown";
@import "patterns/footer";
@import "patterns/formular";
@import "patterns/glossary";
@import "patterns/grid-pagination";
@import "patterns/header";
@import "patterns/hierarchy-select";
@import "patterns/icons";
@import "patterns/isic";
@import "patterns/footer";
@import "patterns/formular";
@import "patterns/interviews";
@import "patterns/link-list";
@import "patterns/login";
@import "patterns/map";
@import "patterns/modal";
@import "patterns/myInfocenter";
@import "patterns/navigations";
@import "patterns/navigations-footer";
@import "patterns/navigations-mobile";
@import "patterns/newsletterAnmeldung";
@import "patterns/newsticker";
@import "patterns/officeSearch";
@import "patterns/pageIntro";
@import "patterns/payout-calculator";
@import "patterns/quiz";
@import "patterns/radio";
@import "patterns/regional-archiv";
@import "patterns/search";
@import "patterns/searchPage";
@import "patterns/sections";
@import "patterns/addAddressForm";
@import "patterns/lightbox";

@import "patterns/seminar/basics";
@import "patterns/seminar/detail";
@import "patterns/seminar/detailedSearch";
@import "patterns/seminar/formular";
@import "patterns/seminar/simpleSearch";
@import "patterns/seminar/seminar";

@import "patterns/slider-elements/sliderElement";
@import "patterns/slider-elements/argumentationsElement";
@import "patterns/slider-elements/teaserRow";

@import "patterns/socialMediaLinks";
@import "patterns/socialMediaWall";
@import "patterns/stage";
@import "patterns/storify";
@import "patterns/streikportal";
@import "patterns/strength-meter";
@import "patterns/teaserCombinationA";
@import "patterns/teaserCombinationB";
@import "patterns/teaserCombinationC";
@import "patterns/teaserCombinationD";
@import "patterns/teaserCombinationE";
@import "patterns/teaserCombinationF";
@import "patterns/teaserCombinationG";

@import "patterns/teaser-combinations/teaser-combination";
@import "patterns/teaser-combinations/combination-a";
@import "patterns/teaser-combinations/combination-b";
@import "patterns/teaser-combinations/combination-c";
@import "patterns/teaser-combinations/combination-d";
@import "patterns/teaser-combinations/combination-e";
@import "patterns/teaser-combinations/combination-f";
@import "patterns/teaser-combinations/combination-g";
@import "patterns/teaser-combinations/combination-h";


@import "patterns/teaserGenerell";
@import "patterns/teaserGrid";
@import "patterns/validation";
@import "patterns/video-iframe";
@import "patterns/dropdown";
@import "patterns/imageGallery";
@import "patterns/accessibility";

@import "elements/box";
@import "elements/contact";
@import "elements/daterange";
@import "elements/dropbox";
@import "elements/event";
@import "elements/teaser";
