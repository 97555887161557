
.dialogStage {
    touch-action: pan-y;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: relative;
}

.dialogStage__inner {
    width: 100%;
    overflow: hidden;
    position: relative;
    background-position: center;
    background-size: cover;
    height: 700px;
    background-color: #f8f8f9;
    display: flex;
    background-image: url("../images/bg/wir-fuer-mehr.jpg");
}

#registerDialog,
#loginDialogStage {
    .dialogStage__inner {
        height: 1100px;
    }
}

.dialogStage__content--wrapper {
    width: 1400px;
    margin: 60px auto;
}

.dialogStage__context--box {
    background-color: #003eff;
    color: #ffffff;
    padding: 20px;
    font-size: 14px;
}

.dialogStage__content {
    background: #fff;
    color: #000;
    text-align: left;
    padding: 30px 20px 20px;
    display: table;
    width: 100%;
}

.dialogStage__content p.error {
    color: #E3020E;
}

.dialogStage__content input.btn {
    margin-top: 10px;
    cursor: pointer;
}

.dialogStage__content a.btn {
    height: 40px;
    width: inherit;
    color: #ffffff;
    font-size: 14px;
    line-height: 24px;
}

.dialogStage__content a.btn.btn-secondary {
    color: $color-black;
}

.dialogStage__content--link {
    padding-left: 0px;
    white-space: nowrap;

    @include media-breakpoint-down(sm) {
        padding-right: 20px;
    }
}

.dialogStage__subcontent--block {
    .dialogStage__content--link {
        padding-left: 0;

        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 20px;
        }
    }
}

.dialogStage__content--link img {
    display: inline-block;
    font-size: .75em;
    width: 1em;
    height: auto;
    margin-left: .5em;
    vertical-align: middle;
}

.dialogStage__subcontent--block {
    clear: both;
    margin: 20px -20px -20px -20px;
    padding: 20px;
    background-color: #F8F8F9;
    border-top: 2px solid #efefef;
}

.dialogStage__subcontent--block {
    h1, .h1 {
        font-size: 18px;
        font-family: $font-family-CondOTBold;
        line-height: 24px;
        padding-bottom: 15px;
    }

    p {
        color: #333333;
        font-size: 14px;
        line-height: 24px;
    }

    a {
        color: #E3020E;
        font-size: 14px;
        line-height: 24px;
    }

    ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        padding: 0;

        li {
            display: list-item;
            text-align: -webkit-match-parent;
            margin-right: 25px;

            &:nth-child(2) {
                margin-right: 0;
            }

            @include media-breakpoint-down(sm) {
                margin-right: 0;
            }
        }
    }
}

.form-group.has-error label.error {
    font-size: 12px;
}

.dialogStage__content {
    a {
        height: 24px;
        color: #E3020E;
        font-size: 14px;
        line-height: 24px;
        width: inherit;
    }

    h1, .h1 {
        font-size: 36px;
        font-family: $font-family-CondOTBold;
        line-height: 42px;
        padding-bottom: 30px;
    }

    p {
        color: #000000;
        font-size: 14px;
        line-height: 24px;
    }

    label {
        height: 18px;
        color: #000000;
        font-size: 14px;
        font-family: $font-family-CondOTBold;
        line-height: 28px;
        margin-bottom: 0;
    }

    .form-group {
        .row {
            div {
                &:nth-child(2) {
                    text-align: right;
                }
            }
        }
    }
}

.dialogStage__content--label {
    font-family: $font-family-base-bold;
}

.dialogStage__content {
    h2, .h2 {
        margin: 0 0 2rem 0;
    }
}

.dialogStage__arrow {
    width: 0;
    height: 0;
    content: "";
    border-top: 1.3em solid;
    border-right: 1.3em solid transparent;
    border-left: 1.3em solid transparent;
    position: relative;
    left: 1.5rem;
    color: #003eff;
}

.dialogStage__arrow--bg {
    width: 100%;
    background: white;
}

.dialogStage__content {

    @media (max-width: 576px) {
        .col-xs-6 {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}

/* das hier muss mit inhaltsblock gemerged werden

uschi START

*/

.contentBlock__downloadList a:before {
    content: url(../svg/icons/downloadlink-red.svg);
    display: inline-block;
    font-size: 0.75em;
    width: 1em;
    height: auto;
    margin-right: 0.5em;
    vertical-align: middle;
    top: 3px;
    position: relative;
}

.contentBlock__downloadList a span {
    color: rgba(0, 0, 0, 0.5);
}

/*

uschi ENDE

*/

.locked a:after {
    content: url(../svg/icons/lock_locked.svg);
    display: inline-block;
    font-size: 0.75em;
    width: 1em;
    height: auto;
    margin-left: 0.5em;
    vertical-align: middle;
    top: 2px;
    position: relative;
}

.unlocked.contentBlock__downloadList {

    a {
        &:after {
            content: url(../svg/icons/lock_unlocked.svg);
            display: inline-block;
            font-size: 0.75em;
            width: 1em;
            height: auto;
            margin-left: 0.5em;
            vertical-align: middle;
            top: 2px;
            position: relative;

        }

    }
}

@include media-breakpoint-up(xl) {

    .contentBlock__downloadList {
        &:hover {
            .contentBlock__downloadList--hoverBox {
                display: inline-table;
            }
        }
    }
}

.contentBlock__downloadList {
    display: flex;

    p {
        width: 100%;
        display: inline-table;
    }
}

.contentBlock__downloadList--hoverBox {
    background: black;
    border-radius: 4px;
    padding: 0 8px;
    position: relative;
    top: -45px;
    left: -1040px;
    display: none;
}

.contentBlock__downloadList--hoverBoxLonger {
    left: -1096px;
    .contentBlock__downloadList--arrow {
        left: 166px;
    }
}

.contentBlock__downloadList--hoverBox p {
    color: white;
    font-size: 14px;
    display: inline;
}

.contentBlock__downloadList--arrow {
    width: 0;
    height: 0;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
    position: absolute;
    left: 110px;
    color: black;
    z-index: 9999999;
    top: 28px;
}

.dialogStage__pageLogin--closeX {
    background-image: url(../svg/icons/close_w.svg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: inline-block;
    position: absolute;
    top: 37%;
    right: 9px;
    height: 28px;
    width: 28px;
    background-color: transparent;
    -webkit-transform: translate(0, -14px);
    transform: translate(0, -14px);
    z-index: 15;
    cursor: pointer;
}

.dialogStage__pageLogin--closeX {
    -webkit-appearance: none;
    border: none;
    outline: none;

    &:active,
    &:focus,
    &:hover {
        -webkit-appearance: none;
        border: none;
        outline: none;
    }
}

.dialogStage__pageLogin--displayNone {
    display: none;
}

.dialogStage__pageLogin {
    background-color: #E3020E;
    height: 44px;
    text-align: center;
    top: 0;
    transition: top 1s ease-in-out;
    position: relative;

    p {
        color: #ffffff;
        font-size: 14px;
        line-height: 45px;
    }
}

/*


.dialogStage__pageLogin--displayNone {
    top: 0;
    transition: top 1s ease-in-out;
    position: relative;

    &:hover{
        top: -44px
    }
}


.dialogStage__pageLogin--displayNone {
    top: 0;
    transition: top 1s ease-in-out;
    position: relative;

    .dialogStage__pageLogin--displayNone {
        &:hover {

            top: -44px
        }
    }
}

.dialogStage__pageLogin--displayNoneBody{

}

.dialogStage__pageLogin--displayNone {

}
*/
