/*
 * 	Default theme - Owl Carousel CSS File
 */

$color-base:			$color-brand !default;
$color-white:			#FFF !default;
$color-gray:			#D6D6D6 !default;

//nav

$nav-color: 			$color-brand !default;
$nav-color-hover:		$color-brand !default;
$nav-font-size: 		20px !default;
$nav-rounded: 			3px !default;
$nav-margin: 			5px !default;
$nav-padding: 			4px 7px !default;
$nav-background: 		$color-gray !default;
$nav-background-hover:	$color-base !default;
$nav-disabled-opacity: 	0.5 !default;

//dots

$dot-width:				10px !default;
$dot-height:			10px !default;
$dot-rounded:			30px !default;
$dot-margin: 			5px 7px !default;
$dot-background:		$color-gray !default;
$dot-background-active:	$color-base !default;

.owl-theme {
	// Styling Next and Prev buttons
    position: relative;

    .owl-item {

    }

	.owl-nav {
        position: initial;
        -webkit-tap-highlight-color: transparent;

		button.owl-prev,
        button.owl-next {
            position: absolute;
            bottom: 0;
            z-index: 5;

			color: $color-brand;
			font-size: 16px;
			cursor: pointer;

			&:hover {
				text-decoration: none;
			}
		}
        button.owl-prev{
            left: 0;
        }
        button.owl-next {
            right: 0;
        }

		.disabled {
			display: none;
		}
	}

	// Styling dots
	.owl-nav.disabled + .owl-dots {
		margin-top: 10px;
	}

	.owl-dots {
        display: flex;
        justify-content: center;
        flex-flow: wrap;
        margin: 40px auto 0 auto;
        max-width: 80%;
        width: 100%;
        height: 24px;
        overflow: visible;
        text-align: center;
		-webkit-tap-highlight-color: transparent;

		.owl-dot {
			display: inline-block;
			zoom: 1;
			*display: inline;

			span {
				width: $dot-width;
				height: $dot-height;
				margin: $dot-margin;
				background: $dot-background;
				display: block;
				-webkit-backface-visibility: visible;
				transition: opacity 200ms ease;
				border-radius: $dot-rounded;
			}

			&.active,
			&:hover {
				span {
					background: $dot-background-active;
				}
			}
		}
	}
}

.owl-carousel.disabled {
    display: block;

    >*:not(:first-child) {
        display: none;
    }
}
