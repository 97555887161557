%fullWidth {
    width: 100%;
}

.pane {
    @extend .clearfix;
}

.container {
    &--full {
        @extend .pane;
    }

    &,
    &--semifluid {
        width: auto !important;
        max-width: $max-width;
        margin: auto;

        padding-left: $grid-gutter-width-base/2;
        padding-right: $grid-gutter-width-base/2;

        @include media-breakpoint-up(md) {
            padding-left: 50px;
            padding-right: 50px;
        }
    }
}

// META TAGS
meta[name="breakpoint-xs"] {
    content: "#{map-get($grid-breakpoints, xs)}";
}

meta[name="breakpoint-sm"] {
    content: "#{map-get($grid-breakpoints, sm)}";
}

meta[name="breakpoint-md"] {
    content: "#{map-get($grid-breakpoints, md)}";
}

meta[name="breakpoint-lg"] {
    content: "#{map-get($grid-breakpoints, lg)}";
}

meta[name="breakpoint-xl"] {
    content: "#{map-get($grid-breakpoints, xl)}";
}
