.container.grey {
    background-color: $color-lightgrey;
    padding: 40px;
    margin-bottom: 40px;

    .btn-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 20px;

        .btn:not(:last-child) {
            margin-right: 10px;
        }

        .spacer {
            display: none;
            width: 100%;
        }
    }

    @include for-tablet-up {
        .btn-wrapper {
            margin-top: 40px;
            justify-content: right;

            .spacer {
                display: block;
            }
        }
    }
}
