.searchPage{

  .teaserGrid-list strong span:before {
        content: none;
    }

  .searchPage__header{
    background: $color-brand;

        h1, .h1 {
            text-align: center;
            color: $white;
            margin-top: 60px;
            margin-bottom: 0;
        }

        .form-group {
            margin: 40px 0 60px 0;
        }

    }

    .search-form-wrapper {
        padding: 40px 0;
        margin-bottom: 40px;

        .help-container {
            display: none;
        }

        &.help-open {
            .help-container {
                display: block;
            }
        }
    }

    .help-toggle {
        float: right;
    }

    .help-container {
        position: relative;
        padding: 40px;
        background-color: $color-grey3;

        .icon-igm-close {
            position: absolute;
            top: 30px;
            right: 30px;

            cursor: pointer;
        }
    }

    .filter-area {
        padding:0;
        background-color:white;
    }

    .range-picker {
        position: relative;
        margin: 0 0 30px 0;

        .content {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            width: 200px;

            background: white;
            z-index: 2;
            border: solid 1px $color-grey3;
        }

        .item {
            padding: 2px 10px;
            cursor: pointer;
        }

        .item:hover {
            background: $color-grey3;
        }
    }

    @media all and(min-width: $tablet-landscape-size){
        .from-input,
        .to-input {
            margin-top: 32px;
        }
    }

    @media all and(min-width: $desktop-size) {
        .range-picker {
            width: 54px;
        }
    }

    .searchPage__button {
        justify-content: flex-end;
        display: flex;
        height: 40px;
        align-self: flex-end;

        @include media-breakpoint-down(md) {
            margin: 20px 0 0 0;
        }
    }

    .teaserGrid-list__line {
        margin: 20px 0 40px 0;
    }

    .searchPage--topHits {
        color: $color-brand;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 14px;
        text-align: right;
        font-family: $font-family-CondOTBold;
        display: block;
        @include media-breakpoint-down(sm) {
            text-align: left;
            display: none;
        }
    }

    .searchPage__filterline--up {
        margin: 80px 0 30px 0;
    }

    .searchPage__filterline--profil {
        margin: 80px 0 80px 0;
    }

    .searchPage__filterline--down {
        margin: 30px 0 40px 0;
    }

    .searchPage__filter--results {
        color: $color-grey4;
        text-align: center;
        padding-top: 26px;
        padding-bottom: 40px;
        border-bottom: solid 2px $color-grey3;
    }
    
    .base-small {
        margin-top: 15px;
    }

    .base-small strong {
        display: inline;
    }

    .searchPage__pagination {

        &--wrapper {

            display: flex;
            justify-content: center;

            a {
                float: left;
                height: 44px;
                width: 44px;
                justify-content: center;
                display: flex;
                align-self: center;

                &:hover {
                    p {
                        color: red;
                    }

                    &.searchPage__pagination--arrowRight {
                        content: url(../svg/icons/next_red.svg);
                    }

                    &.searchPage__pagination--arrowLeft {
                        content: url(../svg/icons/prev_red.svg);
                    }

                }

                p {
                    align-self: center;
                    font-family: $font-family-Cond;
                    font-size: 14px;
                    letter-spacing: 1px;
                    line-height: 17px;
                    margin: 0;
                }

            }

            .searchPage__pagination--arrowRight {
                content: url(../svg/icons/next_black.svg);
                display: inline-block;
                font-size: 0.75em;
                width: 1em;
                height: auto;
                margin-left: 0.5em;
                vertical-align: middle;
            }

            .searchPage__pagination--arrowLeft {
                content: url(../svg/icons/prev_black.svg);
                display: inline-block;
                font-size: 0.75em;
                width: 1em;
                height: auto;
                margin-left: 0.5em;
                vertical-align: middle;
            }

            .searchPage__pagination--active {
                background-color: $color-lightgrey2;

                p {
                    font-family: $font-family-CondOTBold;
                }
            }

        }
    }

    .searchPage__pagination {
        padding: 40px 0 0 0;
    }

    .search--desktop__input {
        display: block;
        width: 100%;
        padding: 0.5rem 0.75rem;
        font-size: 1rem;
        line-height: 1.25;
        color: #333333;
        background-color: #FFFFFF;
        background-image: none;
        background-clip: padding-box;
        border: 1px solid #cccccc;
        border-radius: 0.25rem;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

    }
    .search--desktop__form {
        &:before {
            display: none;
        }
        &:after {
            content: '';
            position: absolute;
            top: 4rem;
            right: 20px;
            width: 2rem;
            height: 2rem;
            background-image: url(../svg/icons/search.svg);
            background-position: 50% 50%;
            background-size: cover;
            background-repeat: no-repeat;
            -webkit-transform: translate(0, -30px);
            -ms-transform: translate(0, -30px);
            transform: translate(0, -30px);
            opacity: 0.5;
            z-index: 1;
        }

        button {
            margin: 6px 0 0 0;
            display: none;
        }
    }

    .teaserCombinationD__profil span {
        line-height: 16px;
        text-transform: capitalize;
        margin: 0 0 20px 0;
        display: block;
    }

    input[readonly] {
        border-color: $input-border-color;
    }
}

.icon-input {
    position: relative;

    .icon-igm-close {
        position: absolute;
        top: 9px;
        right: 10px;
        display: none;

        cursor: pointer;
        font-size: 14px;
    }
}

.daterangepicker {

    padding: 30px;
    margin: 20px 0 0;
    width: auto;

    .daterangepicker_input {
        i {
            left: -5px;
            top: 9px;
        }
    }

    .fa-calendar {
        content: url(../svg/icons/calendar-b.svg);
        display: inline-block;
        font-size: 1.25em;
        width: 1em;
        height: auto;
        margin-left: 0.5em;
        vertical-align: middle;
    }

    .fa-chevron-left {
        content: url(../svg/icons/chevron-left-grau.svg);
        display: inline-block;
        font-size: 1.25em;
        width: 1em;
        height: auto;
        margin-left: 0.5em;
        vertical-align: middle;
        position: relative;
        left: -37px;
    }

    .fa-chevron-right {
        content: url(../svg/icons/chevron-right-grau.svg);
        display: inline-block;
        font-size: 1.25em;
        width: 1em;
        height: auto;
        margin-left: 0.5em;
        vertical-align: middle;
        position: relative;
        left: 20px;
    }

    td.active, td.active:hover {
        background-color: $color-brand;
        border-radius: 0;
    }

    td.in-range {
        background-color: rgba(227, 2, 14, 0.1);
    }

    td.active, td.active:hover {
        background-color: $color-brand;
    }

    &.dropdown-menu li {
        text-align: center;
    }

    .ranges li {
        background-color: rgba(0, 0, 0, 0.06);
        border: none;
        color: $color-grey4;
        border-radius: 0px;
        height: 37px;

        &:hover {
            color: $white;
            background-color: $color-brand;
        }

        &.active {
            background-color: $color-brand;
            border: none;
            color: $white;
        }

    }

    .right {
        .input-mini {
            width: 240px;
        }
    }

    .input-mini {

        border-radius: 0px;
        height: 38px;

        &.active {
            border: 1px solid $color-brand;
            border-radius: 0px;
        }
    }

    .btn-success {
        color: $white;
        background-color: $color-brand;
        border: none;

        &:hover {
            color: #FFFFFF;
            background-color: $btn-primary-hover-bg;
        }
    }

    .cancelBtn {
        background-color: rgba(0, 0, 0, 0.06);
    }

    .monthselect,
    .yearselect {
        background: #FFFFFF url(../svg/icons/dropdown-arrow.svg) no-repeat right 0.75rem center;
        display: inline-block;
        max-width: 100%;
        padding: 0.375rem 1.75rem 0.375rem 0.75rem;
        line-height: 1.25;
        color: #333333;
        vertical-align: middle;
        background-size: 8px 10px;
        border: 1px solid #cccccc;
        border-radius: 0.25rem;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        -moz-appearance: none;
        -webkit-appearance: none;
    }

    select.monthselect:not([size]):not([multiple]),
    select.yearselect:not([size]):not([multiple]) {
        height: calc(2.25rem + 2px);
    }

    select.monthselect,
    select.yearselect {
        padding: 0.375rem 1.75rem 0.375rem 0.75rem !important;
    }

    &.opensleft:before {
        right: 410px;
    }

    &:before {
        top: -10px;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        border-bottom: 10px solid #ccc;
    }

    &.opensleft:after {
        right: 410px;
    }

    &:after {
        top: -9px;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        border-left: 10px solid transparent;
    }

    .table-condensed {
        thead {
            tr {
                height: 50px;
            }
        }
    }

    .month {
        position: relative;
        left: -40px;
    }

    select.monthselect {
        width: 90%
    }

    select.yearselect {
        width: 54%;
    }

    @include media-breakpoint-up(md) {

        &.ltr .calendar.left {
            margin-right: 30px;
        }

        &.ltr .calendar.right {
            margin-right: 30px;
        }

    }

    .ranges ul {
        width: 100%;
    }

    td,
    th {
        &.available {
            &:hover {
                background-color: white;
            }
        }
    }
}

.searchPage__description {
    margin-top: 25px !important;
}


.searchPage__image {
    float: left;
    max-width: 100px;
    max-height: 100px;
    margin-bottom: 15px;
    margin-right: 25px;
}
