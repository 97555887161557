.argumentationsElement {
    &.page-section--colored-black,
    &.page-section--colored-red {
        .base-small {
            color: $color-white;
        }

        .footer {
            border-color: rgba(255, 255, 255, 0.25);
        }

        .text-wrapper {
            color: $color-black;
        }

        .arrow-link {
            color: $color-white;

            &:hover {
                color: $color-white;
            }
        }
    }

    &.page-section--colored-red {
        .btn.btn-primary {
            @extend .btn-secondary;

            &:after {
                content: url('../svg/icons/link-arrow-black.svg');
            }
        }
    }

    p {
        color: inherit;
    }

    .argument {
        background-color: $color-white;
        color: $color-black;
        height: 100%;

        &.single {
            max-width: 33.3333333%;
            margin: 0 auto;
        }
    }

    &.page-section--colored-white {
        .argument {
            background-color: $color-lightgrey;
        }
    }

    .text-wrapper {
        padding: 16px 30px 8px 30px;
        overflow: hidden;

        .text {
            margin-bottom: 10px;
            font-size: 14px;
        }

        &,
        &.text-left {
            h2, .h2,
            h3, .h3 {
                text-align: center;
            }
        }
    }

    figure {
        position: relative;
        margin: 0;
    }

    figcaption {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        color: $color-white;
        font-size: 36px;
        line-height: 42px;
        font-family: $font-family-base-bold;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        margin: 0;
    }

    .owl-stage {
        display: flex;
    }

    .owl-item {
        display: flex;
        flex: 1;
    }

}
