.component--committees {
    h2, h3,
    .h2, .h3 {
        a[data-toggle="collapse"] {
            &,
            &:hover,
            &:focus {
                color: $black;
                text-decoration: none;
            }

            > i.icon-igm-dropdown-arrow {
                color: $color-brand;
                font-size: 10px;
                line-height: 10px;
                margin-right: 15px;
                position: absolute;
                top: 12px;
                left: 0;

                &:before {
                    display: inline-block;
                }
            }

            &[aria-expanded="true"] {
                > i.icon-igm-dropdown-arrow::before {
                    transform: rotate(180deg);
                }
            }

            &[aria-expanded="false"] {
                > i.icon-igm-dropdown-arrow::before {
                    transform: rotate(0deg);
                }
            }
        }
    }
}

.committees-wrapper {
    position: relative;
    padding-bottom: 40px;
}

.table-committee,
.table-committees {
    border-bottom: 1px solid #EFEFEF;

    > thead {
        max-width: 100%;
        background-color: $color-grey3;

        > tr {
            > th {
                text-transform: uppercase;
                font-weight: bold;
                vertical-align: top;
                font-family: $font-family-CondOTBold;
                font-size: 12px;
                letter-spacing: 2px;
            }
        }
    }

    > tbody {
        > tr {
            > td {
                &:first-child {
                    white-space: nowrap;
                }
                & + td {
                    flex-grow: 1;
                }
            }
            &.tr-line-above {
                > td {
                    border-top: 2px solid #DADADA;
                }
            }
            &.tr-line-below {
                > td {
                    border-bottom: 2px solid #DADADA;
                }
            }
        }
    }

    > tfoot {
        > tr {
            > th,
            > td {
                font-family: $font-family-base-bold;
            }
        }
    }

    &.width-50-50 {
        > thead > tr > th,
        > thead > tr > td,
        > tbody > tr > td,
        > tfoot > tr > th,
        > tfoot > tr > td {
            width: 50%;
        }
    }

    @include media-breakpoint-down(md) {
        display: inline-block;
        vertical-align: top;
        max-width: 100%;
        min-width: 100%;
        margin: auto;
        overflow-x: auto;
        white-space: nowrap; 
        border-collapse: collapse;
        border-spacing: 0;
        -webkit-overflow-scrolling: touch;
    }
}
